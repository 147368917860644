import { OperationsCenterProductCurrency } from './OperationsCenterProductCurrency';

export class OpsCenter {
  id: number;
  name: string;
  companyId: number;
  companyName: string;
  operationsCenterProductCurrencies: OperationsCenterProductCurrency[];

  constructor(
    id?: number,
    name?: string,
    companyId?: number,
    companyName?: string,
    operationsCenterProductCurrencies?: OperationsCenterProductCurrency[]
  ) {
    this.id = id;
    this.name = name ?? null;
    this.companyId = companyId ?? null;
    this.companyName = companyName ?? null;
    this.operationsCenterProductCurrencies = operationsCenterProductCurrencies
      ? operationsCenterProductCurrencies.map(
          (ocpc) =>
            new OperationsCenterProductCurrency(
              ocpc.id,
              ocpc.operationsCenterId,
              ocpc.productCurrency,
              ocpc.defaultTimeZoneId,
              ocpc.sellerCutoffTime,
              ocpc.participantSettlementCutoffTime,
              ocpc.participantCutoffTime,
              ocpc.minReferenceRate,
              ocpc.lastFacilityStartRun,
              ocpc.isActive,
              ocpc.processingEndTime
            )
        )
      : new Array<OperationsCenterProductCurrency>();
  }
}
