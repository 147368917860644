export class FinancingOutbound {
  public compartmentName: string;
  public compartmentId: number;
  public currencyCode: string;
  public effectiveDate: Date;
  public facilityGroupName: string;
  public facilityGroupId: number;
  public paymentMethodName: string;
  public proposedEffectiveDate: Date;
  public receiptDate: Date;
  public recipientId: number;
  public recipientName: string;
  public recipientRoleTypeName: string;
  public remittancePurpose: string;
  public remitStatusId: string;
  public remittanceAllocatedAmount: number;
  public remittanceNetAmount: number;
  public remittanceReferenceId: number;
  public remittanceUnallocatedAmount: number;
  public remitTypeId: string;

  constructor(
    remittanceReferenceId?: number,
    remitStatusId?: string,
    compartmentName?: string,
    compartmentId?: number,
    currencyCode?: string,
    effectiveDate?: Date,
    facilityGroupName?: string,
    facilityGroupId?: number,
    paymentMethodName?: string,
    proposedEffectiveDate?: Date,
    receiptDate?: Date,
    recipientId?: number,
    recipientName?: string,
    recipientRoleTypeName?: string,
    remittanceAllocatedAmount?: number,
    remittanceNetAmount?: number,
    remittancePurpose?: string,
    remittanceUnallocatedAmount?: number,
    remitTypeId?: string
  ) {
    this.compartmentName = compartmentName;
    this.compartmentId = compartmentId;
    this.currencyCode = currencyCode;
    this.effectiveDate = effectiveDate;
    this.facilityGroupName = facilityGroupName;
    this.facilityGroupId = facilityGroupId;
    this.paymentMethodName = paymentMethodName;
    this.proposedEffectiveDate = proposedEffectiveDate;
    this.receiptDate = receiptDate;
    this.recipientId = recipientId;
    this.recipientName = recipientName;
    this.recipientRoleTypeName = recipientRoleTypeName;
    this.remitStatusId = remitStatusId;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceNetAmount = remittanceNetAmount;
    this.remittancePurpose = remittancePurpose;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.remitTypeId = remitTypeId;
  }
}
