import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { PolState } from './pol-reducer';

@Injectable()
export class PolSelectors {
  appState = (state: any) => state.pol;

  selectPols = createSelector(this.appState, (state: PolState) =>
    state?.polPages == null ? null : copy(state.polPages)
  );

  selectActivePol = createSelector(this.appState, (state: PolState) =>
    state?.activePol == null ? null : copy(state.activePol)
  );

  selectPolsComponentState = createSelector(this.appState, (state: PolState) => ({
    polPages: state?.polPages,
    polsActivePage: state?.polsActivePage
  }));
}
