export class IngestStatus {
  id: string;
  batchId: string;
  fileName: string;
  status: string;
  message: string;
  dateTime: string;
  fileLocation: string;

  constructor(
    id: string,
    batchId: string,
    fileName: string,
    status: string,
    message: string,
    dateTime: string,
    fileLocation: string
  ) {
    this.id = id;
    this.batchId = batchId;
    this.fileName = fileName;
    this.status = status;
    this.message = message;
    this.dateTime = dateTime;
    this.fileLocation = fileLocation;
  }
}
