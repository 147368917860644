import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'showHiddenCharacters' })
export class ShowHiddenCharactersPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    if (typeof value === 'string') {
      value = value.replace(' ', '\u00A0').split('\\').join('\\\\').replace(/\n/g, '\u005C\u006E');
    }
    return value;
  }
}
