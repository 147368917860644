import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { PolPriceState } from './pol-price-reducer';

@Injectable()
export class PolPriceSelectors {
  appState = (state: any) => state.polPrice;

  selectPolPrices = createSelector(this.appState, (state: PolPriceState) => copy(state.polPricePages));
  selectPolPriceStatuses = createSelector(this.appState, (state: PolPriceState) => copy(state.polPriceStatuses));
  selectPolPriceTypes = createSelector(this.appState, (state: PolPriceState) => copy(state.polPriceTypes));

  selectPolPriceCashAppStatuses = createSelector(this.appState, (state: PolPriceState) =>
    copy(state.polPriceCashAppStatuses)
  );

  selectCashAppStatuses = createSelector(this.appState, (state: PolPriceState) => copy(state.cashAppStatuses));

  selectCashAppInbound = createSelector(this.appState, (state: PolPriceState) => ({
    cashAppInboundPages: state?.cashAppInboundPages == null ? null : copy(state.cashAppInboundPages),
    cashAppStatuses: copy(state.cashAppStatuses)
  }));

  selectCashAppInboundComponentState = createSelector(this.appState, (state: PolPriceState) => ({
    cashAppStatuses: copy(state?.cashAppStatuses),
    cashAppActivePage: copy(state?.cashAppInboundActivePage),
    cashAppInboundPages: copy(state?.cashAppInboundPages)
  }));

  selectPolPriceComponentState = createSelector(this.appState, (state: PolPriceState) => ({
    polPricePages: state?.polPricePages,
    polPriceActivePage: state?.polPriceActivePage
  }));
}
