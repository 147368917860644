import { CompartmentCurrency } from './CompartmentCurrency';
import { OperationsCenter } from './OperationsCenter';
import { RelationshipStatus } from './RelationshipStatus';

export class Compartment {
  companyId?: number;
  operationsCenter?: OperationsCenter;
  relationshipStatus: RelationshipStatus;
  compartmentCurrencies: CompartmentCurrency[];
  isActive: boolean;

  constructor(
    companyId?: number,
    operationsCenter?: OperationsCenter,
    relationshipStatus?: RelationshipStatus,
    compartmentCurrencies?: CompartmentCurrency[],
    isActive?: boolean
  ) {
    this.companyId = companyId;
    this.operationsCenter = operationsCenter
      ? new OperationsCenter(operationsCenter.id, operationsCenter.name, operationsCenter.isActive)
      : new OperationsCenter();
    this.relationshipStatus = relationshipStatus
      ? new RelationshipStatus(relationshipStatus.id, relationshipStatus.name, relationshipStatus.isActive)
      : new RelationshipStatus();
    this.compartmentCurrencies = compartmentCurrencies
      ? compartmentCurrencies.map((cc) => new CompartmentCurrency(cc.isActive, cc.compartmentId, cc.currency))
      : new Array<CompartmentCurrency>();
    this.isActive = isActive ?? false;
  }
}
