import { Currency } from './Currency';
import { PolPriceStatus } from './PolPriceStatus';
import { PolPriceType } from './PolPriceType';

export class PolPrice {
  polId: number;
  instrumentId: number;
  seller: string;
  debtor: string;
  participant: string;
  currency: Currency;
  allocatedAdvanceAmount: string;
  settlementDate: string;
  priceType: PolPriceType;
  purchasePrice: string;
  priceStatus: PolPriceStatus;
  totalDiscountAmount: string;
  revenueShareAmount: string;

  constructor(
    polId?: number,
    instrumentId?: number,
    seller?: string,
    debtor?: string,
    participant?: string,
    currency?: Currency,
    allocatedAdvanceAmount?: string,
    settlementDate?: string,
    priceType?: PolPriceType,
    purchasePrice?: string,
    priceStatus?: PolPriceStatus,
    totalDiscountAmount?: string,
    revenueShareAmount?: string
  ) {
    this.polId = polId ?? null;
    this.instrumentId = instrumentId ?? null;
    this.seller = seller;
    this.debtor = debtor;
    this.participant = participant;
    this.currency = currency
      ? new Currency(currency.currencyCode, currency.name, currency.isActive, currency.sortOrder)
      : new Currency();
    this.allocatedAdvanceAmount = allocatedAdvanceAmount ?? null;
    this.settlementDate = settlementDate;
    this.priceType = priceType ? new PolPriceType(priceType.id, priceType.name) : new PolPriceType();
    this.purchasePrice = purchasePrice ?? null;
    this.priceStatus = priceStatus ? new PolPriceStatus(priceStatus.id, priceStatus.name) : new PolPriceStatus();
    this.totalDiscountAmount = totalDiscountAmount;
    this.revenueShareAmount = revenueShareAmount;
  }
}
