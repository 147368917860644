export class FacilityParticipantConfigList {
  id?: number;
  facilityConfigId?: number;
  facilityId?: number;
  participantGroupId?: number;
  participantGroupName?: string;
  participantName?: string;
  settlementDays?: number;
  marginRate?: string;
  referenceRateType?: string;
  referenceRateMethod?: string;
  dayCount?: string;
  isAutoAccept?: boolean;
  isActive?: boolean;
  constructor(
    id?: number,
    facilityConfigId?: number,
    facilityId?: number,
    participantGroupId?: number,
    participantGroupName?: string,
    participantName?: string,
    settlementDays?: number,
    marginRate?: string,
    referenceRateType?: string,
    referenceRateMethod?: string,
    dayCount?: string,
    isAutoAccept?: boolean,
    isActive?: boolean
  ) {
    this.id = id ?? null;
    this.facilityConfigId = facilityConfigId ?? null;
    this.facilityId = facilityId ?? null;
    this.participantGroupId = participantGroupId ?? null;
    this.participantGroupName = participantGroupName ?? null;
    this.participantName = participantName ?? null;
    this.settlementDays = settlementDays ?? null;
    this.marginRate = marginRate ?? null;
    this.referenceRateType = referenceRateType ?? null;
    this.referenceRateMethod = referenceRateMethod ?? null;
    this.dayCount = dayCount ?? null;
    this.isAutoAccept = isAutoAccept ?? null;
    this.isActive = isActive ?? null;
  }
}
