import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class DiscountMethod extends GenericDropdownOption<string> {
  static readonly FLAT = 'FLAT';
  static readonly MARKUP = 'MARKUP';
  static readonly MPA = 'MPA';
  static readonly RMPA = 'RMPA';

  roleType: string;

  constructor(id?: string, name?: string, isActive?: boolean, roleType?: string) {
    super(id, name, isActive);
    this.roleType = roleType;
  }
}
