import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TextValidation } from '../../app/Components/App Components/data-grid/Models/LsDataGridConfig';

export function createLsEmailValidator(textValidation: TextValidation): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const validEmail = RegExp(textValidation.emailValidator).test(value);

    return !validEmail ? { lsEmail: true } : null;
  };
}
