import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, BlackoutCalendar, Page, PageRequest } from '../../../../Models';

export abstract class BlackoutCalendarActions extends LsAction {
  /* eslint-disable */
  static BLACKOUTCALENDARS: Readonly<string> = 'BLACKOUTCALENDARS';
  static BLACKOUTCALENDAR: Readonly<string> = 'BLACKOUTCALENDAR';
  static BLACKOUTCALENDARS_ACTIVE_PAGE: Readonly<string> = 'BLACKOUTCALENDARS_ACTIVE_PAGE';
  /* eslint-enable */

  static getBlackoutCalendars = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setBlackoutCalendars = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS, ActionType.SET),
    props<{ page: Page<BlackoutCalendar> }>()
  );

  static clearBlackoutCalendars = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS, ActionType.CLEAR)
  );

  static updateBlackoutCalendar = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS, ActionType.UPDATE),
    props<{ blackoutCalendar: BlackoutCalendar }>()
  );

  static getBlackoutCalendar = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDAR, ActionType.GET),
    props<{ id: number }>()
  );

  static setBlackoutCalendar = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDAR, ActionType.SET),
    props<{ blackoutCalendar: BlackoutCalendar }>()
  );

  static createBlackoutCalendar = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDAR, ActionType.SAVE),
    props<{ blackoutCalendar: BlackoutCalendar }>()
  );

  static blackoutCalendarSaveSuccessful = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS, ActionType.COMPLETE)
  );

  static blackoutCalendarSaveUnsuccessful = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getAutocompleteBlackoutCalendars = createAction(
    BlackoutCalendarActions.getActionName(`AUTOCOMPLETE\/${BlackoutCalendarActions.BLACKOUTCALENDARS}`, ActionType.GET),
    props<{
      name: string;
      pageRequest?: PageRequest;
      product?: string;
      currency?: string;
    }>()
  );

  static setAutocompleteBlackoutCalendars = createAction(
    BlackoutCalendarActions.getActionName(`AUTOCOMPLETE\/${BlackoutCalendarActions.BLACKOUTCALENDARS}`, ActionType.SET),
    props<{ calendars: BlackoutCalendar[] }>()
  );

  static clearAutocompleteBlackoutCalendars = createAction(
    BlackoutCalendarActions.getActionName(
      `AUTOCOMPLETE\/${BlackoutCalendarActions.BLACKOUTCALENDARS}`,
      ActionType.CLEAR
    )
  );

  static getBlackoutCalendarsActivePage = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS_ACTIVE_PAGE, ActionType.GET)
  );

  static setBlackoutCalendarsActivePage = createAction(
    BlackoutCalendarActions.getActionName(BlackoutCalendarActions.BLACKOUTCALENDARS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );
}
