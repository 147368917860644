import { createSelector } from '@ngrx/store';
import { OpsState } from './ops-reducer';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { Product } from '../../../../Models';

@Injectable()
export class OpsSelectors {
  appState = (state: any) => state.generic;

  selectCountries = createSelector(this.appState, (state: OpsState) => copy(state.countries));

  selectStates = createSelector(this.appState, (state: OpsState) => copy(state.states));

  selectAddressTypes = createSelector(this.appState, (state: OpsState) => copy(state.addressTypes));

  selectCurrencies = createSelector(this.appState, (state: OpsState) => copy(state.currencies));

  selectPaymentMethods = createSelector(this.appState, (state: OpsState) => copy(state.paymentMethods));

  selectProducts = createSelector(this.appState, (state: OpsState) => copy(state.products));
  selectSCFProduct = createSelector(this.appState, (state: OpsState) => {
    return this.getScfProduct(copy(state.products));
  });

  selectUserRoles = createSelector(this.appState, (state: OpsState) => copy(state.userRoles));

  selectTimeZones = createSelector(this.appState, (state: OpsState) =>
    state.timeZones == null ? null : copy(state.timeZones)
  );

  selectDiscountMethods = createSelector(this.appState, (state: OpsState) => copy(state.discountMethods));

  selectDiscountCalcTypes = createSelector(this.appState, (state: OpsState) => copy(state.discountCalcTypes));

  selectRevenueSharingPrograms = createSelector(this.appState, (state: OpsState) => copy(state.revenueSharingPrograms));

  selectBankAccountPurposeAndAliases = createSelector(this.appState, (state: OpsState) => ({
    purposes: copy(state.bankAccountPurposes),
    aliases: state.bankAccountAliases ? copy(state.bankAccountAliases) : null
  }));

  selectParticipantBankAccountPurposeAndAliases = createSelector(this.appState, (state: OpsState) => ({
    purposes: copy(state.bankAccountPurposes),
    aliases: state.participantBankAccountAliases ? copy(state.participantBankAccountAliases) : null
  }));

  selectFacilityParticipantConfigFields = createSelector(this.appState, (state: OpsState) => ({
    discountMethods: copy(state.discountMethods),
    discountCalcTypes: copy(state.discountCalcTypes)
  }));

  selectMethodTypesAndPrograms = createSelector(this.appState, (state: OpsState) => ({
    discountMethods: copy(state.discountMethods),
    discountCalcTypes: copy(state.discountCalcTypes),
    revenueSharingPrograms: copy(state.revenueSharingPrograms),
    maturityOffsets: copy(state.maturityOffsets)
  }));

  selectBankAccountData = createSelector(this.appState, (state: OpsState) => ({
    countries: copy(state.countries),
    states: copy(state.states),
    currencies: copy(state.currencies),
    paymentMethods: copy(state.paymentMethods),
    bankAccountTypes: copy(state.bankAccountTypes)
  }));

  selectCompanyIntegrationData = createSelector(this.appState, (state: OpsState) => ({
    channelPrograms: copy(state.channelPrograms),
    integrationTypes: copy(state.integrationTypes)
  }));

  selectBankAccountTypes = createSelector(this.appState, (state: OpsState) => copy(state.bankAccountTypes));

  selectRelationshipStatuses = createSelector(this.appState, (state: OpsState) => copy(state.relationshipStatuses));

  selectLimitBasisTypes = createSelector(this.appState, (state: OpsState) => copy(state.limitBasisTypes));

  selectLienPositions = createSelector(this.appState, (state: OpsState) => copy(state.lienPositions));

  selectMaturityOffsets = createSelector(this.appState, (state: OpsState) => copy(state.maturityOffsets));

  selectPayXcelTypes = createSelector(this.appState, (state: OpsState) => copy(state.payXcelTypes));

  selectCapacityBasisTypes = createSelector(this.appState, (state: OpsState) => copy(state.capacityBasisTypes));

  selectIngestChannelNameDropdownOptions = createSelector(this.appState, (state: OpsState) =>
    copy(state.ingestChannelNameDropdownOptions)
  );

  selectIngestImportStatusDropdownOptions = createSelector(this.appState, (state: OpsState) =>
    copy(state.ingestImportStatusDropdownOptions)
  );

  selectIngestErrorMessageDropdownOptions = createSelector(this.appState, (state: OpsState) =>
    copy(state.ingestErrorMessageDropdownOptions)
  );

  selectIndustries = createSelector(this.appState, (state: OpsState) => copy(state.industries));
  selectIntegrationTypes = createSelector(this.appState, (state: OpsState) => copy(state.integrationTypes));
  selectChannelPrograms = createSelector(this.appState, (state: OpsState) => copy(state.channelPrograms));

  selectInstrumentApprovalSources = createSelector(this.appState, (state: OpsState) =>
    copy(state.instrumentApprovalSources)
  );

  selectInstrumentApprovalStatuses = createSelector(this.appState, (state: OpsState) =>
    copy(state.instrumentApprovalStatuses)
  );

  selectProductCurrencies = createSelector(this.appState, (state: OpsState) => copy(state.productCurrencies));

  selectAllocationMethods = createSelector(this.appState, (state: OpsState) => copy(state.allocationMethods));

  selectFacilityGroupMethods = createSelector(this.appState, (state: OpsState) => copy(state.facilityGroupMethods));

  selectPurchaseCadenceTypes = createSelector(this.appState, (state: OpsState) => copy(state.purchaseCadenceTypes));
  private getScfProduct(products: Product[]) {
    return products?.find((p) => p.id === Product.SCF);
  }

  selectSellerData = createSelector(
    this.selectRelationshipStatuses,
    this.selectLimitBasisTypes,
    this.selectLienPositions,
    this.selectPayXcelTypes,
    this.selectSCFProduct,
    (relationshipStatuses, limitBasisTypes, lienPositions, payXcelTypes, product) => {
      return {
        relationshipStatuses: relationshipStatuses,
        limitBasisTypes: limitBasisTypes,
        lienPositions: lienPositions,
        payXcelTypes: payXcelTypes,
        product: product
      };
    }
  );

  selectDebtorData = createSelector(
    this.selectRelationshipStatuses,
    this.selectLimitBasisTypes,
    this.selectMaturityOffsets,
    this.selectSCFProduct,
    (relationshipStatuses, limitBasisTypes, maturityOffsets, product) => {
      return {
        relationshipStatuses: relationshipStatuses,
        limitBasisTypes: limitBasisTypes,
        maturityOffsets: maturityOffsets,
        product: product
      };
    }
  );

  selectCompartmentData = createSelector(
    this.selectRelationshipStatuses,
    this.selectCurrencies,
    (relationshipStatuses, currencies) => {
      return {
        relationshipStatuses: relationshipStatuses,
        currencies: currencies
      };
    }
  );

  selectBankData = createSelector(
    this.selectRelationshipStatuses,
    this.selectCurrencies,
    (relationshipStatuses, currencies) => {
      return {
        relationshipStatuses: relationshipStatuses,
        currencies: currencies
      };
    }
  );

  selectSupplierData = createSelector(
    this.selectRelationshipStatuses,
    this.selectSCFProduct,
    (relationshipStatuses, product) => {
      return {
        relationshipStatuses: relationshipStatuses,
        product: product
      };
    }
  );

  selectFundedParticipantData = createSelector(
    this.selectRelationshipStatuses,
    this.selectLimitBasisTypes,
    this.selectSCFProduct,
    (relationshipStatuses, limitBasisTypes, product) => {
      return {
        relationshipStatuses: relationshipStatuses,
        limitBasisTypes: limitBasisTypes,
        product: product
      };
    }
  );
}
