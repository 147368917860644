import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedComponent, NotFoundComponent, UnauthorizedComponent } from '../Components';
import { environment } from '../../environments/environment';
import { ENV_NAME } from '../../environments/environment.common';
import { UserDataResolver } from './UserData.resolver';
import { MsalGuard } from '@azure/msal-angular';
import { FeatureFlagsResolver } from './FeatureFlags.resolver';

const isLocalOrDev = environment.envName === ENV_NAME.LOCAL || environment.envName === ENV_NAME.DEV;
export const baseRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'ops'
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'ops',
    canActivate: [MsalGuard],
    loadChildren: () => import('../Modules/Ops-Module/ops.module').then((m) => m.OpsModule),
    resolve: { data: UserDataResolver, featureFlags: FeatureFlagsResolver }
  },
  {
    path: 'participant',
    canActivate: [MsalGuard],
    loadChildren: () => import('../Modules/Participant-Module/participant.module').then((m) => m.ParticipantModule),
    resolve: { data: UserDataResolver, featureFlags: FeatureFlagsResolver }
  },
  {
    path: 'supplier',
    canActivate: [MsalGuard],
    loadChildren: () => import('../Modules/Supplier-Module/supplier.module').then((m) => m.SupplierModule),
    resolve: { data: UserDataResolver, featureFlags: FeatureFlagsResolver }
  },
  // {
  //   path: 'banking',
  //   canActivate: [false],
  //   loadChildren: () => import('../banking-module/banking.module').then(m => m.BankingModule)
  // },
  {
    path: 'authorized',
    canActivate: [MsalGuard],
    component: AuthorizedComponent
  },
  {
    path: '404',
    canActivate: [MsalGuard],
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(baseRoutes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
