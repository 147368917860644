import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { ParticipantOfferState } from './participant-offer-reducer';
import { PolPriceState } from '../PolPrice/pol-price-reducer';

@Injectable()
export class ParticipantOfferSelectors {
  appState = (state: any) => state.participantOffer;
  selectParticipantOffers = createSelector(this.appState, (state: ParticipantOfferState) =>
    state.participantOfferPages == null ? null : copy(state.participantOfferPages)
  );
  polPriceState = (state: any) => state.polPrice;
  selectLookups = createSelector(this.polPriceState, (state: PolPriceState) =>
    state.polPriceStatuses == null ? null : copy(state.polPriceStatuses)
  );

  selectActiveParticipantOffer = createSelector(this.appState, (state: ParticipantOfferState) =>
    state?.activeParticipantOffer == null ? null : copy(state.activeParticipantOffer)
  );

  selectParticipantOffersComponentState = createSelector(this.appState, (state: ParticipantOfferState) => ({
    participantOfferPages: state?.participantOfferPages,
    participantOffersActivePage: state?.participantOffersActivePage,
    participantOfferTotals: state?.participantOfferTotals
  }));
}
