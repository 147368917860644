import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BlackoutDate } from 'src/app/Models';
import { Store } from '@ngrx/store';
import { BlackoutCalendarSelectors } from '../app/Modules/Ops-Module/OpsStateManagement';
import { filter, map, takeUntil } from 'rxjs/operators';
import copy from 'fast-copy';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomValidators implements OnDestroy {
  private _blackoutDates: BlackoutDate[] = [];
  private serviceTeardown$ = new Subject();

  constructor(private store: Store, private blackoutCalendarSelectors: BlackoutCalendarSelectors) {
    this.store
      .select(this.blackoutCalendarSelectors.selectActiveBlackoutCalendar)
      .pipe(
        takeUntil(this.serviceTeardown$),
        filter((calendar) => calendar !== null && calendar !== undefined),
        map((calendar) => {
          this._blackoutDates = copy(calendar.blackoutDates);
        })
      )
      .subscribe();
  }
  ngOnDestroy() {
    this.serviceTeardown$.next(null);
    this.serviceTeardown$.complete();
  }

  public autocompleteSelectionValidator(control: AbstractControl): ValidationErrors | null {
    const selection = control.value;
    if (selection !== '' && typeof selection === 'string') {
      return { incorrect: {} };
    }
    return null;
  }

  public uniqueDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const selection = new Date(control.value).toDateString();
        if (this._blackoutDates.some((date) => new Date(date.date).toDateString() === selection) && !control.pristine) {
          return { duplicateDate: true };
        }
      }
      return null;
    };
  }
}
