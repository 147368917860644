import { BlackoutDate } from './BlackoutDate';
import { GenericLookup } from '@limestone/ls-shared-modules';

export class BlackoutCalendar extends GenericLookup<number> {
  blackoutDates?: BlackoutDate[];

  constructor(id?: number, name?: string, isActive?: boolean, blackoutDates?: BlackoutDate[]) {
    super(id, name, isActive ?? true);
    this.blackoutDates = blackoutDates
      ? blackoutDates.map((bd) => new BlackoutDate(bd.calendarId, bd.date, bd.reason))
      : [];
  }
}
