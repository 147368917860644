import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { GeneratedReportsState } from './generated-reports-reducer';
import copy from 'fast-copy';

@Injectable()
export class GeneratedReportsSelectors {
  appState = (state: any) => state.generatedReports;

  selectReports = createSelector(this.appState, (state: GeneratedReportsState) =>
    state.reportPages == null ? null : copy(state.reportPages)
  );

  selectReportsComponentState = createSelector(this.appState, (state: GeneratedReportsState) => ({
    reportPages: state?.reportPages,
    reportsActivePage: state?.reportsActivePage
  }));
}
