<div class="d-flex flex-column">
  <div class="d-flex flex-column ls-mb-5">
    <ng-container *ngFor="let error of errors; let last = last">
      <span [class.ls-mb-3]="!last">{{ error }}</span>
    </ng-container>
  </div>
  <div class="d-flex flex-row justify-content-end">
    <button mat-button (click)="dismiss()">OK</button>
  </div>
</div>
