import { Pipe, PipeTransform } from '@angular/core';
import { NumberValidation } from '../../app/Components/App Components/data-grid/Models/LsDataGridConfig';
import { DecimalPipe } from '@angular/common';
@Pipe({ name: 'largeCurrencyDisplay' })
export class LargeCurrencyDisplayPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: string, validation: NumberValidation): string {
    let num = Number(value);
    if (Number.isFinite(num) && (num > 1e12 || num < -1e12)) {
      value = num.toExponential(7);
    } else {
      const format = `1.${validation.numberOfDecimals}-${validation.numberOfDecimals}`;
      value = this.decimalPipe.transform(value, format);
    }
    return value;
  }
}
