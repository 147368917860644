import { Injectable } from '@angular/core';
import { HttpService } from './HTTP';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../Models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppService extends HttpService {
  constructor(private http: HttpClient) {
    super(http);
    this.path = '/api/configuration';
  }

  getConfig(): Observable<Configuration> {
    return this.http.get(this.path).pipe(map((config: Configuration) => new Configuration(config.raistoneId)));
  }
}
