import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InternalServerError } from './Errors/internal-server-error';
import { AppActions } from '../AppStateManagement';
import { Store } from '@ngrx/store';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500) {
          this.store.dispatch(AppActions.setSpinnerState({ isLoading: false }));
          return throwError(() => new InternalServerError(error.message, error.error));
        }

        return throwError(() => error);
      })
    );
  }
}
