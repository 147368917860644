import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class ExportService {
  constructor() {}

  /**
   * Downloads file to user's machine.
   *
   * @param response
   */
  async downloadFile(response: HttpResponse<Blob>) {
    let fileName = 'undetermined-filename';
    const disposition = response.headers.get('Content-Disposition');
    fileName = disposition ? disposition.split('filename=')[1].split(';')[0] : fileName;
    fileName = fileName.replace(/"/g, ''); // remove double-quotes
    const a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(response.body);
    a.click();
  }

  /**
   * Exports converted csv to user's machine.
   *
   * @param blob The data blob for the csv file.
   * @param fileName The file name to use for the created csv file.
   */
  async exportCSV(blob: Blob, fileName: string) {
    const date = new Date();
    const a = document.createElement('a');
    a.download = `${fileName}_Export_${this.formatDateAsFileNameString(date)}.csv`;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  formatDateAsFileNameString(date: Date) {
    return (
      date.getFullYear() +
      String(date.getMonth() + 1).padStart(2, '0') +
      String(date.getDate()).padStart(2, '0') +
      '_' +
      String(date.getHours()).padStart(2, '0') +
      String(date.getMinutes()).padStart(2, '0')
    );
  }
}
