import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { CompanyContact, ContactType } from '../../../../Models';

export abstract class CompanyContactActions extends LsAction {
  /* eslint-disable */
  static CONTACT: Readonly<string> = 'CONTACT';
  static CONTACT_TYPE: Readonly<string> = 'CONTACT_TYPE';
  /* eslint-enable */

  static getContactTypes = createAction(
    CompanyContactActions.getActionName(CompanyContactActions.CONTACT_TYPE, ActionType.GET)
  );

  static setContactTypes = createAction(
    CompanyContactActions.getActionName(CompanyContactActions.CONTACT_TYPE, ActionType.SET),
    props<{ contactTypes: ContactType[] }>()
  );

  static getAutocompleteContacts = createAction(
    CompanyContactActions.getActionName(`AUTOCOMPLETE\/${CompanyContactActions.CONTACT}`, ActionType.GET),
    props<{
      firstName: string;
      lastName: string;
      email: string;
      companyId: number;
    }>()
  );

  static setAutocompleteContacts = createAction(
    CompanyContactActions.getActionName(`AUTOCOMPLETE\/${CompanyContactActions.CONTACT}`, ActionType.SET),
    props<{ contacts: CompanyContact[] }>()
  );
}
