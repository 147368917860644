import { Country, StateProvince } from '@limestone/ls-shared-modules';
import { Guid } from '../../Utils/Guid';

export class Address {
  id?: number;
  clientId?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: StateProvince;
  country: Country;
  postalCode?: string;
  constructor(
    id?: number,
    clientId?: string,
    address1?: string,
    address2?: string,
    city?: string,
    state?: StateProvince,
    country?: Country,
    postalCode?: string
  ) {
    this.id = id ?? null;
    this.clientId = clientId ?? new Guid().New(false);
    this.address1 = address1 ?? null;
    this.address2 = address2 ?? null;
    this.city = city ?? null;
    this.state = state ? new StateProvince(state.code, state.name, state.country, state.isActive) : null;
    this.country = country ? new Country(country.code, country.name, country.isActive) : null;
    this.postalCode = postalCode ?? null;
  }
}
