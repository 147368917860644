import { ActionsSubject } from '@ngrx/store';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorSnackBarComponent } from './error-snack-bar/error-snack-bar.component';
import { CommonMethodComponentBase } from './CommonMethodComponentBase';
import { inject } from '@angular/core';

export class EditComponentBase extends CommonMethodComponentBase {
  public id: number | string;

  public actions$ = inject(ActionsSubject);
  public snackBar = inject(MatSnackBar);
  constructor(
    public successfulActionType: string,
    public unsuccessfulActionType: string,
    public unsuccessfulExportActionType: string = ''
  ) {
    super();
    if (this.actions$) {
      this.observeSaveActionStatus();
    }
    if (this.activatedRoute) {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
    }
  }

  private observeSaveActionStatus() {
    this.actions$
      .pipe(
        takeUntil(this.componentTeardown$),
        filter(
          (action) =>
            action.type === this.successfulActionType ||
            action.type === this.unsuccessfulActionType ||
            action.type === this.unsuccessfulExportActionType
        ),
        map((action) => {
          if (action.type === this.successfulActionType) {
            this.snackBar.open('Data save was successful', 'X', {
              duration: 5000,
              panelClass: ['successful-sb']
            });
          } else {
            this.errors = action['errors'];
            this.snackBar.openFromComponent(ErrorSnackBarComponent, {
              duration: 5000,
              panelClass: ['unsuccessful-sb'],
              data: this.errors
            });
          }
        })
      )
      .subscribe();
  }

  navToSaved(id: number | string) {
    this.id = id;
    this.navToChild(`../${id}`);
  }
}
