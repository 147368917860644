export class ParticipantOfferPols {
  id: number;
  pobId: number;
  sellerName: string;
  sellerId: number;
  debtorName: string;
  debtorId: number;
  instrumentType: string;
  instrumentNumber: string;
  instrumentId: number;
  currencyCode: string;
  netAmount: string;
  advanceRate: string;
  advanceAmount: string;
  purchasePrice: string;
  participantDiscounts: string;
  participantDiscountPercent: string;
  financingTenor: number;
  expectedDueDate: string;

  constructor(
    id?: number,
    pobId?: number,
    sellerName?: string,
    sellerId?: number,
    debtorName?: string,
    debtorId?: number,
    instrumentType?: string,
    instrumentNumber?: string,
    instrumentId?: number,
    currencyCode?: string,
    netAmount?: string,
    advanceRate?: string,
    advanceAmount?: string,
    purchasePrice?: string,
    participantDiscounts?: string,
    participantDiscountPercent?: string,
    financingTenor?: number,
    expectedDueDate?: string
  ) {
    this.id = id ?? null;
    this.pobId = pobId ?? null;
    this.sellerName = sellerName ?? null;
    this.sellerId = sellerId ?? null;
    this.debtorName = debtorName ?? null;
    this.debtorId = debtorId ?? null;
    this.instrumentType = instrumentType ?? null;
    this.instrumentNumber = instrumentNumber ?? null;
    this.instrumentId = instrumentId ?? null;
    this.currencyCode = currencyCode ?? null;
    this.netAmount = netAmount ?? null;
    this.advanceRate = advanceRate ?? null;
    this.advanceAmount = advanceAmount ?? null;
    this.purchasePrice = purchasePrice ?? null;
    this.participantDiscounts = participantDiscounts ?? null;
    this.participantDiscountPercent = participantDiscountPercent ?? null;
    this.financingTenor = financingTenor ?? null;
    this.expectedDueDate = expectedDueDate ?? null;
  }
}
