import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { map, takeUntil } from 'rxjs/operators';
import { CoreComponent } from '../CoreComponent';
import { AppActions } from '../../AppStateManagement';
import { ActionsSubject } from '@ngrx/store';

@Component({
  selector: 'ls-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent extends CoreComponent implements OnInit {
  public isLoading: boolean = true;

  @Output() isLoadingState: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private actions$: ActionsSubject) {
    super();
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        ofType(AppActions.setSpinnerState),
        takeUntil(this.componentTeardown$),
        map((state) => {
          this.isLoadingState.emit(state.isLoading);
          this.isLoading = state.isLoading;
        })
      )
      .subscribe();
  }
}
