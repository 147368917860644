import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { FacilityConfigurationState } from './facility-configuration-reducer';
import { FacilitySelectors } from '../Facility/facility-selectors';
import { FacilityGroupSelectors } from '../FacilityGroup/facility-group-selectors';
import { OpsCenterSelectors } from '../OpsCenter/ops-center-selectors';
import { DiscountBasisSelectors } from '../DiscountBasis/discount-basis-selectors';
import { OpsSelectors } from '../Generic/ops-selectors';
import { ReferenceRateSelectors } from '../ReferenceRate/reference-rate-selectors';
import { FacilityConfigDetailData } from '../../../../Models';

@Injectable()
export class FacilityConfigurationSelectors {
  constructor(
    private facilitySelectors: FacilitySelectors,
    private facilityGroupSelectors: FacilityGroupSelectors,
    private discountBasisSelectors: DiscountBasisSelectors,
    private opsSelectors: OpsSelectors,
    private opsCenterSelectors: OpsCenterSelectors,
    private referenceRateSelectors: ReferenceRateSelectors
  ) {}
  appState = (state: any) => state.facilityConfiguration;

  selectFacilityConfigDates = createSelector(this.appState, (state: FacilityConfigurationState) =>
    copy(state.facilityConfigDates)
  );

  selectActiveFacilityConfig = createSelector(this.appState, (state: FacilityConfigurationState) =>
    state.activeFacilityConfig == null ? null : copy(state.activeFacilityConfig)
  );

  selectFacilityConfigDetailData = createSelector(
    this.selectActiveFacilityConfig,
    this.selectFacilityConfigDates,
    this.facilitySelectors.selectActiveFacility,
    this.facilityGroupSelectors.selectActiveFacilityGroup,
    this.opsCenterSelectors.selectActiveOpsCenter,
    this.discountBasisSelectors.selectDayCountArr,
    this.discountBasisSelectors.selectDiscountBasisTypes,
    this.opsSelectors.selectDiscountMethods,
    this.opsSelectors.selectDiscountCalcTypes,
    this.opsSelectors.selectRevenueSharingPrograms,
    this.opsSelectors.selectTimeZones,
    this.referenceRateSelectors.selectReferenceRateMethods,
    this.referenceRateSelectors.selectReferenceRateTypes,
    (
      activeFacilityConfig,
      facilityConfigDates,
      activeFacility,
      activeFacilityGroup,
      activeOpsCenter,
      dayCountArr,
      discountBasisTypes,
      discountMethods,
      discountCalcTypes,
      revenueSharingPrograms,
      timeZones,
      referenceRateMethods,
      referenceRateTypes
    ) => {
      const facilityConfigData: FacilityConfigDetailData = {
        facility: activeFacility,
        facilityConfig: activeFacilityConfig,
        facilityGroup: activeFacilityGroup,
        opsCenter: activeOpsCenter,
        facilityConfigDates: facilityConfigDates,
        discountMethods: discountMethods,
        discountCalcTypes: discountCalcTypes,
        referenceRateMethods: referenceRateMethods,
        referenceRateTypes: referenceRateTypes,
        revenueSharingPrograms: revenueSharingPrograms,
        dayCountArr: dayCountArr,
        discountBasisTypes: discountBasisTypes,
        timeZones: timeZones
      };
      return facilityConfigData;
    }
  );

  selectFacilityConfigsComponentState = createSelector(this.appState, (state: FacilityConfigurationState) => ({
    facilityConfigPages: copy(state?.facilityConfigPages),
    facilityConfigsActivePage: copy(state?.facilityConfigsActivePage)
  }));
}
