export class CashAppInbound {
  polCashAppStatusId: string;
  expectedDueDate: Date;
  purpose: string;
  currencyCode: string;
  netAmount: string;
  advanceAmount: string;
  allocatedAmount: string;
  unallocatedAmount: string;
  debtorName: string;
  debtorId: number;
  role: string;
  typeOfCollection: string;
  productId: string;

  constructor(
    polCashAppStatusId?: string,
    expectedDueDate?: Date,
    purpose?: string,
    currencyCode?: string,
    netAmount?: string,
    advanceAmount?: string,
    allocatedAmount?: string,
    unallocatedAmount?: string,
    debtorName?: string,
    debtorId?: number,
    role?: string,
    typeOfCollection?: string,
    productId?: string
  ) {
    this.polCashAppStatusId = polCashAppStatusId;
    this.expectedDueDate = expectedDueDate;
    this.purpose = purpose;
    this.currencyCode = currencyCode;
    this.netAmount = netAmount;
    this.advanceAmount = advanceAmount;
    this.allocatedAmount = allocatedAmount;
    this.unallocatedAmount = unallocatedAmount;
    this.debtorName = debtorName;
    this.debtorId = debtorId;
    this.role = role;
    this.typeOfCollection = typeOfCollection;
    this.productId = productId;
  }
}
