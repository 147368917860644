import { GridColumn } from '../Models/LsDataGridConfig';
import { Group } from '../Models/Group';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataGridPaginatorComponent } from '../grid-pagination/data-grid-paginator.component';

export class DataGridGroupingService {
  constructor() {}

  // Grouping Toggle
  // Returns the level for the column when grouping is active.
  getGroupingLevel(groupByColumns: string[], gridCol: GridColumn): number {
    // const  = this.getGridColumn(column);
    return groupByColumns.findIndex((col) => col === gridCol.value) > -1
      ? groupByColumns.findIndex((col) => col === gridCol.value)
      : null;
  }

  // Enables or disables grouping by column
  groupToggle(
    groupByColumns: string[],
    column: GridColumn,
    dataSource: MatTableDataSource<any>,
    paginator?: DataGridPaginatorComponent
  ): void {
    const exists = groupByColumns.findIndex((col) => col === column.value) > -1;
    if (column) {
      if (exists) {
        this.checkGroupByColumn(groupByColumns, column.value, false);
      } else {
        this.checkGroupByColumn(groupByColumns, column.value, true);
      }
      this.updateGroupings(groupByColumns, dataSource, paginator);
    }
  }

  // Updates data for table with grouping.
  updateGroupings(
    groupByColumns: string[],
    dataSource: MatTableDataSource<any>,
    paginator?: DataGridPaginatorComponent
  ): void {
    dataSource.data = this.addGroups(dataSource.data, groupByColumns);
    dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    paginator?.firstPage();
  }

  // Checks if column is already in grouping.
  checkGroupByColumn(groupByColumns: string[], field, add): void {
    let found = null;
    for (const column of groupByColumns) {
      if (column === field) {
        found = groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        groupByColumns.push(field);
      }
    }
  }

  // Custom filter predicate for data table.
  customFilterPredicate(groupByColumns: string[], data: any | Group): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(groupByColumns, data);
  }

  // Returns whether or not row is visible based on groupings and visibility of parent groupings.
  getDataRowVisible(groupByColumns: string[], dataSource: MatTableDataSource<any>): boolean {
    const groupRows = dataSource.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }

      let match = true;
      groupByColumns.forEach((column) => {
        if (!row[column] || !dataSource.data[column] || row[column] !== dataSource.data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    if (groupRows.length > 1) {
      throw new Error('Data row is in more than one group!');
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  // Expands or collapses grouping.
  groupHeaderClick(row: any, dataSource: MatTableDataSource<any>): void {
    row.expanded = !row.expanded;
    dataSource.filter = performance.now().toString(); // hack to trigger filter refresh
  }

  // Adds data to column grouping.
  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  // Sets sublevel for nested groupings.
  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length) {
      return data;
    }

    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];

    let subGroups = [];
    groups.forEach((group) => {
      const rowsInGroup = data.filter((row) => group[currentColumn] === row[currentColumn]);
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  // Check for uniqueness
  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }
}
