import { InstrumentApprovalMethod } from './InstrumentApprovalMethod';
import { InstrumentApprovalParty } from './InstrumentApprovalParty';

export class InstrumentApprovalSource {
  id: string;
  instrumentApprovalParty: InstrumentApprovalParty;
  instrumentApprovalMethod: InstrumentApprovalMethod;
  isActive: boolean;

  constructor(
    id?: string,
    instrumentApprovalParty?: InstrumentApprovalParty,
    instrumentApprovalMethod?: InstrumentApprovalMethod,
    isActive?: boolean
  ) {
    this.id = id;
    this.instrumentApprovalParty = instrumentApprovalParty
      ? new InstrumentApprovalParty(
          instrumentApprovalParty.id,
          instrumentApprovalParty.name,
          instrumentApprovalParty.isActive
        )
      : null;
    this.instrumentApprovalMethod = instrumentApprovalMethod
      ? new InstrumentApprovalMethod(
          instrumentApprovalMethod.id,
          instrumentApprovalMethod.name,
          instrumentApprovalMethod.isActive
        )
      : null;
    this.isActive = isActive ?? null;
  }
}
