import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppActions, AppSelectors } from '../AppStateManagement';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { User } from '../Models';
import { IdTokenClaims } from '@azure/msal-browser';

@Injectable()
export class UserDataResolver {
  constructor(
    public router: Router,
    private store: Store<any>,
    public authService: MsalService,
    private appSelectors: AppSelectors
  ) {}

  resolve(): Observable<any> | Promise<any> | any {
    return this.store
      .select(this.appSelectors.selectUser)
      .pipe(
        tap((user) => {
          if (user == null) {
            const claims = this.authService.instance.getAllAccounts();
            if (claims.length > 0) {
              const tokenClaims: IdTokenClaims = claims[0].idTokenClaims;
              this.store.dispatch(
                AppActions.setUser({
                  user: new User(tokenClaims['family_name'], tokenClaims['given_name'], tokenClaims['name'])
                })
              );
            }
          }
        }),
        map(() => true)
      )
      .subscribe();
  }
}
