export class Instrument {
  id: number | string;
  sourceSystemInstrumentId: string;
  sourceChannelName: string;
  debtorName: string;
  supplierName: string;
  grossAmount: string;
  deductionAmount: string;
  netAmount: string;
  currencyCode: string;
  instrumentApprovalStatus: string;
  instrumentApprovalSourceId: string;
  instrumentEligibilityStatus: string;
  ineligibleReason: string;
  instrumentNumber: string;
  instrumentType: string;
  issueDate: string;
  notes: string;
  sourceSystemDebtorId: string;
  sourceSystemSupplierId: string;
  originalDueDate: string;

  constructor(
    id?: number | string,
    sourceSystemInstrumentId?: string,
    sourceChannelName?: string,
    debtorName?: string,
    supplierName?: string,
    netAmount?: string,
    currencyCode?: string,
    instrumentApprovalStatus?: string,
    instrumentEligibilityStatus?: string,
    ineligibleReason?: string,
    instrumentNumber?: string,
    instrumentType?: string,
    issueDate?: string,
    notes?: string,
    sourceSystemDebtorId?: string,
    sourceSystemSupplierId?: string,
    originalDueDate?: string,
    grossAmount?: string,
    deductionAmount?: string,
    instrumentApprovalSourceId?: string
  ) {
    this.id = id;
    this.sourceSystemInstrumentId = sourceSystemInstrumentId ?? null;
    this.sourceChannelName = sourceChannelName ?? null;
    this.debtorName = debtorName ?? null;
    this.supplierName = supplierName ?? null;
    this.grossAmount = grossAmount ?? null;
    this.deductionAmount = deductionAmount ?? null;
    this.netAmount = netAmount ?? null;
    this.currencyCode = currencyCode ?? null;
    this.instrumentApprovalStatus = instrumentApprovalStatus ?? null;
    this.instrumentApprovalSourceId = instrumentApprovalSourceId ?? null;
    this.instrumentEligibilityStatus = instrumentEligibilityStatus ?? null;
    this.ineligibleReason = ineligibleReason ?? null;
    this.instrumentNumber = instrumentNumber ?? null;
    this.instrumentType = instrumentType ?? null;
    this.issueDate = issueDate ?? null;
    this.notes = notes ?? null;
    this.sourceSystemDebtorId = sourceSystemDebtorId ?? null;
    this.sourceSystemSupplierId = sourceSystemSupplierId ?? null;
    this.originalDueDate = originalDueDate ?? null;
  }
}
