import { ProductCurrency } from './ProductCurrency';
import { LimitBasisType } from './LimitBasisType';
import { LienPosition } from './LienPosition';
import { PayXcelType } from './PayXcelType';

export class SellerProductCurrency {
  sellerId?: number;
  productCurrency: ProductCurrency;
  creditLimitAmount?: string;
  limitBasisType: LimitBasisType;
  isBankAccountVerified: boolean;
  isAutoXcel: boolean;
  lienPosition: LienPosition;
  payXcelType: PayXcelType;
  isActive: boolean;

  constructor(
    isActive?: boolean,
    sellerId?: number,
    productCurrency?: ProductCurrency,
    creditLimitAmount?: string,
    limitBasisType?: LimitBasisType,
    isBankAccountVerified?: boolean,
    isAutoXcel?: boolean,
    lienPosition?: LienPosition,
    payXcelType?: PayXcelType
  ) {
    this.sellerId = sellerId;
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : new ProductCurrency();
    this.creditLimitAmount = creditLimitAmount ?? null;
    this.limitBasisType = limitBasisType
      ? new LimitBasisType(limitBasisType.id, limitBasisType.name, limitBasisType.isActive)
      : new LimitBasisType();
    this.isBankAccountVerified = isBankAccountVerified ?? false;
    this.isAutoXcel = isAutoXcel ?? true;
    this.lienPosition = lienPosition
      ? new LienPosition(lienPosition.id, lienPosition.name, lienPosition.isActive)
      : new LienPosition();
    this.payXcelType = payXcelType
      ? new PayXcelType(payXcelType.id, payXcelType.name, payXcelType.isActive)
      : new PayXcelType();
    this.isActive = isActive ?? false;
  }
}
