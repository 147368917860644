import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class DaySelectionOption extends GenericDropdownOption<number> {
  constructor(id?: number, name?: string) {
    super(id, name);
  }
}

export const monthlySelection = 'Monthly on';
export const weeklySelection = 'Weekly on';
export const noSelection = '';

export const daySelectionTypes = [noSelection, monthlySelection, weeklySelection] as const;

export class DaySelectionOptionsConstants {
  public static monthlyOptions: DaySelectionOption[] = [
    new DaySelectionOption(1, '1st'),
    new DaySelectionOption(2, '2nd'),
    new DaySelectionOption(3, '3rd'),
    new DaySelectionOption(4, '4th'),
    new DaySelectionOption(5, '5th'),
    new DaySelectionOption(6, '6th'),
    new DaySelectionOption(7, '7th'),
    new DaySelectionOption(8, '8th'),
    new DaySelectionOption(9, '9th'),
    new DaySelectionOption(10, '10th'),
    new DaySelectionOption(11, '11th'),
    new DaySelectionOption(12, '12th'),
    new DaySelectionOption(13, '13th'),
    new DaySelectionOption(14, '14th'),
    new DaySelectionOption(15, '15th'),
    new DaySelectionOption(16, '16th'),
    new DaySelectionOption(17, '17th'),
    new DaySelectionOption(18, '18th'),
    new DaySelectionOption(19, '19th'),
    new DaySelectionOption(20, '20th'),
    new DaySelectionOption(21, '21st'),
    new DaySelectionOption(22, '22nd'),
    new DaySelectionOption(23, '23rd'),
    new DaySelectionOption(24, '24th'),
    new DaySelectionOption(25, '25th'),
    new DaySelectionOption(26, '26th'),
    new DaySelectionOption(27, '27th'),
    new DaySelectionOption(28, '28th'),
    new DaySelectionOption(29, '29th'),
    new DaySelectionOption(30, '30th'),
    new DaySelectionOption(31, '31st')
  ];
  public static weeklyOptions: DaySelectionOption[] = [
    new DaySelectionOption(0, 'Sunday'),
    new DaySelectionOption(1, 'Monday'),
    new DaySelectionOption(2, 'Tuesday'),
    new DaySelectionOption(3, 'Wednesday'),
    new DaySelectionOption(4, 'Thursday'),
    new DaySelectionOption(5, 'Friday'),
    new DaySelectionOption(6, 'Saturday')
  ];
  public static weekdayOptions: DaySelectionOption[] = [
    new DaySelectionOption(1, 'Monday'),
    new DaySelectionOption(2, 'Tuesday'),
    new DaySelectionOption(3, 'Wednesday'),
    new DaySelectionOption(4, 'Thursday'),
    new DaySelectionOption(5, 'Friday')
  ];
}

export type DaySelectionTypes = (typeof daySelectionTypes)[number];
