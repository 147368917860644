import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'pascalToTitle' })
export class PascalToTitlePipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const result = value
      .replace(/([A-Z])/g, ' $1')
      .replace(/pol/g, 'POL')
      .replace(/pob/g, 'POB')
      .replace(/Id/g, 'ID');
    const final = result.charAt(0).toUpperCase() + result.slice(1);
    return final;
  }
}
