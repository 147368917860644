import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { CoreComponent } from '../../../CoreComponent';
import { map, takeUntil } from 'rxjs/operators';
import { DataGridGroupingService } from '../Services/data-grid-grouping.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ls-data-grid-paginator',
  templateUrl: './data-grid-paginator.component.html',
  styleUrls: ['./data-grid-paginator.component.scss'],
  providers: [DataGridGroupingService]
})
export class DataGridPaginatorComponent extends CoreComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() pageSizeOptions: Array<number> = [25, 50, 100, 250, 500];
  @Input() length: number;
  @Input() page$: Observable<PageEvent> = of();
  @Input() showFirstAndLastButtons: boolean = true;
  @Output() pageSizeChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  currentPage: PageEvent = new PageEvent();

  ngOnInit() {
    this.paginator.page
      .pipe(
        takeUntil(this.componentTeardown$),
        map((p: PageEvent) => (this.currentPage = p))
      )
      .subscribe();

    this.page$
      .pipe(
        takeUntil(this.componentTeardown$),
        map((page) => this.setPage(page))
      )
      .subscribe();
  }

  /**
   * Emits when a page event occurs.
   *
   * @param page The PageEvent that is emitted from the paginator.
   */
  public emitPageEvent(page: PageEvent): void {
    this.currentPage = page;
    this.pageSizeChange.emit(page);
  }

  /**
   * Sets the page state of the paginator.
   *
   * @param page PageEvent the paginator needs to be set to..
   */
  public setPage(page: PageEvent): void {
    this.paginator.pageSize = page.pageSize;
    this.paginator.pageIndex = page.pageIndex;
    this.paginator.length = page.length;
  }

  /**
   * Sets the paginator to return to thr first page.
   */
  public firstPage(): void {
    this.paginator.firstPage();
  }

  /**
   * Moves to next page if available.
   *
   * @param nextDataIndex index of the next element in the grid data
   * @return true if moved to next page; false if not.
   */
  public nextPage(nextDataIndex: number): boolean {
    const currPage = this.currentPage.pageIndex;
    const pageSize = this.currentPage.pageSize;
    const totalLength = this.paginator.length;
    if (currPage > 0) {
      nextDataIndex = currPage * pageSize + nextDataIndex;
    }
    if (nextDataIndex < totalLength) {
      if (nextDataIndex >= (currPage + 1) * pageSize && this.paginator.hasNextPage()) {
        this.paginator.nextPage();
      }
      return true;
    } else if (nextDataIndex >= totalLength) {
      return false;
    }
  }
}
