export class ImportActivity {
  batchId: string;
  importDate: Date;
  sourceSystemFileName: string;
  instrumentCount: string;
  currency: string;
  totalAmount: string;
  channelName: string;
  status: string;
  errorMessage: string;
  constructor(
    batchId?: string,
    importDate?: Date,
    sourceSystemFileName?: string,
    instrumentCount?: string,
    currency?: string,
    totalAmount?: string,
    channelName?: string,
    status?: string,
    errorMessage?: string
  ) {
    this.batchId = batchId;
    this.importDate = importDate;
    this.sourceSystemFileName = sourceSystemFileName;
    this.instrumentCount = instrumentCount;
    this.currency = currency;
    this.totalAmount = totalAmount ?? null;
    this.channelName = channelName;
    this.status = status;
    this.errorMessage = errorMessage;
  }
}
