import { BlackoutCalendar } from './BlackoutCalendar';
import { Currency } from './Currency';

export class CompanyCurrencyBlackout {
  companyId: number;
  blackoutCalendar: BlackoutCalendar;
  currency: Currency;

  constructor(companyId?: number, currency?: Currency, blackoutCalendar?: BlackoutCalendar) {
    this.companyId = companyId;
    this.currency = currency;
    this.blackoutCalendar = blackoutCalendar
      ? new BlackoutCalendar(
          blackoutCalendar.id,
          blackoutCalendar.name,
          blackoutCalendar.isActive,
          blackoutCalendar.blackoutDates
        )
      : new BlackoutCalendar();
  }
}
