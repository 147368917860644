import { AddressType } from './AddressType';
import { Address } from './Address';

export class CompanyAddress {
  addressId: number;
  companyId: number;
  address: Address;
  addressType?: AddressType;
  isActive: boolean = true;

  constructor(
    address?: Address,
    addressType?: AddressType,
    isActive?: boolean,
    addressId?: number,
    companyId?: number
  ) {
    this.address = address
      ? new Address(
          address.id,
          address.clientId,
          address.address1,
          address.address2,
          address.city,
          address.state,
          address.country,
          address.postalCode
        )
      : null;
    this.addressType = addressType ? new AddressType(addressType.id, addressType.name, addressType.isActive) : null;
    this.isActive = isActive ?? true;
    this.addressId = addressId ?? null;
    this.companyId = companyId ?? null;
  }
}
