import { DebtorProductCurrency } from './DebtorProductCurrency';
import { RelationshipStatus } from './RelationshipStatus';

export class Debtor {
  companyId?: number;
  relationshipStatus: RelationshipStatus;
  debtorProductCurrencies: DebtorProductCurrency[];

  constructor(
    companyId?: number,
    relationshipStatus?: RelationshipStatus,
    debtorProductCurrencies?: DebtorProductCurrency[]
  ) {
    this.companyId = companyId ?? null;
    this.relationshipStatus = relationshipStatus
      ? new RelationshipStatus(relationshipStatus.id, relationshipStatus.name, relationshipStatus.isActive)
      : new RelationshipStatus();
    this.debtorProductCurrencies = debtorProductCurrencies
      ? debtorProductCurrencies.map(
          (dpc) =>
            new DebtorProductCurrency(
              dpc.isActive,
              dpc.debtorId,
              dpc.productCurrency,
              dpc.creditLimitAmount,
              dpc.limitBasisType,
              dpc.isBankAccountVerified,
              dpc.isUnfinancedCollected,
              dpc.maturityOffset,
              dpc.maturityType,
              dpc.maturityDaysOfWeek,
              dpc.maturityDaysOfMonth
            )
        )
      : new Array<DebtorProductCurrency>();
  }
}
