import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('b2clogin')) {
      let appendApiRoute = false;
      let url = environment.limestoneHost;
      if (request.url.split('/').length > 0) {
        if (request.url.split('/')[1] === 'api') {
          appendApiRoute = true;
          url = `${url}${request.url}`;
        }
      }
      request = request.clone({
        url: appendApiRoute ? url : request.url
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if ([401, 403].includes(error.status)) {
          // Handle 401/403 errors
          this.router.navigateByUrl('/unauthorized');
          return of(null);
        } else {
          throw error;
        }
      })
    );
  }
}
