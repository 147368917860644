import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class Currency extends GenericDropdownOption<string> {
  sortOrder: number;
  defaultDayCountId?: string;
  constructor(
    currencyCode?: string,
    name?: string,
    isActive?: boolean,
    sortOrder?: number,
    defaultDayCountId?: string
  ) {
    super(currencyCode, currencyCode, isActive);
    this.sortOrder = sortOrder ?? null;
    this.defaultDayCountId = defaultDayCountId ?? null;
  }

  get currencyCode() {
    return this.id;
  }

  set currencyCode(value: string) {
    this.id = value;
  }
}
