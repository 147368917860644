import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    if (value.includes('T')) {
      value = value.split('T')[1];
    }

    let hours: number = parseInt(value.split(':')[0], 10);
    const minutes: string = value.split(':')[1];
    const suffix: string = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    value = `${((hours + 11) % 12) + 1}:${minutes} ${suffix}`;
    return value;
  }
}
