import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ParticipantGroupCohort, ParticipantGroupCohortList } from '../../../../Models';

export abstract class ParticipantGroupCohortActions extends LsAction {
  static PARTICIPANT_GROUP_COHORT_LIST = 'PARTICIPANT_GROUP_COHORTS';
  static PARTICIPANT_GROUP_COHORT = 'PARTICIPANT_GROUP_COHORT';
  static getParticipantGroupCohortList = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT_LIST,
      ActionType.GET
    ),
    props<{ participantGroupId: number | string }>()
  );

  static setParticipantGroupCohortList = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT_LIST,
      ActionType.SET
    ),
    props<{ participantGroupCohortList: ParticipantGroupCohortList[] }>()
  );

  static patchParticipantGroupCohort = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT,
      ActionType.PATCH
    ),
    props<{ participantGroupId: number; participantGroupCohort: any }>()
  );

  static patchParticipantGroupCohorts = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT_LIST,
      ActionType.PATCH
    ),
    props<{ participantGroupId: number; participantGroupCohorts: any[] }>()
  );

  static clearParticipantGroupCohortList = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT_LIST,
      ActionType.CLEAR
    )
  );

  static getParticipantGroupCohort = createAction(
    ParticipantGroupCohortActions.getActionName(ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT, ActionType.GET),
    props<{ participantGroupId: number; id: number }>()
  );

  static setActiveParticipantGroupCohort = createAction(
    ParticipantGroupCohortActions.getActionName(ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT, ActionType.SET),
    props<{ participantGroupCohort: ParticipantGroupCohort }>()
  );

  static createParticipantGroupCohort = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT,
      ActionType.SAVE
    ),
    props<{
      participantGroupId: number;
      participantGroupCohort: ParticipantGroupCohort;
    }>()
  );

  static updateParticipantGroupCohort = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT,
      ActionType.UPDATE
    ),
    props<{
      participantGroupId: number;
      participantGroupCohort: ParticipantGroupCohort;
    }>()
  );

  static participantGroupCohortSaveUnsuccessful = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT,
      ActionType.ERROR
    ),
    props<{ errors: string[] }>()
  );

  static participantGroupCohortSaveSuccessful = createAction(
    ParticipantGroupCohortActions.getActionName(
      ParticipantGroupCohortActions.PARTICIPANT_GROUP_COHORT,
      ActionType.COMPLETE
    )
  );
}
