import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class FacilityGroupMethod extends GenericDropdownOption<string> {
  static readonly DEBT: string = 'DEBT';
  static readonly SELL: string = 'SELL';
  static readonly SELL_DEBT: string = 'SELL_DEBT';
  constructor(id?: string, name?: string, isActive?: boolean) {
    super(id, name, isActive);
  }
}
