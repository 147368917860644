import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Guid {
  New(dashed = true) {
    const guid = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return dashed ? guid : guid.replace('-', '');
  }
}
