import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { InstrumentState } from './instrument-reducer';

@Injectable()
export class InstrumentSelectors {
  appState = (state: any) => state.instrument;

  selectInstruments = createSelector(this.appState, (state: InstrumentState) =>
    state?.instrumentPages == null ? null : copy(state.instrumentPages)
  );

  selectActiveInstrument = createSelector(this.appState, (state: InstrumentState) =>
    state?.activeInstrument == null ? null : copy(state.activeInstrument)
  );

  selectInstrumentsComponentState = createSelector(this.appState, (state: InstrumentState) => ({
    instrumentPages: state?.instrumentPages,
    instrumentsActivePage: state?.instrumentsActivePage
  }));
}
