import { ParticipantOffer } from './ParticipantOffer';
import { Page } from './Page';
import { ParticipantOfferTotals } from './ParticipantOfferTotals';

export class ParticipantOfferData {
  page: Page<ParticipantOffer>;
  totals: ParticipantOfferTotals;

  constructor(page?: Page<ParticipantOffer>, totals?: ParticipantOfferTotals) {
    this.page = page ?? null;
    this.totals = totals ?? null;
  }
}
