import { InstrumentApprovalStatus } from './InstrumentApprovalStatus';

export class FacilityInstrumentApprovalStatus {
  instrumentApprovalStatus?: InstrumentApprovalStatus;
  constructor(instrumentApprovalStatus?: InstrumentApprovalStatus) {
    this.instrumentApprovalStatus = instrumentApprovalStatus
      ? new InstrumentApprovalStatus(
          instrumentApprovalStatus.id,
          instrumentApprovalStatus.name,
          instrumentApprovalStatus.isActive
        )
      : new InstrumentApprovalStatus();
  }
}
