import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { FacilityGroupState } from './facility-group-reducer';
import { OpsSelectors } from '../Generic/ops-selectors';
import { FacilityGroupDetailData } from '../../Resolver/FacilityGroupData.resolver';

@Injectable()
export class FacilityGroupSelectors {
  constructor(private opsSelectors: OpsSelectors) {}
  facilityGroupState = (state: any) => state.facilityGroup;

  selectFacilityGroupsComponentState = createSelector(this.facilityGroupState, (state: FacilityGroupState) => ({
    facilityGroupPages: copy(state?.facilityGroupPages),
    facilityGroupsActivePage: copy(state?.facilityGroupsActivePage)
  }));

  selectFacilityGroups = createSelector(this.facilityGroupState, (state: FacilityGroupState) =>
    state.facilityGroupPages == null ? null : copy(state.facilityGroupPages)
  );

  selectFacilityGroupsActivePage = createSelector(this.facilityGroupState, (state: FacilityGroupState) =>
    state.facilityGroupsActivePage == null ? null : copy(state.facilityGroupsActivePage)
  );

  selectActiveFacilityGroup = createSelector(this.facilityGroupState, (state: FacilityGroupState) =>
    state.activeFacilityGroup == null ? null : copy(state.activeFacilityGroup)
  );

  selectFacilityGroupFacilitiesComponentState = createSelector(
    this.facilityGroupState,
    (state: FacilityGroupState) => ({
      facilityGroupFacilitiesPages: copy(state?.facilityGroupFacilitiesPages),
      facilityGroupFacilitiesActivePage: copy(state?.facilityGroupFacilitiesActivePage)
    })
  );

  selectFacilityGroupState = createSelector(
    this.selectFacilityGroupFacilitiesComponentState,
    this.selectActiveFacilityGroup,
    (fgfComponentState, activeFacilityGroup) => ({
      fgfComponentState,
      activeFacilityGroup
    })
  );

  selectFacilityGroupDetailData = createSelector(
    this.selectActiveFacilityGroup,
    this.opsSelectors.selectFacilityGroupMethods,
    this.opsSelectors.selectProducts,
    (activeFacilityGroup, facilityGroupMethods, products) => {
      const facilityGroupData: FacilityGroupDetailData = {
        activeFacilityGroup,
        facilityGroupMethods,
        products
      };
      return facilityGroupData;
    }
  );
}
