import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { ActiveFilter } from '../Components/App Components/data-grid/grid-filter-modal/grid-filter-modal.component';
import { PageRequest } from './PageRequest';
import { GridSort } from '@limestone/ls-shared-modules';

export class ActivePage {
  page?: PageEvent;
  sort?: GridSort[];
  filter?: Map<string, ActiveFilter[]>;
  status?: string;

  constructor(page?: PageEvent, sort?: GridSort[], filter?: Map<string, ActiveFilter[]>, status?: string) {
    this.page = page ?? null;
    this.sort = sort ?? null;
    this.filter = filter ?? null;
    this.status = status ?? null;
  }

  toPageRequest(): PageRequest {
    return new PageRequest(
      this.page?.pageIndex ? this.page?.pageIndex + 1 : 1,
      this.page?.pageSize ?? 25,
      this.sort,
      this.filter
    );
  }
}
