import { ReferenceRateMethod, ReferenceRateTenor, ReferenceRateType } from './ReferenceRateType';
import { DiscountMethod } from './DiscountMethod';
import { DiscountCalcType } from './DiscountCalcType';
import { Participant } from './Participant';
import { DiscountBasisType } from './DiscountBasisType';
import { DayCount } from './DayCount';

export class FacilityParticipantConfiguration {
  id?: number;
  facilityConfigId?: number;
  facilityId?: number;
  participant?: Participant;
  allocatedPercent?: number;
  discountCalculationType?: DiscountCalcType;
  discountMethod?: DiscountMethod;
  marginRate?: number;
  referenceRateType?: ReferenceRateType;
  referenceRateMethod?: ReferenceRateMethod;
  referenceRateTenor?: ReferenceRateTenor;
  discountBasisType?: DiscountBasisType;
  dayCount?: DayCount;
  isDirectParticipant?: boolean;
  isAutoAccept?: boolean;
  isActive?: boolean;

  constructor(
    id?: number,
    facilityConfigId?: number,
    facilityId?: number,
    participant?: Participant,
    allocatedPercent?: number,
    discountCalculationType?: DiscountCalcType,
    discountMethod?: DiscountMethod,
    marginRate?: number,
    referenceRateTenor?: ReferenceRateTenor,
    discountBasisType?: DiscountBasisType,
    dayCount?: DayCount,
    isDirectParticipant?: boolean,
    isAutoAccept?: boolean,
    isActive?: boolean,
    referenceRateType?: ReferenceRateType,
    referenceRateMethod?: ReferenceRateMethod
  ) {
    this.id = id ?? null;
    this.facilityConfigId = facilityConfigId ?? null;
    this.facilityId = facilityId ?? null;
    this.participant = participant
      ? new Participant(participant.id, participant.name, participant.isActive)
      : new Participant();
    this.allocatedPercent = allocatedPercent ?? null;
    this.discountCalculationType = discountCalculationType
      ? new DiscountCalcType(discountCalculationType.id, discountCalculationType.name, discountCalculationType.isActive)
      : new DiscountCalcType();
    this.discountMethod = discountMethod
      ? new DiscountMethod(discountMethod.id, discountMethod.name, discountMethod.isActive)
      : new DiscountMethod();
    this.marginRate = marginRate ?? null;
    this.referenceRateTenor = referenceRateTenor
      ? new ReferenceRateTenor(referenceRateTenor.id, referenceRateTenor.name, referenceRateTenor.tenor)
      : new ReferenceRateTenor();
    this.discountBasisType = discountBasisType
      ? new DiscountBasisType(discountBasisType.id, discountBasisType.name, discountBasisType.isActive)
      : new DiscountBasisType();
    this.dayCount = dayCount ? new DayCount(dayCount.id, dayCount.name, dayCount.isActive) : new DayCount();
    this.isDirectParticipant = isDirectParticipant ?? false;
    this.isAutoAccept = isAutoAccept ?? false;
    this.isActive = isActive ?? true;
    this.referenceRateType = referenceRateType
      ? new ReferenceRateType(
          referenceRateType.id,
          referenceRateType.name,
          referenceRateType.currencyCode,
          referenceRateType.tenors,
          referenceRateType.referenceRateLockingDays
        )
      : new ReferenceRateType();
    this.referenceRateMethod = referenceRateMethod
      ? new ReferenceRateMethod(referenceRateMethod.id, referenceRateMethod.name, referenceRateMethod.isActive)
      : new ReferenceRateMethod();
  }
}
