import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgControl,
  NgForm,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { LsValidators } from '@limestone/ls-shared-modules';
import { CommonMethodComponentBase } from '../CommonMethodComponentBase';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'ls-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CurrencyInputComponent }]
})
export class CurrencyInputComponent
  extends CommonMethodComponentBase
  implements MatFormFieldControl<string>, ControlValueAccessor, Validator, DoCheck, OnDestroy
{
  static nextId: number = 0;
  @ViewChild('currencyValue') currencyInput: HTMLInputElement;

  formGroup: FormGroup<{
    currencyCode: FormControl<string | null>;
    currencyValue: FormControl<string | null>;
  }>;

  stateChanges = new Subject<void>();
  focused: boolean = false;
  touched: boolean;
  controlType: string = 'ls-currency-input';
  @HostBinding() id: string = `ls-currency-input-${CurrencyInputComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  protected readonly CURRENCY_VALUE = 'currencyValue';
  protected _currencyCode = 'USD';
  private currencyValidator = LsValidators.currency(this._currencyCode);

  constructor(
    private fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super();

    this.buildForm();

    if (this.ngControl !== null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  buildForm() {
    if (this.formGroup) {
      this.formGroup.removeControl(this.CURRENCY_VALUE);
    }
    this.formGroup = this.fb.group({
      currencyCode: this._currencyCode,
      currencyValue: [this.value, [this.currencyValidator]]
    });
  }

  get empty(): boolean {
    return !this.formGroup?.get(this.CURRENCY_VALUE)?.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input() displayCurrencySymbol: boolean = false;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  private _required: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    return this.formGroup?.get(this.CURRENCY_VALUE)?.value;
  }
  set value(val: string | null) {
    this.formGroup?.get(this.CURRENCY_VALUE)?.setValue(val, { emitEvent: false });
    this.stateChanges.next();
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.formGroup.markAllAsTouched();
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.ls-currency-input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.currencyInput, 'program');
  }

  registerOnChange(onChange: (currencyValue: string) => void): void {
    this.formGroup
      .get(this.CURRENCY_VALUE)
      .valueChanges.pipe(takeUntil(this.componentTeardown$), map(onChange))
      .subscribe();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(currency: string | null) {
    this.value = currency ?? null;
  }

  errorState: boolean = false;
  private updateErrorState() {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState =
      (this.ngControl?.invalid || this.formGroup.invalid) && (this.ngControl.touched || parent.submitted);

    if (this.formGroup?.get(this.CURRENCY_VALUE)?.hasError('required')) {
      this.ngControl?.control?.setErrors({ required: true }, { emitEvent: false });
    } else if (this.formGroup?.get(this.CURRENCY_VALUE)?.hasError('lsCurrencyPattern')) {
      this.ngControl?.control?.setErrors({ lsCurrencyPattern: true }, { emitEvent: false });
    } else if (this.formGroup?.get(this.CURRENCY_VALUE)?.value < this._currencyMin) {
      this.ngControl?.control?.setErrors({ currencyMin: { minValue: this._currencyMin } }, { emitEvent: false });
    } else {
      this.ngControl?.control?.setErrors(null, { emitEvent: false });
    }

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  @Input() set currencyCode(currencyCode: string) {
    if (this.formGroup.get(this.CURRENCY_VALUE).hasValidator(this.currencyValidator)) {
      this.formGroup.get(this.CURRENCY_VALUE).removeValidators(this.currencyValidator);
    }
    this._currencyCode = currencyCode;
    this.currencyValidator = LsValidators.currency(this._currencyCode);
    this.formGroup.get(this.CURRENCY_VALUE).addValidators(this.currencyValidator);
  }

  protected _currencyFormat: 'wide' | 'narrow' = 'wide';
  @Input() set currencyFormat(format: 'wide' | 'narrow') {
    this._currencyFormat = format;
  }

  protected _currencyLocale: string = 'en-US';
  @Input() set currencyLocale(locale: string) {
    this._currencyLocale = locale;
  }

  protected _currencyLabel: string;
  @Input() set currencyLabel(label: string) {
    this._currencyLabel = label;
  }

  protected _currencyRequired: boolean = false;
  @Input() set currencyRequired(required: boolean) {
    this._currencyRequired = required;
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  protected _currencyMin: string | number;
  @Input() set currencyMin(currencyMin: number) {
    this._currencyMin = currencyMin;
  }

  private _decimalsAllowed = true;
  @Input() set decimalsAllowed(allowed: boolean) {
    this._decimalsAllowed = allowed;
  }

  public handleInput(input: KeyboardEvent): boolean {
    const regex = /^[0-9.]$/;
    const allowedKeys = ['ArrowRight', 'ArrowLeft', 'Backspace', 'Delete', 'Tab'];
    if (!(regex.test(input.key) || allowedKeys.find((k) => k === input.key))) {
      return false;
    }
    if (input.key === '.') {
      if (!this._decimalsAllowed || this.formGroup?.get(this.CURRENCY_VALUE)?.value?.indexOf('.') >= 0) {
        return false;
      }
    }
  }

  public inputChanged(): void {
    this.stateChanges.next();
  }

  onValidatorChange = () => {};
  registerOnValidatorChange(onValidatorChange: () => void) {
    this.onValidatorChange = onValidatorChange;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let errors: any = null;

    errors = this.addControlErrors(errors, this.formGroup.get(this.CURRENCY_VALUE), this.CURRENCY_VALUE);

    return errors;
  }

  addControlErrors(allErrors: any, control: AbstractControl<any, any>, controlName: string) {
    let errors: any = allErrors ? { ...allErrors } : null;

    const controlErrors = control.errors;

    if (controlErrors) {
      if (!errors) {
        errors = {};
      }
      errors[controlName] = controlErrors;
    }

    return errors;
  }
}
