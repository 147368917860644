import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class DiscountCalcType extends GenericDropdownOption<string> {
  static readonly SIMPLE = 'SMPL';
  static readonly YIELD_TO_MATURITY = 'YTM';
  static readonly FLAT = 'FLAT';

  roleType: string;

  constructor(id?: string, name?: string, isActive?: boolean, roleType?: string) {
    super(id, name, isActive);
    this.roleType = roleType;
  }
}
