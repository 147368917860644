import { RelationshipStatus, ProductCurrency, SupplierProductCurrency } from './index';

export class Supplier {
  companyId?: number;
  relationshipStatus: RelationshipStatus;
  supplierProductCurrencies: SupplierProductCurrency[];

  constructor(
    companyId?: number,
    relationshipStatus?: RelationshipStatus,
    supplierProductCurrencies?: SupplierProductCurrency[]
  ) {
    this.companyId = companyId ?? null;
    this.relationshipStatus = relationshipStatus
      ? new RelationshipStatus(relationshipStatus.id, relationshipStatus.name, relationshipStatus.isActive)
      : new RelationshipStatus();
    this.supplierProductCurrencies = supplierProductCurrencies
      ? supplierProductCurrencies.map(
          (spc) =>
            new SupplierProductCurrency(
              spc.isActive,
              spc.supplierId,
              spc?.productCurrency
                ? new ProductCurrency(
                    spc.productCurrency.id,
                    spc.productCurrency.productId,
                    spc.productCurrency.isActive,
                    spc.productCurrency.currency
                  )
                : new ProductCurrency()
            )
        )
      : new Array<SupplierProductCurrency>();
  }
}
