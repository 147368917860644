import {
  FacilityDebtor,
  FacilitySeller,
  SourceChannel,
  ProductCurrency,
  FacilityConfigList,
  LienPosition,
  PayXcelType,
  LimitBasisType,
  FacilityInstrumentApprovalStatus,
  MaturityOffset
} from '.';
import { DaySelectionTypes } from './DaySelectionOption';

export class Facility {
  id?: number;
  isAutoXcelEnabled?: boolean;
  isRemittanceEmailSent?: boolean;
  isUnfinancedCollected?: boolean;
  seller?: FacilitySeller;
  debtor?: FacilityDebtor;
  sourceChannel?: SourceChannel;
  productCurrency?: ProductCurrency;
  facilityConfigs?: FacilityConfigList[];
  ignoreCreditNotes?: boolean;
  maturityType: DaySelectionTypes;
  maturityDaysOfWeek: number[];
  maturityDaysOfMonth: number[];
  lienPosition?: LienPosition;
  payXcelType?: PayXcelType;
  relationshipLimit?: string;
  limitBasisType?: LimitBasisType;
  facilityInstrumentApprovalStatuses?: FacilityInstrumentApprovalStatus[];
  facilityGroupId?: number;
  participantGroupId?: number;
  maturityOffset?: MaturityOffset;
  isApproved?: boolean;
  canApprove?: boolean;
  sysStartTime?: Date;

  constructor(
    id?: number,
    isAutoXcelEnabled?: boolean,
    isRemittanceEmailSent?: boolean,
    isUnfinancedCollected?: boolean,
    seller?: FacilitySeller,
    debtor?: FacilityDebtor,
    sourceChannel?: SourceChannel,
    productCurrency?: ProductCurrency,
    facilityConfigs?: FacilityConfigList[],
    ignoreCreditNotes?: boolean,
    maturityType?: DaySelectionTypes,
    maturityDaysOfWeek?: number[],
    maturityDaysOfMonth?: number[],
    lienPosition?: LienPosition,
    payXcelType?: PayXcelType,
    relationshipLimit?: string,
    limitBasisType?: LimitBasisType,
    facilityInstrumentApprovalStatuses?: FacilityInstrumentApprovalStatus[],
    facilityGroupId?: number,
    participantGroupId?: number,
    maturityOffset?: MaturityOffset,
    isApproved?: boolean,
    canApprove?: boolean,
    sysStartTime?: Date
  ) {
    this.id = id ?? null;
    this.isAutoXcelEnabled = isAutoXcelEnabled ?? true;
    this.isRemittanceEmailSent = isRemittanceEmailSent ?? false;
    this.isUnfinancedCollected = isUnfinancedCollected ?? true;
    this.seller = seller ? new FacilitySeller(seller.id, seller.name, seller.isActive) : new FacilitySeller();
    this.debtor = debtor ? new FacilityDebtor(debtor.id, debtor.name, debtor.isActive) : new FacilityDebtor();
    this.sourceChannel = sourceChannel
      ? new SourceChannel(sourceChannel.id, sourceChannel.name, sourceChannel.isActive)
      : new SourceChannel();
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : new ProductCurrency();
    this.maturityType = maturityType ?? null;
    this.maturityDaysOfWeek = maturityDaysOfWeek ?? new Array<number>();
    this.maturityDaysOfMonth = maturityDaysOfMonth ?? new Array<number>();
    this.ignoreCreditNotes = ignoreCreditNotes ?? false;
    this.facilityConfigs = facilityConfigs
      ? facilityConfigs.map(
          (f) =>
            new FacilityConfigList(
              f.id,
              f.startDate,
              f.endDate,
              f.advanceRate,
              f.marginRate,
              f.referenceRateType,
              f.revenueSharingProgramId,
              f.revenueSharingProgramName,
              f.isApproved
            )
        )
      : new Array<FacilityConfigList>();
    this.lienPosition = lienPosition
      ? new LienPosition(lienPosition.id, lienPosition.name, lienPosition.isActive)
      : new LienPosition();
    this.payXcelType = payXcelType
      ? new PayXcelType(payXcelType.id, payXcelType.name, payXcelType.isActive)
      : new PayXcelType();
    this.relationshipLimit = relationshipLimit;
    this.limitBasisType = limitBasisType
      ? new LimitBasisType(limitBasisType.id, limitBasisType.name, limitBasisType.isActive)
      : new LimitBasisType();
    this.facilityInstrumentApprovalStatuses = facilityInstrumentApprovalStatuses
      ? facilityInstrumentApprovalStatuses.map(
          (fias) => new FacilityInstrumentApprovalStatus(fias.instrumentApprovalStatus)
        )
      : new Array<FacilityInstrumentApprovalStatus>();
    this.facilityGroupId = facilityGroupId;
    this.participantGroupId = participantGroupId;
    this.maturityOffset = maturityOffset
      ? new MaturityOffset(maturityOffset.id, maturityOffset.name, maturityOffset.sortOrder, maturityOffset.isActive)
      : new MaturityOffset();
    this.isApproved = isApproved;
    this.canApprove = canApprove;
    this.sysStartTime = sysStartTime;
  }
}
