import { NgModule } from '@angular/core';
import { Guid } from './Guid';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [Guid]
})
export class UtilsModule {}
