import { Component, OnInit } from '@angular/core';
import { NavigationService } from './Services';
import { Store } from '@ngrx/store';
import { AppActions } from './AppStateManagement';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../environments/environment';

@Component({
  selector: 'ls-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public navigation: NavigationService, private store: Store, private authService: MsalService) {
    this.navigation.startSaveHistory();
    this.store.dispatch(AppActions.getConfig());
  }

  ngOnInit() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      const lsAccount = accounts.find((acc) => {
        if (acc.idTokenClaims) {
          const sso_token = acc.idTokenClaims['tfp'] as string;
          return sso_token.toLowerCase() === environment.policyName.toLowerCase();
        }
        return false;
      });
      if (!lsAccount) {
        throw new Error('No Raistone account found.');
      }
      this.authService.instance.setActiveAccount(lsAccount);
    }
  }
}
