import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import {
  PageRequest,
  Pol,
  Page,
  PolView,
  CashAppInbound,
  CashAppStatus,
  ExportRequest,
  ActivePage
} from '../../../../Models';

export abstract class PolActions extends LsAction {
  /* eslint-disable */
  static POLS: Readonly<string> = 'POLS';
  static POL: Readonly<string> = 'POL';
  static POLS_ACTIVE_PAGE: Readonly<string> = 'POLS_ACTIVE_PAGE';
  static POLS_EXPORT: Readonly<string> = 'POLS/EXPORT';
  /* eslint-enable */

  static getPols = createAction(
    PolActions.getActionName(PolActions.POLS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setPols = createAction(
    PolActions.getActionName(PolActions.POLS, ActionType.SET),
    props<{ page: Page<Pol> }>()
  );

  static clearPols = createAction(PolActions.getActionName(PolActions.POLS, ActionType.CLEAR));

  static getPol = createAction(PolActions.getActionName(PolActions.POL, ActionType.GET), props<{ id: number }>());
  static setPol = createAction(PolActions.getActionName(PolActions.POL, ActionType.SET), props<{ pol: PolView }>());

  static getPolsActivePage = createAction(PolActions.getActionName(PolActions.POLS_ACTIVE_PAGE, ActionType.GET));

  static setPolsActivePage = createAction(
    PolActions.getActionName(PolActions.POLS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static triggerPolsExport = createAction(
    PolActions.getActionName(PolActions.POLS_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static polsExportComplete = createAction(PolActions.getActionName(PolActions.POLS_EXPORT, ActionType.COMPLETE));

  static polsExportUnsuccessful = createAction(
    PolActions.getActionName(PolActions.POLS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
