import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataGridPaginatorComponent } from '../grid-pagination/data-grid-paginator.component';

export class DataGridSelectionService {
  private selection: SelectionModel<any>;
  private singleSelect: boolean;
  private multiSelect: boolean;
  private lastSelectedSegmentRow: number = null;

  /**
   * Returns all selected values.
   */
  get selected(): any[] {
    return this.selection ? this.selection.selected : [];
  }

  /**
   * Sets the selection mode.
   *
   * @param mode Mode of the selection. 'single' or 'multi'
   */
  public setSelectionMode(mode: 'single' | 'multi') {
    if (mode === 'single') {
      this.singleSelect = true;
      this.multiSelect = false;
      this.selection = new SelectionModel<any>(false);
    }
    if (mode === 'multi') {
      this.multiSelect = true;
      this.singleSelect = false;
      this.selection = new SelectionModel<any>(true, []);
    }
  }

  /**
   * Clears selected values.
   */
  public clear(): void {
    if (this.selection) {
      this.selection.clear();
    }
  }

  /**
   * Checks if all boxes are selected.
   */
  public isAllSelected(dataSource: MatTableDataSource<any>): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Returns whether or not th current row is selected.
   *
   * @param row Row to be checked for selection value.
   */
  public isSelected(row: any): boolean {
    return this.selection ? this.selection.isSelected(row) : false;
  }

  /**
   * Toggles selection for all rows.
   *
   * @param dataSource Grid data of the table.
   */
  public masterToggle(dataSource: MatTableDataSource<any>): void {
    if (this.isAllSelected(dataSource)) {
      this.selection.clear();
      return;
    }

    this.selection.select(...dataSource.data);
  }

  /**
   * Selects individual row. Deselects if already selected.
   *
   * @param row Row to be selected or deselected.
   */
  public select(row: any): any {
    if (this.multiSelect || this.singleSelect) {
      if (this.selection.isSelected(row)) {
        this.selection.deselect(row);
      } else {
        this.selection.select(row);
      }
      return this.selection.selected;
    }
  }

  /**
   * Handles selection for shift select behavior.
   *
   * @param event MouseEvent
   * @param lastRow lastRow of the shift-select.
   * @param dataSource Grid data of the table.
   * @param paginator Data grid paginator
   */
  public shiftSelect(
    event: MouseEvent,
    lastRow: any,
    dataSource: MatTableDataSource<any>,
    paginator: DataGridPaginatorComponent
  ): void {
    if (paginator) {
      lastRow = lastRow + paginator.currentPage.pageIndex * paginator.currentPage.pageSize;
    }
    if (event.shiftKey) {
      const obj: any[] = Object.assign([], dataSource.data);

      obj.forEach((row, i) => {
        if (this.lastSelectedSegmentRow <= lastRow) {
          if (i >= this.lastSelectedSegmentRow && i < lastRow) {
            if (!this.selection.isSelected(row)) {
              this.selection.select(row);
            }
          }
        } else {
          if (i > lastRow && i <= this.lastSelectedSegmentRow) {
            if (!this.selection.isSelected(row)) {
              this.selection.select(row);
            }
          }
        }
      });
    }
    this.lastSelectedSegmentRow = lastRow;
  }

  /**
   * Checks if any selection has been made.
   */
  public hasValue(): boolean {
    return this.selection?.hasValue();
  }
}
