import { ParticipantOfferPols } from './ParticipantOfferPols';

export class ParticipantOffer {
  offerId: number;
  offerStatusId: string;
  facilityGroupName: string;
  compartmentName: string;
  participantName: string;
  participantGroupName: string;
  settlementDate: string;
  currencyCode: string;
  netAmount: string;
  advanceAmount: string;
  purchasePrice: string;
  participantDiscounts: string;
  discountCalcTypeName: string;
  facilityGroupId: number;
  compartmentId: number;
  participantId: number;
  participantGroupId: number;
  pols: ParticipantOfferPols[];
  facilityGroupMethodId: string;
  operationsOwnerName: string;

  constructor(
    offerId?: number,
    offerStatusId?: string,
    facilityGroupName?: string,
    compartmentName?: string,
    participantName?: string,
    participantGroupName?: string,
    settlementDate?: string,
    currencyCode?: string,
    netAmount?: string,
    advanceAmount?: string,
    purchasePrice?: string,
    participantDiscounts?: string,
    discountCalcTypeName?: string,
    facilityGroupId?: number,
    compartmentId?: number,
    participantId?: number,
    participantGroupId?: number,
    pols?: ParticipantOfferPols[],
    facilityGroupMethodId?: string,
    operationsOwnerName?: string
  ) {
    this.offerId = offerId ?? null;
    this.offerStatusId = offerStatusId ?? null;
    this.facilityGroupName = facilityGroupName ?? null;
    this.compartmentName = compartmentName ?? null;
    this.participantName = participantName ?? null;
    this.participantGroupName = participantGroupName ?? null;
    this.settlementDate = settlementDate ?? null;
    this.currencyCode = currencyCode ?? null;
    this.netAmount = netAmount ?? null;
    this.advanceAmount = advanceAmount ?? null;
    this.purchasePrice = purchasePrice ?? null;
    this.participantDiscounts = participantDiscounts ?? null;
    this.discountCalcTypeName = discountCalcTypeName ?? null;
    this.facilityGroupId = facilityGroupId ?? null;
    this.compartmentId = compartmentId ?? null;
    this.participantId = participantId ?? null;
    this.participantGroupId = participantGroupId ?? null;
    this.pols = pols
      ? pols.map(
          (p) =>
            new ParticipantOfferPols(
              p.id,
              p.pobId,
              p.sellerName,
              p.sellerId,
              p.debtorName,
              p.debtorId,
              p.instrumentType,
              p.instrumentNumber,
              p.instrumentId,
              p.currencyCode,
              p.netAmount,
              p.advanceRate,
              p.advanceAmount,
              p.purchasePrice,
              p.participantDiscounts,
              p.participantDiscountPercent,
              p.financingTenor,
              p.expectedDueDate
            )
        )
      : new Array<ParticipantOfferPols>();
    this.facilityGroupMethodId = facilityGroupMethodId ?? null;
    this.operationsOwnerName = operationsOwnerName ?? null;
  }
}
