import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouteAuthorizationService } from '../Services/RouteAuthorization.service';
import { Observable } from 'rxjs';

@Injectable()
export class RouteAuthorizationGuard {
  constructor(private routeAuthService: RouteAuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.routeAuthService.canViewOrEdit(state.url, route);
  }
}
