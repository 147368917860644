<button color="primary" mat-raised-button [matMenuTriggerFor]="columnMenu">
  <ng-content></ng-content>
</button>
<mat-menu #columnMenu="matMenu">
  <div class="drag-container d-flex flex-column" cdkDropList dkDropListLockAxis="y" cdkDropListOrientation="vertical">
    <div
      class="d-flex flex-row justify-content-start align-items-center"
      *ngFor="let column of columnInfo; let i = index"
      cdkDrag
      [cdkDragDisabled]="!dragColumns"
      [cdkDragData]="{ columnIndex: i, columnTitle: column.name }"
      (cdkDragDropped)="columnMenuDropped($event)">
      <mat-icon class="ls-mb-3" *ngIf="dragColumns" cdkDragHandle color="accent">drag_indicator</mat-icon>
      <mat-checkbox
        class="ls-mb-3"
        [disabled]="column.preventBeingHidden"
        [checked]="!column.hidden"
        (click)="$event.stopPropagation()"
        (change)="toggleSelectedColumn(column.name)"
        color="primary">
        {{ column.name | titlecase }}
      </mat-checkbox>

      <mat-card class="d-flex flex-row justify-content-start align-items-center" *cdkDragPreview>
        <mat-icon color="accent">drag_indicator</mat-icon>
        <mat-checkbox [checked]="!column.hidden" color="primary">
          {{ column.name | titlecase }}
        </mat-checkbox>
      </mat-card>
    </div>
  </div>
</mat-menu>
