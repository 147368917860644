import { PaymentMethod } from './PaymentMethod';

export class BankAccountPaymentMethod {
  paymentMethod?: PaymentMethod;
  constructor(paymentMethod?: PaymentMethod) {
    this.paymentMethod = paymentMethod
      ? new PaymentMethod(paymentMethod.id, paymentMethod.name, paymentMethod.isActive)
      : null;
  }
}
