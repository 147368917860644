import { Component, inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Settings } from 'luxon';
import { GeneralUtils } from '../../Utils/GeneralUtils/GeneralUtils';

@Component({
  selector: 'ls-core',
  template: ''
})
export class CoreComponent implements OnDestroy {
  protected componentTeardown$ = new Subject();
  public router = inject(Router);
  public activatedRoute = inject(ActivatedRoute);
  public isGridHidden: boolean;
  readonly initialZone = Settings.defaultZone;

  useUtcDatePicker() {
    Settings.defaultZone = 'utc';
  }

  resetDatePicker() {
    Settings.defaultZone = this.initialZone;
  }
  navToChild(id: number | string) {
    this.router.navigate([id], { relativeTo: this.activatedRoute });
  }

  navToParent(levels: number = 1): void {
    const path = this.router.routerState.snapshot.url.split('/');
    path.splice(path.length - levels, levels);
    this.router.navigate(path, { replaceUrl: true });
  }

  navToView(path: string | UrlTree): void {
    this.router.navigateByUrl(path);
  }

  ngOnDestroy() {
    this.resetDatePicker();
    this.componentTeardown$.next(null);
    this.componentTeardown$.complete();
  }

  public isLoadingInProgress(isLoading: boolean) {
    this.isGridHidden = isLoading;
  }

  /**
   * Navigates to a page by removing the last n segments of the current path and appending the new subpath.
   * @param segmentRemoveCount The number of segments to remove from the current path.
   * @param newSubPath The new subpath to append to the path.
   * @param queryParam The query parameter to append to the path.
   * @param queryParamValue The value of the query parameter. Requires query param to exist.
   * @example assuming a path of ops/setup/facilities/1, navToPage(2, ['facility-groups', '3'], 'tab', 'details') => /facility-groups/3?tab=details
   */
  navToPage(segmentRemoveCount: number, newSubPath: string[], queryParam?: string, queryParamValue?: string): void {
    const path = this.router.routerState.snapshot.url.split('/');
    path.splice(path.length - segmentRemoveCount);
    path.push(...newSubPath);

    const queryParams = GeneralUtils.exists(queryParam) ? `?${queryParam}=${queryParamValue}` : '';

    this.navToView(path.join('/') + queryParams);
  }
}
