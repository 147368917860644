import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { PobState } from './pob-reducer';

@Injectable()
export class PobSelectors {
  appState = (state: any) => state.pob;

  selectPobs = createSelector(this.appState, (state: PobState) =>
    state?.pobPages == null ? null : copy(state.pobPages)
  );

  selectActivePob = createSelector(this.appState, (state: PobState) =>
    state?.activePob == null ? null : copy(state.activePob)
  );

  selectLookups = createSelector(this.appState, (state: PobState) => ({
    pobFinancingStatuses: state?.pobFinancingStatuses
  }));

  selectPobsComponentState = createSelector(this.appState, (state: PobState) => ({
    pobPages: state?.pobPages,
    pobsActivePage: state?.pobsActivePage
  }));
}
