import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class ContactType extends GenericDropdownOption<string> {
  public static readonly ACP: string = 'ACP';
  public static readonly PRI: string = 'PRI';
  public static readonly SEC: string = 'SEC';
  constructor(id: string, name: string, isActive: boolean) {
    super(id, name, isActive);
    this.type = name;
  }

  type: string;

  label(): string {
    return this.name;
  }
}
