import { ProductCurrency } from './index';

export class SupplierProductCurrency {
  supplierId?: number;
  productCurrency: ProductCurrency;
  isActive: boolean;

  constructor(isActive?: boolean, supplierId?: number, productCurrency?: ProductCurrency) {
    this.supplierId = supplierId;
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : new ProductCurrency();
    this.isActive = isActive ?? false;
  }
}
