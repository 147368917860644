import { ContactType } from './ContactType';
import { DropdownOption } from '@limestone/ls-shared-modules';

export class CompanyContact implements DropdownOption {
  contactId: number | string;
  userId?: number;
  emailAddress: string;
  isActive: boolean;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  contactType?: ContactType;
  isBeneficialOwner: boolean;

  constructor(
    contactId: number | string,
    emailAddress: string,
    isActive: boolean,
    userId?: number,
    firstName?: string,
    lastName?: string,
    jobTitle?: string,
    contactType?: ContactType,
    isBeneficialOwner?: boolean
  ) {
    this.contactId = contactId;
    this.emailAddress = emailAddress;
    this.isActive = isActive ?? true;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.jobTitle = jobTitle;
    this.contactType = contactType ? new ContactType(contactType.id, contactType.name, contactType.isActive) : null;
    this.isBeneficialOwner = isBeneficialOwner ?? false;
  }

  label(): string {
    return `${this.firstName} ${this.lastName} ${this.emailAddress ?? ''}`;
  }

  value(): CompanyContact {
    return this;
  }
}
