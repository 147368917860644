import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { ParticipantGroupState } from './participant-group-reducer';
import { OpsSelectors } from '../Generic/ops-selectors';
import { FacilityGroupSelectors } from '../FacilityGroup/facility-group-selectors';

@Injectable()
export class ParticipantGroupSelectors {
  constructor(private OpsSelectors: OpsSelectors, private FacilityGroupSelectors: FacilityGroupSelectors) {}
  participantGroupState = (state: any) => state.participantGroup;

  selectParticipantGroupsComponentState = createSelector(
    this.participantGroupState,
    (state: ParticipantGroupState) => ({
      participantGroupPages: copy(state?.participantGroupPages),
      participantGroupsActivePage: copy(state?.participantGroupsActivePage)
    })
  );

  selectParticipantGroups = createSelector(this.participantGroupState, (state: ParticipantGroupState) =>
    state.participantGroupPages == null ? null : copy(state.participantGroupPages)
  );

  selectParticipantGroupsActivePage = createSelector(this.participantGroupState, (state: ParticipantGroupState) =>
    state.participantGroupsActivePage == null ? null : copy(state.participantGroupsActivePage)
  );

  selectActiveParticipantGroup = createSelector(this.participantGroupState, (state: ParticipantGroupState) =>
    state.activeParticipantGroup == null ? null : copy(state.activeParticipantGroup)
  );

  selectAutocompleteParticipantGroups = createSelector(this.participantGroupState, (state: ParticipantGroupState) =>
    copy(state.participantGroupAutocompleteOptions)
  );

  selectParticipantGroupCohortList = createSelector(this.participantGroupState, (state: ParticipantGroupState) =>
    state.participantGroupCohortList == null ? null : copy(state.participantGroupCohortList)
  );

  selectParticipantGroupDetailData = createSelector(
    this.selectActiveParticipantGroup,
    this.OpsSelectors.selectAllocationMethods,
    this.OpsSelectors.selectProducts,
    this.selectParticipantGroupCohortList,
    this.FacilityGroupSelectors.selectActiveFacilityGroup,
    (participantGroup, allocationMethods, products, cohortList, facilityGroup) => ({
      participantGroup,
      allocationMethods,
      cohortList,
      products,
      facilityGroup
    })
  );
}
