import { Component, Inject } from '@angular/core';
import { ErrorModalComponent } from '../App Components/error-modal/error-modal.component';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBar as MatSnackBar
} from '@angular/material/legacy-snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ls-error-sb',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./error-snack-bar.component.scss']
})
export class ErrorSnackBarComponent {
  public message = 'Data save was not successful';

  constructor(public snackBar: MatSnackBar, public modal: MatDialog, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  get errors() {
    return Array.isArray(this.data) ? this.data : this.data.errors;
  }
  viewDetails(): void {
    this.modal.open(ErrorModalComponent, { data: this.data.errors ? this.data.errors : this.data });
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }
}
