export class ParticipantOfferTotals {
  totalOffers: number;
  totalNetAmount: string;
  totalAdvanceAmount: string;
  totalPurchasePrice: string;
  totalParticipantDiscounts: string;

  constructor(
    totalOffers?: number,
    totalNetAmount?: string,
    totalAdvanceAmount?: string,
    totalPurchasePrice?: string,
    totalParticipantDiscounts?: string
  ) {
    this.totalOffers = totalOffers ?? null;
    this.totalNetAmount = totalNetAmount ?? null;
    this.totalAdvanceAmount = totalAdvanceAmount ?? null;
    this.totalPurchasePrice = totalPurchasePrice ?? null;
    this.totalParticipantDiscounts = totalParticipantDiscounts ?? null;
  }
}
