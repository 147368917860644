import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class AddressType extends GenericDropdownOption<string> {
  constructor(id?: string, name?: string, isActive?: boolean) {
    super(id, name, isActive);
  }
  static readonly B = 'B';
  static readonly P = 'P';
  static readonly T = 'T';
}
