import { ParticipantGroupCohortParticipant } from './ParticipantGroupCohortParticipant';

export class ParticipantGroupCohort {
  id: string | number;
  participantGroupId: number;
  cohortPriority: number;
  settlementDays: number;
  daysOfWeek: string;
  daysOfMonth: string;
  weekDaysCount: number;
  monthDaysCount: number;
  graceDays: number;
  deemedDisputeDays: number;
  writeOffDays: number;
  limitBasisTypeId: string;
  purchaseCadenceTypeId: string;
  participants: Array<ParticipantGroupCohortParticipant>;

  constructor(
    id?: string | number,
    participantGroupId?: number,
    cohortPriority?: number,
    settlementDays?: number,
    daysOfWeek?: string,
    daysOfMonth?: string,
    weekDaysCount?: number,
    monthDaysCount?: number,
    graceDays?: number,
    deemedDisputeDays?: number,
    writeOffDays?: number,
    limitBasisTypeId?: string,
    purchaseCadenceTypeId?: string,
    participants?: Array<ParticipantGroupCohortParticipant>
  ) {
    this.id = id;
    this.participantGroupId = participantGroupId;
    this.cohortPriority = cohortPriority;
    this.settlementDays = settlementDays;
    this.daysOfWeek = daysOfWeek;
    this.daysOfMonth = daysOfMonth;
    this.weekDaysCount = weekDaysCount;
    this.monthDaysCount = monthDaysCount;
    this.graceDays = graceDays;
    this.deemedDisputeDays = deemedDisputeDays;
    this.writeOffDays = writeOffDays;
    this.limitBasisTypeId = limitBasisTypeId;
    this.purchaseCadenceTypeId = purchaseCadenceTypeId;
    this.participants = participants?.map(
      (p) =>
        new ParticipantGroupCohortParticipant(
          p.id,
          p.fundedParticipantId,
          p.assetSplitPercent,
          p.exposurePercent,
          p.discountCalcTypeId,
          p.discountMethodId,
          p.marginRate,
          p.referenceRateTypeId,
          p.referenceRateMethodId,
          p.referenceRateTenorId,
          p.discountBasisTypeId,
          p.dayCountId,
          p.offerCutoff,
          p.minTenor,
          p.maxTenor,
          p.maxPolLimit,
          p.minDailyThreshold,
          p.participantCreditLimitAmount,
          p.creditLimitBufferAmount,
          p.creditLimitAlertPercent,
          p.minMonthlyAmount,
          p.minMonthlyPercent,
          p.minChargedDays
        )
    );
  }
}
