import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { CompanyContactState } from './company-contact-reducer';

@Injectable()
export class CompanyContactSelectors {
  appState = (state: any) => state.companyContact;
  selectContactTypes = createSelector(this.appState, (state: CompanyContactState) => copy(state.contactTypes));

  selectAutocompleteContacts = createSelector(this.appState, (state: CompanyContactState) =>
    copy(state.contactAutocompleteOptions)
  );
}
