import { Action, createReducer, on } from '@ngrx/store';
import { AppActions } from './app-actions';
import { User, Configuration } from '../Models';

export interface AppState {
  user: User;
  config: Configuration;
}

export const initialState: AppState = {
  user: null,
  config: null
};

const appReducer = createReducer(
  initialState,
  on(AppActions.setUser, (state, { user }) => ({ ...state, user })),
  on(AppActions.setConfig, (state, { config }) => ({ ...state, config }))
);

export const reducer = (state: AppState | undefined, action: Action) => appReducer(state, action);
