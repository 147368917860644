// @ts-ignore

export class FinancingInbound {
  facilityGroupId: number;
  facilityGroupName: string;
  originatorId: number;
  originatorName: string;
  originatorRoleTypeName: string;
  proposedEffectiveDate: Date;
  compartmentId: number;
  compartmentName: string;
  receiptDate: Date;
  effectiveDate: Date;
  remittanceReferenceId: number;
  remittancePurpose: string;
  paymentMethodName: string;
  currencyCode: string;
  remittanceNetAmount: string;
  remittanceAllocatedAmount: string;
  remittanceUnallocatedAmount: string;
  remitStatusId: string;

  constructor(
    remittanceReferenceId?: number,
    remitStatusId?: string,
    facilityGroupId?: number,
    facilityGroupName?: string,
    originatorId?: number,
    originatorName?: string,
    originatorRoleTypeName?: string,
    proposedEffectiveDate?: Date,
    compartmentId?: number,
    compartmentName?: string,
    receiptDate?: Date,
    effectiveDate?: Date,
    remittancePurpose?: string,
    paymentMethodName?: string,
    currencyCode?: string,
    remittanceNetAmount?: string,
    remittanceAllocatedAmount?: string,
    remittanceUnallocatedAmount?: string
  ) {
    this.facilityGroupId = facilityGroupId;
    this.facilityGroupName = facilityGroupName;
    this.originatorId = originatorId;
    this.originatorName = originatorName;
    this.originatorRoleTypeName = originatorRoleTypeName;
    this.proposedEffectiveDate = proposedEffectiveDate;
    this.compartmentId = compartmentId;
    this.compartmentName = compartmentName;
    this.receiptDate = receiptDate;
    this.effectiveDate = effectiveDate;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittancePurpose = remittancePurpose;
    this.paymentMethodName = paymentMethodName;
    this.currencyCode = currencyCode;
    this.remittanceNetAmount = remittanceNetAmount;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.remitStatusId = remitStatusId;
  }
}
