import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RoleMap } from '../Models/RoleMap';
import { HttpClient } from '@angular/common/http';
import { AppSelectors } from '../AppStateManagement';

@Injectable({
  providedIn: 'root'
})
export class RouteAuthorizationService {
  private ROLE_MAP: RoleMap;

  constructor(
    private router: Router,
    private store: Store<any>,
    private http: HttpClient,
    private appSelectors: AppSelectors
  ) {
    this.getRoleMap()
      .pipe(
        take(1),
        map((res: RoleMap) => (this.ROLE_MAP = res))
      )
      .subscribe();
  }

  canViewOrEdit(path: string, route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(this.appSelectors.selectUser).pipe(
      map((user) => {
        const baseRoute = path.split('/')[1];
        if (user.opsRole && baseRoute === 'ops') {
          const roleVal = user.opsRole;
          this.canEdit(path, roleVal, route);
          return this.canView(path, roleVal);
        } else if (user.participantRole && baseRoute === 'participant') {
          const roleVal = user.participantRole;
          this.canEdit(path, roleVal, route);
          return this.canView(path, roleVal);
        } else if (user.supplierRole && baseRoute === 'supplier') {
          const roleVal = user.supplierRole;
          this.canEdit(path, roleVal, route);
          return this.canView(path, roleVal);
        } else {
          // TODO: Add this back when roles have been added to identity claims.
          // this.router.navigate(['login'])
          // return false
          return true;
        }
      })
    );
  }

  private canView(path: string, roleVal: number): boolean {
    const roleClaims = this.ROLE_MAP.ops.find((roleDef) => roleDef.route === path);
    const canView = roleClaims ? (roleVal & roleClaims.view) === roleClaims.view : false;
    // if(!canView) this.router.navigate(['unauthorized']);
    return canView;
  }

  private canEdit(path: string, roleVal: number, route: ActivatedRouteSnapshot): void {
    const roleClaims = this.ROLE_MAP.ops.find((roleDef) => roleDef.route === path);
    const canEdit = roleClaims ? (roleVal & roleClaims.edit) === roleClaims.edit : false;
    if (canEdit) {
      route.data = { canEdit };
    }
  }

  getRoleMap(): Observable<RoleMap> {
    return this.http.get<RoleMap>('/api/role-map');
  }
}
