import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ENV_NAME } from 'src/environments/environment.common';

@Injectable()
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor() {
    if (environment.envName !== ENV_NAME.LOCAL) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.InstrumentationKey,
          enableAutoRouteTracking: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logException(exception: Error, securityLevel?: SeverityLevel): void {
    this.appInsights.trackException({ exception });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name }, properties);
  }
}
