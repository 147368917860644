export class PolOwner {
  id: number;
  ownershipEventId: number;
  transferDate: Date;
  companyName: string;
  ownershipTypeName: string;
  allocatedPercent: number;
  isActive: boolean;

  constructor(
    id: number,
    ownershipEventId: number,
    transferDate: Date,
    companyName: string,
    ownershipTypeName: string,
    allocatedPercent: number,
    isActive: boolean
  ) {
    this.id = id ?? null;
    this.ownershipEventId = ownershipEventId ?? null;
    this.transferDate = transferDate ?? null;
    this.companyName = companyName ?? null;
    this.ownershipTypeName = ownershipTypeName ?? null;
    this.allocatedPercent = allocatedPercent ?? null;
    this.isActive = isActive ?? null;
  }
}
