import { ActiveFilter } from '../Components/App Components/data-grid/grid-filter-modal/grid-filter-modal.component';
import { PageRequest } from './PageRequest';
import { GridSort } from '@limestone/ls-shared-modules';

export class ExportRequest extends PageRequest {
  // Array of sorts, applied in order, with format: 'propertyName,sortDirection' example: 'name,desc'.
  // If not specified, sort direction defaults to asc
  sort?: string[];
  filter?: string;
  options?: any[];

  constructor(sorts?: GridSort[], filters?: Map<string, ActiveFilter[]>, options?: any[]) {
    super(null, null);
    if (sorts) {
      this.setSort(sorts);
    }
    if (filters) {
      this.setFilters(filters);
    }
    if (options) {
      this.options = options;
    }
  }
}
