import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { ReferenceRateState } from './reference-rate-reducer';

@Injectable()
export class ReferenceRateSelectors {
  appState = (state: any) => state.referenceRate;

  selectReferenceRateTypes = createSelector(this.appState, (state: ReferenceRateState) =>
    copy(state.referenceRateTypes)
  );

  selectReferenceRatePublication = createSelector(this.appState, (state: ReferenceRateState) =>
    copy(state.referenceRatePublication)
  );

  selectActiveReferenceRateType = createSelector(this.appState, (state: ReferenceRateState) =>
    copy(state.activeReferenceRateType)
  );

  selectReferenceRateMethods = createSelector(this.appState, (state: ReferenceRateState) =>
    copy(state.referenceRateMethods)
  );

  selectMethodsAndTypes = createSelector(this.appState, (state: ReferenceRateState) => ({
    referenceRateMethods: copy(state.referenceRateMethods),
    referenceRateTypes: copy(state.referenceRateTypes)
  }));
}
