import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { ImportState } from './import-reducer';
import { FacilityState } from '../Facility/facility-reducer';

@Injectable()
export class ImportSelectors {
  appState = (state: any) => state.import;

  selectImportActivityComponentState = createSelector(this.appState, (state: ImportState) => ({
    importActivity: copy(state.importActivity),
    importActivityActivePage: copy(state.importActivityActivePage),
    importActivityActiveDate: copy(state.importActivityActiveDate)
  }));

  selectImportActivity = createSelector(this.appState, (state: ImportState) => copy(state.importActivity));
  selectImportActivityErrors = createSelector(this.appState, (state: ImportState) => copy(state.importActivityErrors));
}
