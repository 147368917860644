import { BankCurrency } from './BankCurrency';
import { Company } from './Company';
import { GenericDropdownOption } from '@limestone/ls-shared-modules';
import { RelationshipStatus } from './RelationshipStatus';

export class Bank extends GenericDropdownOption<number> {
  companyId?: number;
  company?: Company;
  relationshipStatus: RelationshipStatus;
  bankCurrencies: BankCurrency[];

  constructor(
    companyId?: number,
    company?: Company,
    relationshipStatus?: RelationshipStatus,
    bankCurrencies?: BankCurrency[],
    isActive?: boolean
  ) {
    super(companyId, company?.name, isActive ?? false);
    this.companyId = companyId;
    this.company = company;
    this.relationshipStatus = relationshipStatus
      ? new RelationshipStatus(relationshipStatus.id, relationshipStatus.name, relationshipStatus.isActive)
      : new RelationshipStatus();
    this.bankCurrencies = bankCurrencies
      ? bankCurrencies.map((bac) => new BankCurrency(bac.isActive, bac.bankId, bac.currency))
      : new Array<BankCurrency>();
  }
}
