import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import {
  AddressType,
  AllocationMethod,
  BankAccountAlias,
  BankAccountPurpose,
  BankAccountType,
  MaturityOffset,
  CapacityBasisType,
  ChannelProgram,
  Currency,
  DiscountCalcType,
  DiscountMethod,
  FacilityGroupMethod,
  Industry,
  InstrumentApprovalSource,
  InstrumentApprovalStatus,
  IntegrationType,
  LienPosition,
  LimitBasisType,
  PaymentMethod,
  PayXcelType,
  Product,
  ProductCurrency,
  PurchaseCadenceType,
  RelationshipStatus,
  RevenueSharingProgram,
  TimeZone,
  UserRole
} from '../../../../Models';
import { Country, GenericDropdownOption, StateProvince } from '@limestone/ls-shared-modules';

export abstract class OpsActions extends LsAction {
  /* eslint-disable */
  static COUNTRIES: Readonly<string> = 'COUNTRIES';
  static STATES: Readonly<string> = 'STATES';
  static ADDRESS_TYPES: Readonly<string> = 'ADDRESS-TYPES';
  static TIME_ZONES: Readonly<string> = 'TIME_ZONES';
  static PRODUCT: Readonly<string> = 'PRODUCT';
  static DISCOUNT_METHOD: Readonly<string> = 'DISCOUNT-METHOD';
  static DISCOUNT_CALC_TYPE: Readonly<string> = 'DISCOUNT-CALC-TYPE';
  static REVENUE_SHARING_PROGRAM: Readonly<string> = 'REVENUE-SHARING-PROGRAM';
  static CURRENCIES: Readonly<string> = 'CURRENCIES';
  static PAYMENT_METHODS: Readonly<string> = 'PAYMENT_METHODS';
  static BANK_ACCOUNT_PURPOSE: Readonly<string> = 'BANK_ACCOUNT_PURPOSE';
  static BANK_ACCOUNT_ALIAS: Readonly<string> = 'BANK_ACCOUNT_ALIAS';
  static PARTICIPANT_BANK_ACCOUNT_ALIAS: Readonly<string> = 'PARTICIPANT_BANK_ACCOUNT_ALIAS';
  static USER_ROLES: Readonly<string> = 'USER_ROLES';
  static INGEST_CHANNEL_NAME_DROPDOWN_OPTIONS: Readonly<string> = 'INGEST_CHANNEL_NAME_DROPDOWN_OPTIONS';
  static INGEST_IMPORT_STATUS_DROPDOWN_OPTIONS: Readonly<string> = 'INGEST_IMPORT_STATUS_DROPDOWN_OPTIONS';
  static INGEST_ERROR_MESSAGE_DROPDOWN_OPTIONS: Readonly<string> = 'INGEST_ERROR_MESSAGE_DROPDOWN_OPTIONS';
  static INDUSTRIES: Readonly<string> = 'INDUSTRIES';
  static INTEGRATION_TYPES: Readonly<string> = 'INTEGRATION_TYPES';
  static CHANNELS: Readonly<string> = 'CHANNELS';
  static CHANNEL_PROGRAMS: Readonly<string> = 'CHANNEL_PROGRAMS';
  static RELATIONSHIP_STATUSES: Readonly<string> = 'RELATIONSHIP_STATUSES';
  static LIMIT_BASIS_TYPES: Readonly<string> = 'LIMIT_BASIS_TYPES';
  static LIEN_POSITIONS: Readonly<string> = 'LIEN_POSITIONS';
  static MATURITY_OFFSETS: Readonly<string> = 'MATURITY_OFFSETS';
  static PAY_XCEL_TYPES: Readonly<string> = 'PAY_XCEL_TYPES';
  static INSTRUMENT_APPROVAL_SOURCES: Readonly<string> = 'INSTRUMENT_APPROVAL_SOURCES';
  static INSTRUMENT_APPROVAL_STATUSES: Readonly<string> = 'INSTRUMENT_APPROVAL_STATUSES';
  static CAPACITY_BASIS_TYPES: Readonly<string> = 'CAPACITY_BASIS_TYPES';
  static BANK_ACCOUNT_TYPES: Readonly<string> = 'BANK_ACCOUNT_TYPES';
  static PRODUCT_CURRENCIES: Readonly<string> = 'PRODUCT_CURRENCIES';
  static ALLOCATION_METHODS: Readonly<string> = 'ALLOCATION_METHODS';
  static FACILITY_GROUP_METHODS: Readonly<string> = 'FACILITY_GROUP_METHODS';
  static PURCHASE_CADENCE_TYPES: Readonly<string> = 'PURCHASE_CADENCE_TYPES';
  /* eslint-enable */

  static getCountries = createAction(OpsActions.getActionName(OpsActions.COUNTRIES, ActionType.GET));
  static setCountries = createAction(
    OpsActions.getActionName(OpsActions.COUNTRIES, ActionType.SET),
    props<{ countries: Country[] }>()
  );

  static getStates = createAction(OpsActions.getActionName(OpsActions.STATES, ActionType.GET));
  static setStates = createAction(
    OpsActions.getActionName(OpsActions.STATES, ActionType.SET),
    props<{ states: StateProvince[] }>()
  );

  static getAddressTypes = createAction(OpsActions.getActionName(OpsActions.ADDRESS_TYPES, ActionType.GET));
  static setAddressTypes = createAction(
    OpsActions.getActionName(OpsActions.ADDRESS_TYPES, ActionType.SET),
    props<{ addressTypes: AddressType[] }>()
  );

  static getTimeZones = createAction(OpsActions.getActionName(OpsActions.TIME_ZONES, ActionType.GET));
  static setTimeZones = createAction(
    OpsActions.getActionName(OpsActions.TIME_ZONES, ActionType.SET),
    props<{ timeZones: TimeZone[] }>()
  );

  static getProducts = createAction(OpsActions.getActionName(OpsActions.PRODUCT, ActionType.GET));
  static setProducts = createAction(
    OpsActions.getActionName(OpsActions.PRODUCT, ActionType.SET),
    props<{ products: Product[] }>()
  );

  static getDiscountMethods = createAction(OpsActions.getActionName(OpsActions.DISCOUNT_METHOD, ActionType.GET));
  static setDiscountMethods = createAction(
    OpsActions.getActionName(OpsActions.DISCOUNT_METHOD, ActionType.SET),
    props<{ discountMethods: DiscountMethod[] }>()
  );

  static getDiscountCalcTypes = createAction(OpsActions.getActionName(OpsActions.DISCOUNT_CALC_TYPE, ActionType.GET));
  static setDiscountCalcTypes = createAction(
    OpsActions.getActionName(OpsActions.DISCOUNT_CALC_TYPE, ActionType.SET),
    props<{ discountCalcTypes: DiscountCalcType[] }>()
  );

  static getRevenueSharingPrograms = createAction(
    OpsActions.getActionName(OpsActions.REVENUE_SHARING_PROGRAM, ActionType.GET)
  );
  static setRevenueSharingPrograms = createAction(
    OpsActions.getActionName(OpsActions.REVENUE_SHARING_PROGRAM, ActionType.SET),
    props<{ revenueSharingPrograms: RevenueSharingProgram[] }>()
  );

  static getCurrencies = createAction(OpsActions.getActionName(OpsActions.CURRENCIES, ActionType.GET));
  static setCurrencies = createAction(
    OpsActions.getActionName(OpsActions.CURRENCIES, ActionType.SET),
    props<{ currencies: Currency[] }>()
  );

  static getPaymentMethods = createAction(OpsActions.getActionName(OpsActions.PAYMENT_METHODS, ActionType.GET));
  static setPaymentMethods = createAction(
    OpsActions.getActionName(OpsActions.PAYMENT_METHODS, ActionType.SET),
    props<{ paymentMethods: PaymentMethod[] }>()
  );

  static getBankAccountPurposes = createAction(
    OpsActions.getActionName(OpsActions.BANK_ACCOUNT_PURPOSE, ActionType.GET)
  );
  static setBankAccountPurposes = createAction(
    OpsActions.getActionName(OpsActions.BANK_ACCOUNT_PURPOSE, ActionType.SET),
    props<{ bankAccountPurposes: BankAccountPurpose[] }>()
  );

  static getBankAccountAliases = createAction(
    OpsActions.getActionName(OpsActions.BANK_ACCOUNT_ALIAS, ActionType.GET),
    props<{ companyId: number; facilityParticipantConfigId?: number }>()
  );
  static setBankAccountAliases = createAction(
    OpsActions.getActionName(OpsActions.BANK_ACCOUNT_ALIAS, ActionType.SET),
    props<{ bankAccountAliases: BankAccountAlias[] }>()
  );
  static setParticipantBankAccountAliases = createAction(
    OpsActions.getActionName(OpsActions.PARTICIPANT_BANK_ACCOUNT_ALIAS, ActionType.SET),
    props<{ bankAccountAliases: BankAccountAlias[] }>()
  );

  static clearParticipantBankAccountAliases = createAction(
    OpsActions.getActionName(OpsActions.PARTICIPANT_BANK_ACCOUNT_ALIAS, ActionType.CLEAR)
  );

  static getUserRoles = createAction(OpsActions.getActionName(OpsActions.USER_ROLES, ActionType.GET));
  static setUserRoles = createAction(
    OpsActions.getActionName(OpsActions.USER_ROLES, ActionType.SET),
    props<{ userRoles: UserRole[] }>()
  );

  static getIngestChannelNameDropdownOptions = createAction(
    OpsActions.getActionName(OpsActions.INGEST_CHANNEL_NAME_DROPDOWN_OPTIONS, ActionType.GET)
  );
  static setIngestChannelNameDropdownOptions = createAction(
    OpsActions.getActionName(OpsActions.INGEST_CHANNEL_NAME_DROPDOWN_OPTIONS, ActionType.SET),
    props<{ ingestChannelNameDropdownOptions: GenericDropdownOption<number | string>[] }>()
  );

  static getIngestImportStatusDropdownOptions = createAction(
    OpsActions.getActionName(OpsActions.INGEST_IMPORT_STATUS_DROPDOWN_OPTIONS, ActionType.GET)
  );
  static setIngestImportStatusDropdownOptions = createAction(
    OpsActions.getActionName(OpsActions.INGEST_IMPORT_STATUS_DROPDOWN_OPTIONS, ActionType.SET),
    props<{ ingestImportStatusDropdownOptions: GenericDropdownOption<number | string>[] }>()
  );

  static getIngestErrorMessageDropdownOptions = createAction(
    OpsActions.getActionName(OpsActions.INGEST_ERROR_MESSAGE_DROPDOWN_OPTIONS, ActionType.GET)
  );
  static setIngestErrorMessageDropdownOptions = createAction(
    OpsActions.getActionName(OpsActions.INGEST_ERROR_MESSAGE_DROPDOWN_OPTIONS, ActionType.SET),
    props<{ ingestErrorMessageDropdownOptions: GenericDropdownOption<number | string>[] }>()
  );

  static getRelationshipStatuses = createAction(
    OpsActions.getActionName(OpsActions.RELATIONSHIP_STATUSES, ActionType.GET)
  );
  static setRelationshipStatuses = createAction(
    OpsActions.getActionName(OpsActions.RELATIONSHIP_STATUSES, ActionType.SET),
    props<{ relationshipStatuses: RelationshipStatus[] }>()
  );

  static getBankAccountTypes = createAction(OpsActions.getActionName(OpsActions.BANK_ACCOUNT_TYPES, ActionType.GET));
  static setBankAccountTypes = createAction(
    OpsActions.getActionName(OpsActions.BANK_ACCOUNT_TYPES, ActionType.SET),
    props<{ bankAccountTypes: BankAccountType[] }>()
  );

  static getLimitBasisTypes = createAction(OpsActions.getActionName(OpsActions.LIMIT_BASIS_TYPES, ActionType.GET));
  static setLimitBasisTypes = createAction(
    OpsActions.getActionName(OpsActions.LIMIT_BASIS_TYPES, ActionType.SET),
    props<{ limitBasisTypes: LimitBasisType[] }>()
  );

  static getLienPositions = createAction(OpsActions.getActionName(OpsActions.LIEN_POSITIONS, ActionType.GET));
  static setLienPositions = createAction(
    OpsActions.getActionName(OpsActions.LIEN_POSITIONS, ActionType.SET),
    props<{ lienPositions: LienPosition[] }>()
  );

  static getMaturityOffsets = createAction(OpsActions.getActionName(OpsActions.MATURITY_OFFSETS, ActionType.GET));
  static setMaturityOffsets = createAction(
    OpsActions.getActionName(OpsActions.MATURITY_OFFSETS, ActionType.SET),
    props<{ maturityOffsets: MaturityOffset[] }>()
  );

  static getPayXcelTypes = createAction(OpsActions.getActionName(OpsActions.PAY_XCEL_TYPES, ActionType.GET));
  static setPayXcelTypes = createAction(
    OpsActions.getActionName(OpsActions.PAY_XCEL_TYPES, ActionType.SET),
    props<{ payXcelTypes: PayXcelType[] }>()
  );
  static getCapacityBasisTypes = createAction(
    OpsActions.getActionName(OpsActions.CAPACITY_BASIS_TYPES, ActionType.GET)
  );
  static setCapacityBasisTypes = createAction(
    OpsActions.getActionName(OpsActions.CAPACITY_BASIS_TYPES, ActionType.SET),
    props<{ capacityBasisTypes: CapacityBasisType[] }>()
  );
  static getIndustries = createAction(OpsActions.getActionName(OpsActions.INDUSTRIES, ActionType.GET));
  static setIndustries = createAction(
    OpsActions.getActionName(OpsActions.INDUSTRIES, ActionType.SET),
    props<{
      industries: Industry[];
    }>()
  );
  static getIntegrationTypes = createAction(OpsActions.getActionName(OpsActions.INTEGRATION_TYPES, ActionType.GET));
  static setIntegrationTypes = createAction(
    OpsActions.getActionName(OpsActions.INTEGRATION_TYPES, ActionType.SET),
    props<{
      integrationTypes: IntegrationType[];
    }>()
  );
  static getChannelPrograms = createAction(OpsActions.getActionName(OpsActions.CHANNEL_PROGRAMS, ActionType.GET));
  static setChannelPrograms = createAction(
    OpsActions.getActionName(OpsActions.CHANNEL_PROGRAMS, ActionType.SET),
    props<{
      channelPrograms: ChannelProgram[];
    }>()
  );

  static getInstrumentApprovalSources = createAction(
    OpsActions.getActionName(OpsActions.INSTRUMENT_APPROVAL_SOURCES, ActionType.GET)
  );
  static setInstrumentApprovalSources = createAction(
    OpsActions.getActionName(OpsActions.INSTRUMENT_APPROVAL_SOURCES, ActionType.SET),
    props<{ instrumentApprovalSources: InstrumentApprovalSource[] }>()
  );
  static getInstrumentApprovalStatuses = createAction(
    OpsActions.getActionName(OpsActions.INSTRUMENT_APPROVAL_STATUSES, ActionType.GET)
  );
  static setInstrumentApprovalStatuses = createAction(
    OpsActions.getActionName(OpsActions.INSTRUMENT_APPROVAL_STATUSES, ActionType.SET),
    props<{ instrumentApprovalStatuses: InstrumentApprovalStatus[] }>()
  );

  static getProductCurrencies = createAction(OpsActions.getActionName(OpsActions.PRODUCT_CURRENCIES, ActionType.GET));
  static setProductCurrencies = createAction(
    OpsActions.getActionName(OpsActions.PRODUCT_CURRENCIES, ActionType.SET),
    props<{ productCurrencies: ProductCurrency[] }>()
  );

  static getAllocationMethods = createAction(OpsActions.getActionName(OpsActions.ALLOCATION_METHODS, ActionType.GET));
  static setAllocationMethods = createAction(
    OpsActions.getActionName(OpsActions.ALLOCATION_METHODS, ActionType.SET),
    props<{ allocationMethods: AllocationMethod[] }>()
  );
  static getFacilityGroupMethods = createAction(
    OpsActions.getActionName(OpsActions.FACILITY_GROUP_METHODS, ActionType.GET)
  );
  static setFacilityGroupMethods = createAction(
    OpsActions.getActionName(OpsActions.FACILITY_GROUP_METHODS, ActionType.SET),
    props<{ facilityGroupMethods: FacilityGroupMethod[] }>()
  );
  static getPurchaseCadenceTypes = createAction(
    OpsActions.getActionName(OpsActions.PURCHASE_CADENCE_TYPES, ActionType.GET)
  );
  static setPurchaseCadenceTypes = createAction(
    OpsActions.getActionName(OpsActions.PURCHASE_CADENCE_TYPES, ActionType.SET),
    props<{ purchaseCadenceTypes: PurchaseCadenceType[] }>()
  );
}
