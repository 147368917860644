import { RemitLineItem } from './RemitLineItem';

export class RemitOutboundDetails {
  facilityGroupName: string;
  facilityGroupId: number;
  recipient: string;
  recipientId: number;
  role: string;
  proposedEffectiveDate: Date;
  effectiveDate: Date;
  compartment: string;
  compartmentId: number;
  remittancePurpose: string;
  recipientBankAccount: string;
  paymentMethod: string;
  remittanceReferenceId: number;
  remittanceNetAmount: number;
  currencyCode: string;
  remittanceAllocatedAmount: number;
  remittanceUnallocatedAmount: number;
  remitStatusId: string;
  remitTypeId: string;
  lineItems: RemitLineItem[];

  constructor(
    facilityGroupName?: string,
    facilityGroupId?: number,
    recipient?: string,
    recipientId?: number,
    role?: string,
    proposedEffectiveDate?: Date,
    effectiveDate?: Date,
    compartment?: string,
    compartmentId?: number,
    remittancePurpose?: string,
    recipientBankAccount?: string,
    paymentMethod?: string,
    remittanceReferenceId?: number,
    remittanceNetAmount?: number,
    currencyCode?: string,
    remittanceAllocatedAmount?: number,
    remittanceUnallocatedAmount?: number,
    remitStatusId?: string,
    remitTypeId?: string,
    lineItems?: RemitLineItem[]
  ) {
    this.facilityGroupName = facilityGroupName;
    this.facilityGroupId = facilityGroupId;
    this.recipient = recipient;
    this.recipientId = recipientId;
    this.role = role;
    this.proposedEffectiveDate = proposedEffectiveDate;
    this.effectiveDate = effectiveDate;
    this.compartment = compartment;
    this.compartmentId = compartmentId;
    this.remittancePurpose = remittancePurpose;
    this.recipientBankAccount = recipientBankAccount;
    this.paymentMethod = paymentMethod;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittanceNetAmount = remittanceNetAmount;
    this.currencyCode = currencyCode;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.remitStatusId = remitStatusId;
    this.remitTypeId = remitTypeId;
    this.lineItems = lineItems;
  }
}
