export class FacilityGroupList {
  id?: number;
  participantGroupName?: string;
  productCurrencyCode?: string;
  facilityGroupMethodName?: string;
  operationsCenterName?: string;
  sellerName?: string;
  debtorName?: string;
  compartmentName?: string;
  operationsOwner?: string;

  constructor(
    id?: number,
    participantGroupName?: string,
    productCurrencyCode?: string,
    facilityGroupMethodName?: string,
    operationsCenterName?: string,
    sellerName?: string,
    debtorName?: string,
    compartmentName?: string,
    operationsOwner?: string
  ) {
    this.id = id ?? null;
    this.participantGroupName = participantGroupName ?? null;
    this.productCurrencyCode = productCurrencyCode ?? null;
    this.facilityGroupMethodName = facilityGroupMethodName ?? null;
    this.operationsCenterName = operationsCenterName ?? null;
    this.sellerName = sellerName ?? null;
    this.debtorName = debtorName ?? null;
    this.compartmentName = compartmentName ?? null;
    this.operationsOwner = operationsOwner ?? null;
  }
}
