import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { BlackoutCalendarState } from './blackout-calendar-reducer';

@Injectable()
export class BlackoutCalendarSelectors {
  appState = (state: any) => state.blackoutCalendar;
  selectBlackoutCalendars = createSelector(this.appState, (state: BlackoutCalendarState) =>
    state.blackoutCalendarPages == null ? null : copy(state.blackoutCalendarPages)
  );

  selectAutocompleteBlackoutCalendars = createSelector(this.appState, (state: BlackoutCalendarState) =>
    copy(state.blackoutCalendarAutocompleteOptions)
  );

  selectActiveBlackoutCalendar = createSelector(this.appState, (state: BlackoutCalendarState) =>
    state?.activeBlackoutCalendar == null ? null : copy(state.activeBlackoutCalendar)
  );

  selectBlackoutCalendarsComponentState = createSelector(this.appState, (state: BlackoutCalendarState) => ({
    blackoutCalendarPages: state?.blackoutCalendarPages,
    blackoutCalendarsActivePage: state?.blackoutCalendarsActivePage
  }));
}
