import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { OpsCenterState } from './ops-center-reducer';

@Injectable()
export class OpsCenterSelectors {
  appState = (state: any) => state.opsCenter;
  selectOpsCenters = createSelector(this.appState, (state: OpsCenterState) =>
    state.opsCenterPages == null ? null : copy(state.opsCenterPages)
  );

  selectActiveOpsCenter = createSelector(this.appState, (state: OpsCenterState) =>
    state.activeOpsCenter == null ? null : copy(state.activeOpsCenter)
  );

  selectAutocompleteOpsCenters = createSelector(this.appState, (state: OpsCenterState) =>
    copy(state.opsCenterAutocompleteOptions)
  );

  selectOpsCentersComponentState = createSelector(this.appState, (state: OpsCenterState) => ({
    opsCenterPages: copy(state?.opsCenterPages),
    opsCentersActivePage: copy(state?.opsCentersActivePage)
  }));
}
