export class FinancingInboundTotals {
  totalRemittances: number;
  totalNetAmount: string;
  totalAllocatedAmount: string;
  totalUnallocatedAmount: string;

  constructor(
    totalRemittances?: number,
    totalNetAmount?: string,
    totalAllocatedAmount?: string,
    totalUnallocatedAmount?: string
  ) {
    this.totalRemittances = totalRemittances ?? null;
    this.totalNetAmount = totalNetAmount ?? null;
    this.totalAllocatedAmount = totalAllocatedAmount ?? null;
    this.totalUnallocatedAmount = totalUnallocatedAmount ?? null;
  }
}
