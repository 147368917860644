import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class PurchaseCadenceType extends GenericDropdownOption<string> {
  static readonly DAILY: string = 'DAILY';
  static readonly WKDAY_CT: string = 'WKDAY_CT';
  static readonly WKDAY_DAYS: string = 'WKDAY_DAYS';
  static readonly MONTH_CT: string = 'MONTH_CT';
  static readonly MONTH_DAYS: string = 'MONTH_DAYS';
  constructor(id?: string, name?: string, isActive?: boolean) {
    super(id, name, isActive);
  }
}
