import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class AllocationMethod extends GenericDropdownOption<string> {
  constructor(id?: string, name?: string, isActive?: boolean) {
    super(id, name, isActive);
  }
  static readonly CUST_OVER = 'CUST_OVER';
  static readonly CUST_UTIL = 'CUST_UTIL';
  static readonly EQUAL_OVER = 'EQUAL_OVER';
  static readonly EQUAL_UTIL = 'EQUAL_UTIL';
  static readonly MANUAL = 'MANUAL';
  static readonly TOP_UTIL = 'TOP_UTIL';
}
