export class User {
  public familyName: string;
  public givenName: string;
  public username: string;

  public opsRole: number;
  public supplierRole: number;
  public participantRole: number;

  constructor(
    familyName: string,
    givenName: string,
    name: string,
    opsRole?: number,
    supplierRole?: number,
    participantRole?: number
  ) {
    this.familyName = familyName;
    this.givenName = givenName;
    this.username = name;
    this.opsRole = opsRole ?? null;
    this.supplierRole = supplierRole ?? null;
    this.participantRole = participantRole ?? null;
  }

  get fullName() {
    return `${this.givenName} ${this.familyName}`;
  }
}

export const OPS_ROLE = 'extension_LsRoles_OPS';
export const SUPPLIER_ROLE = 'extension_LsRoles_SUPPLIER';
export const PARTICIPANT_ROLE = 'extension_LsRoles_PARTICIPANT';
