import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppActions } from './app-actions';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../Services/app.service';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private appService: AppService) {}

  getConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getConfig),
      switchMap(() => this.appService.getConfig().pipe(map((config) => AppActions.setConfig({ config }))))
    )
  );
}
