import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { DayCount, DiscountBasisType } from '../../../../Models';

export abstract class DiscountBasisActions extends LsAction {
  static getDayCountArr = createAction(DiscountBasisActions.getActionName('DAY-COUNT-ARR', ActionType.GET));

  static setDayCountArr = createAction(
    DiscountBasisActions.getActionName('DAY-COUNT-ARR', ActionType.SET),
    props<{ dayCountArr: DayCount[] }>()
  );

  static setActiveDayCount = createAction(
    DiscountBasisActions.getActionName('DAY-COUNT', ActionType.SET),
    props<{ dayCount: DayCount }>()
  );

  static getDiscountBasisTypes = createAction(
    DiscountBasisActions.getActionName('DISCOUNT-BASIS-TYPES', ActionType.GET)
  );

  static setDiscountBasisTypes = createAction(
    DiscountBasisActions.getActionName('DISCOUNT-BASIS-TYPES', ActionType.SET),
    props<{ discountBasisTypes: DiscountBasisType[] }>()
  );

  static setActiveDiscountBasisType = createAction(
    DiscountBasisActions.getActionName('DISCOUNT-BASIS-TYPE', ActionType.SET),
    props<{ discountBasisType: DiscountBasisType }>()
  );
}
