import { ProductCurrency } from './ProductCurrency';
import { LimitBasisType } from './LimitBasisType';

export class FundedParticipantProductCurrency {
  fundedParticipantId?: number;
  productCurrency: ProductCurrency;
  isActive: boolean;
  creditLimitAmount?: string;
  limitBasisType: LimitBasisType;

  constructor(
    isActive?: boolean,
    fundedParticipantId?: number,
    productCurrency?: ProductCurrency,
    creditLimitAmount?: string,
    limitBasisType?: LimitBasisType
  ) {
    this.fundedParticipantId = fundedParticipantId;
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : new ProductCurrency();
    this.isActive = isActive ?? true;
    this.creditLimitAmount = creditLimitAmount;
    this.limitBasisType = limitBasisType
      ? new LimitBasisType(limitBasisType.id, limitBasisType.name, limitBasisType.isActive)
      : new LimitBasisType();
  }
}
