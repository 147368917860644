<div
  [formGroup]="formGroup"
  class="ls-currency-input-container">
  <input matInput class="ls-currency-input-element wide-input"
         #currencyValue
         [lsCurrencyDisplay]="formGroup.controls[CURRENCY_VALUE].value"
         [lsCurrencyType]="_currencyCode"
         [lsCurrencyFormat]="_currencyFormat"
         [lsCurrencyLocale]="_currencyLocale"
         [displayCurrencySymbol]="displayCurrencySymbol"
         [required]="_currencyRequired"
         type="text"
         (keydown)="handleInput($event)"
         (input)="inputChanged()"
         [formControlName]="CURRENCY_VALUE"
         (focus)="onFocusIn($event)"
         (blur)="onFocusOut($event)"/>
</div>
