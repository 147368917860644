import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FeatureFlagActions, FeatureFlagService } from '@limestone/ls-shared-modules';

@Injectable()
export class FeatureFlagsResolver {
  constructor(public router: Router, private store: Store<any>, public featureFlagService: FeatureFlagService) {}

  resolve(): Observable<any> | Promise<any> | any {
    return this.store.dispatch(FeatureFlagActions.getFeatureFlags());
  }
}
