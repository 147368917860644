import { Page } from './Page';
import { FinancingInboundTotals } from './FinancingInboundTotals';
import { FinancingInbound } from './FinancingInbound';

export class FinancingInboundData {
  page: Page<FinancingInbound>;
  totals: FinancingInboundTotals;

  constructor(page?: Page<FinancingInbound>, totals?: FinancingInboundTotals) {
    this.page = page ?? null;
    this.totals = totals ?? null;
  }
}
