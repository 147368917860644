export class ImportActivityError {
  batchId: string;
  importDate: Date;
  fileName: string;
  channel: string;
  debtor: string;
  supplier: string;
  instrumentNumber: string;
  errorMessage: string;
  currencyCode: string;
  netAmount: string;
  constructor(
    batchId?: string,
    importDate?: Date,
    fileName?: string,
    channel?: string,
    debtor?: string,
    supplier?: string,
    instrumentNumber?: string,
    currencyCode?: string,
    errorMessage?: string,
    netAmount?: string
  ) {
    this.batchId = batchId;
    this.importDate = importDate;
    this.fileName = fileName;
    this.channel = channel;
    this.debtor = debtor;
    this.supplier = supplier;
    this.instrumentNumber = instrumentNumber;
    this.errorMessage = errorMessage;
    this.currencyCode = currencyCode;
    this.netAmount = netAmount ?? null;
  }
}
