import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { CompanyState } from './company-reducer';
import { OpsSelectors } from '../Generic/ops-selectors';
import {
  AddressType,
  BankAccountType,
  CapacityBasisType,
  ChannelProgram,
  Company,
  ContactType,
  Currency,
  Industry,
  IntegrationType,
  LienPosition,
  LimitBasisType,
  MaturityOffset,
  PaymentMethod,
  PayXcelType,
  Product,
  RelationshipStatus,
  UserRole
} from '../../../../Models';
import { CompanyContactSelectors } from '../CompanyContact/company-contact-selectors';
import { Country, StateProvince } from '@limestone/ls-shared-modules';

export interface CompanyDetailData {
  activeCompany: Company;
  countries: Country[];
  states: StateProvince[];
  addressTypes: AddressType[];
  contactTypes: ContactType[];
  currencies: Currency[];
  paymentMethods: PaymentMethod[];
  userRoles: UserRole[];
  industries: Industry[];
  products: Product[];
  bankAccountTypes: BankAccountType[];
  integrationTypes: IntegrationType[];
  channelPrograms: ChannelProgram[];
  limitBasisTypes: LimitBasisType[];
  relationshipStatuses: RelationshipStatus[];
  lienPositions: LienPosition[];
  payXcelTypes: PayXcelType[];
  maturityOffsets: MaturityOffset[];
  capacityBasisTypes: CapacityBasisType[];
}

@Injectable()
export class CompanySelectors {
  constructor(private opsSelector: OpsSelectors, private contactSelector: CompanyContactSelectors) {}
  appState = (state: any) => state.company;

  selectCompanies = createSelector(this.appState, (state: CompanyState) =>
    state.companyPages == null ? null : copy(state.companyPages)
  );

  selectActiveCompany = createSelector(this.appState, (state: CompanyState) =>
    state.activeCompany == null ? null : copy(state.activeCompany)
  );

  selectAutocompleteCompanies = createSelector(this.appState, (state: CompanyState) =>
    copy(state.companyAutocompleteOptions)
  );

  selectCompaniesComponentState = createSelector(this.appState, (state: CompanyState) => ({
    companyPages: state?.companyPages,
    companiesActivePage: state?.companiesActivePage
  }));

  selectCompanyAddressData = createSelector(
    this.opsSelector.selectCountries,
    this.opsSelector.selectStates,
    this.opsSelector.selectAddressTypes,
    (countries, states, addressTypes) => {
      return {
        countries: countries,
        states: states,
        addressTypes: addressTypes
      };
    }
  );

  selectCompanyDetailData = createSelector(
    this.selectActiveCompany,
    this.opsSelector.selectCountries,
    this.opsSelector.selectStates,
    this.opsSelector.selectAddressTypes,
    this.contactSelector.selectContactTypes,
    this.opsSelector.selectCurrencies,
    this.opsSelector.selectPaymentMethods,
    this.opsSelector.selectUserRoles,
    this.opsSelector.selectIndustries,
    this.opsSelector.selectProducts,
    this.opsSelector.selectBankAccountTypes,
    this.opsSelector.selectIntegrationTypes,
    this.opsSelector.selectChannelPrograms,
    this.opsSelector.selectLimitBasisTypes,
    this.opsSelector.selectRelationshipStatuses,
    this.opsSelector.selectLienPositions,
    this.opsSelector.selectPayXcelTypes,
    this.opsSelector.selectMaturityOffsets,
    this.opsSelector.selectCapacityBasisTypes,
    (
      activeCompany,
      countries,
      states,
      addressTypes,
      contactTypes,
      currencies,
      paymentMethods,
      userRoles,
      industries,
      products,
      bankAccountTypes,
      integrationTypes,
      channelPrograms,
      limitBasisTypes,
      relationshipStatuses,
      lienPositions,
      payXcelTypes,
      maturityOffsets,
      capacityBasisTypes
    ) => {
      const companyData: CompanyDetailData = {
        activeCompany,
        countries,
        states,
        addressTypes,
        contactTypes,
        currencies,
        paymentMethods,
        userRoles,
        industries,
        products,
        bankAccountTypes,
        integrationTypes,
        channelPrograms,
        limitBasisTypes,
        relationshipStatuses,
        lienPositions,
        payXcelTypes,
        maturityOffsets,
        capacityBasisTypes
      };
      return companyData;
    }
  );
}
