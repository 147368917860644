import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, OpsCenter, Page, PageRequest } from '../../../../Models';

export abstract class OpsCenterActions extends LsAction {
  /* eslint-disable */
  static OPS_CENTERS: Readonly<string> = 'OPS_CENTERS';
  static OPS_CENTER: Readonly<string> = 'OPS_CENTER';
  static OPS_CENTERS_ACTIVE_PAGE: Readonly<string> = 'OPS_CENTERS_ACTIVE_PAGE';
  /* eslint-enable */

  static getOpsCenters = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTERS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setOpsCenters = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTERS, ActionType.SET),
    props<{ page: Page<OpsCenter> }>()
  );

  static clearOpsCenters = createAction(OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTERS, ActionType.CLEAR));

  static getOpsCenter = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTER, ActionType.GET),
    props<{ id: number }>()
  );

  static setOpsCenter = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTER, ActionType.SET),
    props<{ opsCenter: OpsCenter }>()
  );

  static createOpsCenter = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTER, ActionType.SAVE),
    props<{ opsCenter: OpsCenter }>()
  );

  static updateOpsCenter = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTER, ActionType.UPDATE),
    props<{ opsCenter: OpsCenter }>()
  );

  static opsCenterSaveSuccessful = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTER, ActionType.COMPLETE)
  );

  static opsCenterSaveUnsuccessful = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTER, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getAutocompleteOpsCenters = createAction(
    OpsCenterActions.getActionName(`AUTOCOMPLETE\/${OpsCenterActions.OPS_CENTERS}`, ActionType.GET),
    props<{ name: string; currency?: string; pageRequest?: PageRequest }>()
  );

  static setAutocompleteOpsCenters = createAction(
    OpsCenterActions.getActionName(`AUTOCOMPLETE\/${OpsCenterActions.OPS_CENTERS}`, ActionType.SET),
    props<{ page: Page<OpsCenter> }>()
  );

  static getOpsCentersActivePage = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTERS_ACTIVE_PAGE, ActionType.GET)
  );

  static setOpsCentersActivePage = createAction(
    OpsCenterActions.getActionName(OpsCenterActions.OPS_CENTERS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );
}
