import { UserRole } from './UserRole';
import { DropdownOption } from '@limestone/ls-shared-modules';

export class CompanyUser implements DropdownOption {
  userRole: UserRole;
  companyId: number;
  userId: number;
  contactId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  b2cId: string;
  isInternal: boolean = false;

  constructor(
    userRole?: UserRole,
    companyId?: number,
    userId?: number,
    contactId?: number,
    firstName?: string,
    lastName?: string,
    emailAddress?: string,
    b2cId?: string,
    isInternal?: boolean
  ) {
    this.userRole = new UserRole(userRole.id, userRole.name);
    this.companyId = companyId;
    this.userId = userId;
    this.contactId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.emailAddress = emailAddress;
    this.b2cId = b2cId;
    this.isInternal = isInternal;
  }
  label(): string {
    return `${this.firstName} ${this.lastName} ${this.emailAddress ?? ''}`;
  }

  value(): CompanyUser {
    return this;
  }
}
