<div class="d-flex flex-column">
  <div class="d-flex flex-row justify-content-between ls-mb-3">
    <h2>{{ data.column | titlecase }}</h2>
    <button
      mat-icon-button
      aria-label="Filter popup"
      color="primary"
      (click)="addFormGroup()"
      [disabled]="maxNumOfControls()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <ng-container>
    <div class="d-flex flex-column">
      <form [formGroup]="filterForm">
        <ng-container *ngFor="let form of controls">
          <div class="d-flex flex-column justify-content-between align-items-center" formGroupName="{{ form }}">
            <div class="d-flex flex-row justify-content-between ls-mb-3">
              <ng-container [ngSwitch]="data.columnDataType">
                <div
                  *ngSwitchCase="'checkbox'"
                  appearance="fill"
                  class="d-flex flex-row justify-content-between align-items-center ls-mr-3">
                  <mat-radio-group formControlName="filterValue" class="d-flex flex-row">
                    <mat-radio-button class="ls-mr-3" value="0">False</mat-radio-button>
                    <mat-radio-button value="1">True</mat-radio-button>
                  </mat-radio-group>
                </div>

                <div
                  *ngSwitchCase="['date', 'datetime'].includes(data.columnDataType) ? data.columnDataType : ''"
                  appearance="fill"
                  class="d-flex flex-row justify-content-between align-items-center ls-mr-3">
                  <mat-form-field class="ls-mr-3" appearance="fill">
                    <mat-label>Action</mat-label>
                    <mat-select formControlName="filterAction">
                      <mat-option *ngFor="let action of filterActions" [value]="action">{{ action }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngSwitchCase="['date', 'datetime'].includes(data.columnDataType) ? data.columnDataType : ''" appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="filterValue" />
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div
                  *ngSwitchCase="'select'"
                  appearance="fill"
                  class="d-flex flex-row justify-content-between align-items-center ls-mr-3">
                  <mat-form-field class="ls-mr-3" appearance="fill">
                    <mat-label>Action</mat-label>
                    <mat-select formControlName="filterAction">
                      <mat-option *ngFor="let action of filterActions" [value]="action">{{ action }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-select formControlName="filterValue" multiple>
                      <mat-option [value]="option.value()" *ngFor="let option of data.columnOptions">{{
                        option.label()
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  *ngSwitchDefault
                  appearance="fill"
                  class="d-flex flex-row justify-content-between align-items-center ls-mr-3">
                  <mat-form-field class="ls-mr-3" appearance="fill">
                    <mat-label>Action</mat-label>
                    <mat-select formControlName="filterAction">
                      <mat-option *ngFor="let action of filterActions" [value]="action">{{ action }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label>Filter Value</mat-label>
                    <input matInput [type]="data.columnDataType" formControlName="filterValue" />
                  </mat-form-field>
                </div>

                <button mat-icon-button color="warn" (click)="removeForm(form)" class="remove-btn">
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </ng-container>
            </div>
            <div class="d-flex flex-row justify-content-around align-items-center radio-btns" *ngIf="!isLast(form)">
              <mat-radio-group formControlName="filterJunction" color="primary" [required]="!isLast(form)">
                <mat-radio-button value="AND">AND</mat-radio-button>
                <mat-radio-button value="OR">OR</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </ng-container>
      </form>
      <div class="d-flex flex-row justify-content-end align-items-start">
        <button class="ls-mr-3" mat-raised-button aria-label="Filter popup" color="accent" (click)="dismiss()">Cancel</button>
        <button
          mat-raised-button
          aria-label="Filter popup"
          color="primary"
          (click)="closeModal()"
          [disabled]="!filterForm.valid">
          Apply
        </button>
      </div>
    </div>
  </ng-container>
</div>
