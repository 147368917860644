import { SellerProductCurrency } from './SellerProductCurrency';
import { RelationshipStatus } from './RelationshipStatus';
import { ProductCurrency } from './ProductCurrency';
import { LimitBasisType } from './LimitBasisType';
import { LienPosition } from './LienPosition';
import { PayXcelType } from './PayXcelType';

export class Seller {
  companyId?: number;
  relationshipStatus: RelationshipStatus;
  sellerProductCurrencies: SellerProductCurrency[];

  constructor(
    companyId?: number,
    relationshipStatus?: RelationshipStatus,
    sellerProductCurrencies?: SellerProductCurrency[]
  ) {
    this.companyId = companyId ?? null;
    this.relationshipStatus = relationshipStatus
      ? new RelationshipStatus(relationshipStatus.id, relationshipStatus.name, relationshipStatus.isActive)
      : new RelationshipStatus();
    this.sellerProductCurrencies = sellerProductCurrencies
      ? sellerProductCurrencies.map(
          (spc) =>
            new SellerProductCurrency(
              spc.isActive,
              spc.sellerId,
              spc?.productCurrency
                ? new ProductCurrency(
                    spc.productCurrency.id,
                    spc.productCurrency.productId,
                    spc.productCurrency.isActive,
                    spc.productCurrency.currency
                  )
                : new ProductCurrency(),
              spc.creditLimitAmount,
              spc?.limitBasisType
                ? new LimitBasisType(spc.limitBasisType.id, spc.limitBasisType.name, spc.limitBasisType.isActive)
                : new LimitBasisType(),
              spc.isBankAccountVerified,
              spc.isAutoXcel,
              spc?.lienPosition
                ? new LienPosition(spc.lienPosition.id, spc.lienPosition.name, spc.lienPosition.isActive)
                : new LienPosition(),
              spc?.payXcelType
                ? new PayXcelType(spc.payXcelType.id, spc.payXcelType.name, spc.payXcelType.isActive)
                : new PayXcelType()
            )
        )
      : new Array<SellerProductCurrency>();
  }
}
