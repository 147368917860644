import { Bank, BankAccountPaymentMethod } from '.';
import { Currency } from './Currency';
import { BankAccountType } from './BankAccountType';
import { Country, StateProvince } from '@limestone/ls-shared-modules';

export class BankAccount {
  alias: number | string;
  currency: Currency;
  bankAccountType: BankAccountType;
  bank: Bank;
  bankAccountPaymentMethods?: BankAccountPaymentMethod[];
  companyId: number;
  id: string | number;
  accountNumber?: number;
  routingNumber?: number;
  iban?: number | string;
  swift?: number | string;
  country?: Country;
  address1?: string;
  address2?: string;
  city?: string;
  postalCode?: string;
  stateProvince?: StateProvince;
  alternate1?: string;
  alternate2?: string;
  alternate3?: string;
  alternate4?: string;
  intermediaryBankId?: number | string;
  isActive: boolean = true;
  isFinancingAccount: boolean;
  isCashAppAccount: boolean;

  constructor(
    alias?: number | string,
    currency?: Currency,
    bankAccountPaymentMethods?: BankAccountPaymentMethod[],
    companyId?: number,
    id?: string | number,
    accountNumber?: number,
    routingNumber?: number,
    iban?: number | string,
    swift?: number | string,
    country?: Country,
    address1?: string,
    address2?: string,
    city?: string,
    postalCode?: string,
    stateProvince?: StateProvince,
    alternate1?: string,
    alternate2?: string,
    alternate3?: string,
    alternate4?: string,
    intermediaryBankId?: number | string,
    bankAccountType?: BankAccountType,
    bank?: Bank,
    isActive?: boolean,
    isFinancingAccount?: boolean,
    isCashAppAccount?: boolean
  ) {
    this.alias = alias;
    this.currency = currency
      ? new Currency(currency.currencyCode, currency.name, currency.isActive, currency.sortOrder)
      : null;
    this.bankAccountPaymentMethods = bankAccountPaymentMethods
      ? bankAccountPaymentMethods.map((bapm) => new BankAccountPaymentMethod(bapm.paymentMethod))
      : new Array<BankAccountPaymentMethod>();
    this.companyId = companyId;
    this.id = id;
    this.accountNumber = accountNumber;
    this.routingNumber = routingNumber;
    this.iban = iban;
    this.swift = swift;
    this.country = country ? new Country(country.code, country.name, country.isActive) : null;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.postalCode = postalCode;
    this.stateProvince = stateProvince
      ? new StateProvince(stateProvince.code, stateProvince.name, stateProvince.country, stateProvince.isActive)
      : null;
    this.alternate1 = alternate1;
    this.alternate2 = alternate2;
    this.alternate3 = alternate3;
    this.alternate4 = alternate4;
    this.intermediaryBankId = intermediaryBankId;
    this.bankAccountType = bankAccountType
      ? new BankAccountType(bankAccountType.id, bankAccountType.name, bankAccountType.isActive)
      : null;
    this.bank = bank
      ? new Bank(bank.companyId, bank.company, bank.relationshipStatus, bank.bankCurrencies, bank.isActive)
      : null;
    this.isActive = isActive ?? true;
    this.isFinancingAccount = isFinancingAccount ?? false;
    this.isCashAppAccount = isCashAppAccount ?? false;
  }
  get paymentMethods(): string {
    return this.bankAccountPaymentMethods.map((bapm) => bapm.paymentMethod.name).join(', ');
  }
}
