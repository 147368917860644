import { CashAppOutbound, CashAppOutboundTotals } from '.';
import { Page } from './Page';

export class CashAppOutboundData {
  page: Page<CashAppOutbound>;
  totals: CashAppOutboundTotals;

  constructor(page?: Page<any>, totals?: CashAppOutboundTotals) {
    this.page = page ?? null;
    this.totals = totals ?? null;
  }
}
