import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class RelationshipStatus extends GenericDropdownOption<string> {
  public static readonly ACT: string = 'ACT';
  public static readonly HOLD: string = 'HOLD';
  public static readonly INACT: string = 'INACT';
  public static readonly TRMN: string = 'TRMN';
  constructor(id?: string, name?: string, isActive?: boolean) {
    super(id, name, isActive);
  }
}
