import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { FinancingInboundState } from './financing-inbound-reducer';

@Injectable()
export class FinancingInboundSelectors {
  appState = (state: any) => state.financingInbound;

  selectFinancingInbound = createSelector(this.appState, (state: FinancingInboundState) => ({
    financingInboundPages: state?.financingInboundPages == null ? null : copy(state.financingInboundPages)
  }));

  selectFinancingInboundComponentState = createSelector(this.appState, (state: FinancingInboundState) => ({
    financingInboundPages: copy(state?.financingInboundPages),
    financingInboundActiveTab: copy(state.financingInboundActiveTab),
    financingInboundActivePage: copy(state?.financingInboundActivePage),
    financingInboundTotals: copy(state?.financingInboundTotals)
  }));

  selectFinancingInboundDetailsComponentState = createSelector(this.appState, (state: FinancingInboundState) => ({
    financingInboundActive: copy(state?.financingInboundActive)
  }));
}
