import { CompanyContact } from './CompanyContact';
import { CompanyAddress } from './CompanyAddress';
import { BankAccount } from './BankAccount';
import { CompanyUser } from './CompanyUser';
import { Industry } from './Industry';
import { CompanyIntegration } from './CompanyIntegration';
import { Debtor } from './Debtor';
import { Seller } from './Seller';
import { Compartment } from './Compartment';
import { FundedParticipant } from './FundedParticipant';
import { Bank } from './Bank';
import { GenericDropdownOption, StateProvince } from '@limestone/ls-shared-modules';
import { CompanyCurrencyBlackout } from './CompanyCurrencyBlackout';
import { Supplier } from './Supplier';
import { BankAccountPaymentMethod } from './BankAccountPaymentMethod';

export class Company extends GenericDropdownOption<number> {
  taxId?: string;
  parentId?: number;
  parentName?: string;
  addresses?: CompanyAddress[] = new Array<CompanyAddress>();
  companyContacts?: CompanyContact[];
  debtor?: Debtor;
  seller?: Seller;
  bankAccounts?: BankAccount[];
  users?: CompanyUser[];
  industry?: Industry;
  companyCurrencyBlackouts?: CompanyCurrencyBlackout[];
  companyIntegrations?: CompanyIntegration[];
  compartment?: Compartment;
  fundedParticipant?: FundedParticipant;
  bank?: Bank;
  supplier?: Supplier;
  formationState?: StateProvince;

  constructor(
    id?: number,
    name?: string,
    taxId?: string,
    parentId?: number,
    parentName?: string,
    companyContacts?: CompanyContact[],
    companyAddresses?: CompanyAddress[],
    debtor?: Debtor,
    seller?: Seller,
    bankAccounts?: BankAccount[],
    users?: CompanyUser[],
    industry?: Industry,
    companyCurrencyBlackouts?: CompanyCurrencyBlackout[],
    companyIntegrations?: CompanyIntegration[],
    compartment?: Compartment,
    fundedParticipant?: FundedParticipant,
    bank?: Bank,
    supplier?: Supplier,
    formationState?: StateProvince
  ) {
    super(id, name);
    this.taxId = taxId ?? null;
    this.parentId = parentId ?? null;
    this.parentName = parentName ?? null;
    this.companyContacts = companyContacts
      ? companyContacts.map(
          (cc) =>
            new CompanyContact(
              cc.contactId,
              cc.emailAddress,
              cc.isActive,
              cc.userId,
              cc.firstName,
              cc.lastName,
              cc.jobTitle,
              cc.contactType,
              cc.isBeneficialOwner
            )
        )
      : new Array<CompanyContact>();
    this.addresses = companyAddresses
      ? companyAddresses.map(
          (ca) => new CompanyAddress(ca.address, ca.addressType, ca.isActive, ca.addressId, ca.companyId)
        )
      : new Array<CompanyAddress>();
    this.debtor = debtor
      ? new Debtor(debtor.companyId, debtor.relationshipStatus, debtor.debtorProductCurrencies)
      : null;
    this.seller = seller
      ? new Seller(seller.companyId, seller.relationshipStatus, seller.sellerProductCurrencies)
      : null;
    this.fundedParticipant = fundedParticipant
      ? new FundedParticipant(
          fundedParticipant.companyId,
          fundedParticipant.relationshipStatus,
          fundedParticipant.fundedParticipantProductCurrencies
        )
      : null;
    this.bankAccounts = bankAccounts
      ? bankAccounts.map(
          (ba) =>
            new BankAccount(
              ba.alias,
              ba.currency,
              (ba.bankAccountPaymentMethods = ba.bankAccountPaymentMethods
                ? ba.bankAccountPaymentMethods.map((pm) => new BankAccountPaymentMethod(pm.paymentMethod))
                : new Array<BankAccountPaymentMethod>()),
              ba.companyId,
              ba.id,
              ba.accountNumber,
              ba.routingNumber,
              ba.iban,
              ba.swift,
              ba.country,
              ba.address1,
              ba.address2,
              ba.city,
              ba.postalCode,
              ba.stateProvince,
              ba.alternate1,
              ba.alternate2,
              ba.alternate3,
              ba.alternate4,
              ba.intermediaryBankId,
              ba.bankAccountType,
              ba.bank,
              ba.isActive,
              ba.isFinancingAccount,
              ba.isCashAppAccount
            )
        )
      : new Array<BankAccount>();
    this.users = users
      ? users.map(
          (u) =>
            new CompanyUser(
              u.userRole,
              u.companyId,
              u.userId,
              u.contactId,
              u.firstName,
              u.lastName,
              u.emailAddress,
              u.b2cId,
              u.isInternal
            )
        )
      : new Array<CompanyUser>();
    this.industry = industry ? new Industry(industry.id, industry.name, industry.isActive) : null;
    this.companyCurrencyBlackouts = companyCurrencyBlackouts
      ? companyCurrencyBlackouts.map(
          (ccb) => new CompanyCurrencyBlackout(ccb.companyId, ccb.currency, ccb.blackoutCalendar)
        )
      : new Array<CompanyCurrencyBlackout>();
    this.companyIntegrations = companyIntegrations
      ? companyIntegrations.map(
          (ci) =>
            new CompanyIntegration(
              ci.id,
              ci.companyId,
              ci.integrationType,
              ci.integrationId,
              ci.channelId,
              ci.channelProgram
            )
        )
      : new Array<CompanyIntegration>();
    this.compartment = compartment
      ? new Compartment(
          compartment.companyId,
          compartment.operationsCenter,
          compartment.relationshipStatus,
          compartment.compartmentCurrencies,
          compartment.isActive
        )
      : null;

    this.bank = bank
      ? new Bank(bank.companyId, bank.company, bank.relationshipStatus, bank.bankCurrencies, bank.isActive)
      : null;

    this.supplier = supplier
      ? new Supplier(supplier.companyId, supplier.relationshipStatus, supplier.supplierProductCurrencies)
      : null;

    this.formationState = formationState
      ? new StateProvince(formationState.code, formationState.name, formationState.country, formationState.isActive)
      : null;
  }
}
