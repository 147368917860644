import { Currency } from './Currency';

export class ProductCurrency {
  id: number;
  productId: string;
  isActive: boolean;
  currency: Currency;

  constructor(id?: number, productId?: string, isActive?: boolean, currency?: Currency) {
    this.id = id ?? null;
    this.productId = productId ?? null;
    this.isActive = isActive ?? null;
    this.currency = currency
      ? new Currency(
          currency.currencyCode,
          currency.name,
          currency.isActive,
          currency.sortOrder,
          currency.defaultDayCountId
        )
      : new Currency();
  }
}
