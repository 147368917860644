import { ValidatorFn, Validators } from '@angular/forms';
import { TextValidation } from '../../app/Components/App Components/data-grid/Models/LsDataGridConfig';
import { createLsEmailValidator } from './ls-email.validator';

export function createLsTextValidatorArray(textValidation: TextValidation) {
  let textValidations: ValidatorFn[] = [];
  if (textValidation.required) {
    textValidations.push(Validators.required);
  }
  if (textValidation.maxLength) {
    textValidations.push(Validators.maxLength(textValidation.maxLength));
  }
  if (textValidation.invalidChars) {
    textValidations.push(Validators.pattern(new RegExp(textValidation.invalidChars)));
  }
  if (textValidation.emailValidator) {
    textValidations.push(Validators.email);
    textValidations.push(createLsEmailValidator(textValidation));
  }
  if (textValidation.required) {
    textValidations.push(Validators.required);
  }
  return textValidations;
}
