export class ParticipantGroupList {
  id?: number;
  currency?: string;
  name?: string;
  opsCenterName?: string;
  allocationMethod?: string;
  constructor(id?: number, currency?: string, name?: string, opsCenterName?: string, allocationMethod?: string) {
    this.id = id;
    this.currency = currency;
    this.name = name;
    this.opsCenterName = opsCenterName;
    this.allocationMethod = allocationMethod;
  }
}
