import { ValidatorFn, Validators } from '@angular/forms';
import { NumberValidation } from '../../app/Components/App Components/data-grid/Models/LsDataGridConfig';
import { LsValidators } from '@limestone/ls-shared-modules';

export function createLsNumberValidatorArray(numberValidation: NumberValidation) {
  let numberValidations: ValidatorFn[] = [];
  if (numberValidation.currencyCode) {
    numberValidations.push(LsValidators.currency(numberValidation.currencyCode));
  }
  if (numberValidation.numberOfDecimals) {
    //language=regexp
    let pattern = `^-?([0-9]?)+(.[0-9]{0,${numberValidation.numberOfDecimals}})?$`;
    numberValidations.push(Validators.pattern(pattern));
  }
  if (numberValidation.min) {
    numberValidations.push(Validators.min(numberValidation.min));
  }
  if (numberValidation.max) {
    numberValidations.push(Validators.max(numberValidation.max));
  }
  if (numberValidation.required) {
    numberValidations.push(Validators.required);
  }
  return numberValidations;
}
