import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class ReferenceRateType extends GenericDropdownOption<string> {
  tenors: ReferenceRateTenor[];
  referenceRateLockingDays: number;
  currencyCode: string;

  static readonly CDOR_CORRA = 'CDOR';
  static readonly SOFR_TERM_SOFR = 'SOFR';
  constructor(
    id?: string,
    name?: string,
    currencyCode?: string,
    tenors?: ReferenceRateTenor[],
    referenceRateLockingDays?: number
  ) {
    super(id, name);
    this.tenors = tenors
      ? tenors.map((t) => new ReferenceRateTenor(t.id, t.name, t.tenor))
      : new Array<ReferenceRateTenor>();
    this.referenceRateLockingDays = referenceRateLockingDays;
    this.currencyCode = currencyCode;
  }
}

export class ReferenceRatePublication {
  id?: number;
  referenceRateTypeId: string;
  publicationDate: string;
  referenceRateValues?: ReferenceRateValue[];
  constructor(
    id?: number,
    referenceRateTypeId?: string,
    publicationDate?: string,
    referenceRateValues?: ReferenceRateValue[]
  ) {
    this.id = id;
    this.referenceRateTypeId = referenceRateTypeId;
    this.publicationDate = publicationDate;
    this.referenceRateValues = referenceRateValues
      ? referenceRateValues.map(
          (rrv) =>
            new ReferenceRateValue(
              rrv.id,
              rrv.referenceRateTenorId,
              rrv.referenceRateTypeId,
              rrv.referenceRatePublicationId,
              rrv.value
            )
        )
      : new Array<ReferenceRateValue>();
  }
}

export class ReferenceRateValue {
  id?: number;
  referenceRateTenorId: number;
  referenceRateTypeId: string;
  referenceRatePublicationId: number;
  value?: string;

  constructor(
    id?: number,
    referenceRateTenorId?: number,
    referenceRateTypeId?: string,
    referenceRatePublicationId?: number,
    value?: string
  ) {
    this.id = id;
    this.referenceRateTenorId = referenceRateTenorId;
    this.referenceRateTypeId = referenceRateTypeId;
    this.referenceRatePublicationId = referenceRatePublicationId;
    this.value = value;
  }
}

export class ReferenceRateTenor extends GenericDropdownOption<number> {
  tenor: number;

  constructor(id?: number, name?: string, tenor?: number) {
    super(id, name);
    this.tenor = tenor ?? null;
  }
}

export class ReferenceRateMethod extends GenericDropdownOption<string> {
  static readonly FIXED_MATURITY_BAND = 'FX';
  static readonly BACKWARD_BAND = 'B';
  constructor(id?: string, name?: string, isActive?: boolean) {
    super(id, name, isActive);
  }
}
