import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { IngestStatus } from '../../../../Models';

export abstract class IngestStatusActions extends LsAction {
  /* eslint-disable */
  static INGEST_STATUS: Readonly<string> = 'INGEST_STATUS';
  static INGEST_FILE: Readonly<string> = 'INGEST_FILE';
  /* eslint-enable */

  static getIngestStatuses = createAction(
    IngestStatusActions.getActionName(IngestStatusActions.INGEST_STATUS, ActionType.GET)
  );

  static setIngestStatuses = createAction(
    IngestStatusActions.getActionName(IngestStatusActions.INGEST_STATUS, ActionType.SET),
    props<{ ingestStatuses: IngestStatus[] }>()
  );

  static downloadIngestFile = createAction(
    IngestStatusActions.getActionName(IngestStatusActions.INGEST_FILE, ActionType.GET),
    props<{ ingestStatus: IngestStatus }>()
  );
}
