import { ChannelProgram } from './ChannelProgram';
import { IntegrationType } from './IntegrationType';
import { GenericDropdownOption } from '@limestone/ls-shared-modules';
import { Lookup } from '@limestone/ls-shared-modules';

export class CompanyIntegration {
  constructor(
    id?: string | number,
    companyId?: number,
    integrationType?: IntegrationType,
    integrationId?: string,
    channelId?: string,
    channelProgram?: ChannelProgram
  ) {
    this.id = id;
    this.companyId = companyId;
    this.integrationType = integrationType ? new IntegrationType(integrationType.id, integrationType.name) : null;
    this.integrationId = integrationId;
    this.channelId = channelId;
    if (channelProgram) {
      this.channelId = channelProgram.channelId;
      this.sourceCompany = new GenericDropdownOption(channelProgram.companyId, channelProgram.companyName);
    }
    if (this.channelId) {
      this.channel = new GenericDropdownOption(this.channelId, channelProgram?.channelName);
    }
    this.channelProgram = channelProgram
      ? new ChannelProgram(
          channelProgram.id,
          channelProgram.channelId,
          channelProgram.companyId,
          channelProgram.integrationTypeId,
          channelProgram.name,
          channelProgram.channelName
        )
      : null;
  }
  id?: string | number;
  companyId: number;
  integrationType: IntegrationType;
  integrationId: string;
  channelId: string;
  channelProgram: ChannelProgram;
  channel: Lookup<string>;
  sourceCompany: GenericDropdownOption<number>;
}
