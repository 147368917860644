import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, ExportRequest, Instrument, Page, PageRequest } from '../../../../Models';

export abstract class InstrumentActions extends LsAction {
  /* eslint-disable */
  static INSTRUMENTS: Readonly<string> = 'INSTRUMENTS';
  static INSTRUMENT: Readonly<string> = 'INSTRUMENT';
  static INSTRUMENTS_ACTIVE_PAGE: Readonly<string> = 'INSTRUMENTS_ACTIVE_PAGE';
  static INSTRUMENTS_EXPORT: Readonly<string> = 'INSTRUMENTS/EXPORT';
  /* eslint-enable */

  static getInstruments = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setInstruments = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS, ActionType.SET),
    props<{ page: Page<Instrument> }>()
  );

  static clearInstruments = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS, ActionType.CLEAR)
  );

  static getInstrument = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENT, ActionType.GET),
    props<{ id: number }>()
  );

  static setInstrument = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENT, ActionType.SET),
    props<{ instrument: Instrument }>()
  );

  static getInstrumentsActivePage = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS_ACTIVE_PAGE, ActionType.GET)
  );

  static setInstrumentsActivePage = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static triggerInstrumentsExport = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static instrumentExportComplete = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS_EXPORT, ActionType.COMPLETE)
  );

  static instrumentExportUnsuccessful = createAction(
    InstrumentActions.getActionName(InstrumentActions.INSTRUMENTS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
