import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { DiscountBasisState } from './discount-basis-reducer';

@Injectable()
export class DiscountBasisSelectors {
  appState = (state: any) => state.discountBasis;

  selectDayCountArr = createSelector(this.appState, (state: DiscountBasisState) => copy(state.dayCountArr));

  selectActiveDayCount = createSelector(this.appState, (state: DiscountBasisState) => copy(state.activeDayCount));

  selectDiscountBasisTypes = createSelector(this.appState, (state: DiscountBasisState) =>
    copy(state.discountBasisTypes)
  );

  selectActiveDiscountBasisType = createSelector(this.appState, (state: DiscountBasisState) =>
    copy(state.activeDiscountBasisType)
  );

  selectDayCountArrAndDiscountBasisTypes = createSelector(this.appState, (state: DiscountBasisState) => ({
    dayCountArr: copy(state.dayCountArr),
    discountBasisTypes: copy(state.discountBasisTypes)
  }));
}
