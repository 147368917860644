export class FacilityList {
  id?: number;
  productCurrencyCode?: string;
  sellerName?: string;
  debtorName?: string;
  sourceChannelCompanyName?: string;
  integrationTypeName?: string;
  isAutoXcelEnabled?: boolean;
  isRemittanceEmailSent?: boolean;
  channelAndIntegrationType?: string;
  lienPositionName?: string;
  payXcelTypeName?: string;
  facilityGroupId?: string;
  isFacilityApproved?: boolean;
  allConfigsApproved?: boolean;
  allActiveConfigsApproved?: boolean;
  constructor(
    id?: number,
    productCurrencyCode?: string,
    sellerName?: string,
    debtorName?: string,
    sourceChannelCompanyName?: string,
    integrationTypeName?: string,
    isAutoXcelEnabled?: boolean,
    isRemittanceEmailSent?: boolean,
    lienPositionName?: string,
    payXcelTypeName?: string,
    facilityGroupId?: string,
    isFacilityApproved?: boolean,
    allConfigsApproved?: boolean,
    allActiveConfigsApproved?: boolean
  ) {
    this.id = id ?? null;
    this.productCurrencyCode = productCurrencyCode ?? null;
    this.sellerName = sellerName ?? null;
    this.debtorName = debtorName ?? null;
    this.sourceChannelCompanyName = integrationTypeName ?? null;
    this.integrationTypeName = integrationTypeName ?? null;
    this.isAutoXcelEnabled = isAutoXcelEnabled ?? null;
    this.isRemittanceEmailSent = isRemittanceEmailSent ?? null;
    this.channelAndIntegrationType = sourceChannelCompanyName + ' ' + integrationTypeName;
    this.lienPositionName = lienPositionName ?? null;
    this.payXcelTypeName = payXcelTypeName ?? null;
    this.facilityGroupId = facilityGroupId ?? null;
    this.isFacilityApproved = isFacilityApproved ?? null;
    this.allConfigsApproved = allConfigsApproved ?? null;
    this.allActiveConfigsApproved = allActiveConfigsApproved ?? null;
  }

  get isApprovedForFacilityGroup(): boolean {
    return this.isFacilityApproved && this.allConfigsApproved;
  }

  get isApprovedForParticipantGroup(): boolean {
    return this.isFacilityApproved && this.allActiveConfigsApproved;
  }
}
