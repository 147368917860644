import { ProductCurrency } from './ProductCurrency';
import { OperationsCenter } from './OperationsCenter';
import { AllocationMethod } from './AllocationMethod';

export class ParticipantGroup {
  id: number;
  name: string;
  productCurrency: ProductCurrency;
  operationsCenter: OperationsCenter;
  allocationMethod: AllocationMethod;
  equalExposurePercent: string;
  facilityGroupId: number;

  constructor(
    id?: number,
    name?: string,
    productCurrency?: ProductCurrency,
    operationsCenter?: OperationsCenter,
    allocationMethod?: AllocationMethod,
    equalExposurePercent?: string,
    facilityGroupId?: number
  ) {
    this.id = id ?? null;
    this.name = name ?? null;
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : null;
    this.operationsCenter = operationsCenter
      ? new OperationsCenter(operationsCenter.id, operationsCenter.name, operationsCenter.isActive)
      : null;
    this.allocationMethod = allocationMethod
      ? new AllocationMethod(allocationMethod.id, allocationMethod.name, allocationMethod.isActive)
      : null;
    this.equalExposurePercent = equalExposurePercent ?? null;
    this.facilityGroupId = facilityGroupId ?? null;
  }
}
