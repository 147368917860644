import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import copy from 'fast-copy';
import { CoreComponent } from './CoreComponent';
import { Lookup } from '@limestone/ls-shared-modules';
import { BehaviorSubject } from 'rxjs';

type handledErrorMessages =
  | 'required'
  | 'min'
  | 'max'
  | 'maxlength'
  | 'matDatepickerParse'
  | 'matDatepickerFilter'
  | 'pattern'
  | 'duplicateDate'
  | 'nameExists'
  | 'currencyMin'
  | 'lsIntegerPattern'
  | 'lsCurrencyPattern'
  | 'lsTimeRange'
  | 'lsRatePattern'
  | 'lsNumberGreater'
  | 'lsLengthAsync';

export class CommonMethodComponentBase extends CoreComponent {
  public originalData: any;
  public formGroup: FormGroup;
  public errors: string[] = [];
  public errorMsgMap: Map<string, Map<string, string>> = new Map();

  revertData(): any {
    return copy(this.originalData);
  }

  controlHasErrors(name: string, formGroup?: UntypedFormGroup) {
    let control: AbstractControl;
    if (formGroup) {
      control = formGroup.get(name);
    } else {
      control = this.formGroup.get(name);
    }
    if (control) {
      if (control.errors) {
        if (
          (control.touched && control.errors.hasOwnProperty('required')) ||
          control.errors.hasOwnProperty('maxlength')
        ) {
          return true;
        }
      }
      return control.errors != null;
    }
  }

  getControl(name: string): FormControl {
    return this.formGroup.get(name) as FormControl;
  }

  /**
   * Returns the specific error messages based on which validation failed.
   *
   * @param controlName Name of the control which has errors.
   * @param formGroup used if validating a child form group (such as a form array)
   *
   * @return string of the errors.
   */
  getErrorMessage(controlName: string, formGroup?: UntypedFormGroup) {
    let errorObj: ValidationErrors;
    if (formGroup) {
      errorObj = formGroup.get(controlName)?.errors;
    } else {
      errorObj = this.formGroup.get(controlName)?.errors;
    }

    return this.getErrorMessageFromErrors(errorObj, controlName);
  }

  getErrorMessageFromErrors(errors: ValidationErrors | null, controlName: string = null): string {
    let errorStr = '';
    if (errors === null) {
      return errorStr;
    }
    Object.keys(errors).forEach((key) => {
      switch (key) {
        case 'required':
          errorStr = this.getErrorString(key, 'Required', controlName);
          break;
        case 'min':
          errorStr += this.getErrorString(key, `The value is below the minimum of ${errors[key].min}`, controlName);
          break;
        case 'max':
          errorStr += this.getErrorString(key, `The value exceeds the maximum of ${errors[key].max}`, controlName);
          break;
        case 'maxlength':
          errorStr += this.getErrorString(key, `Max length of ${errors[key].requiredLength} exceeded`, controlName);
          break;
        case 'matDatepickerParse':
          errorStr += this.getErrorString(key, 'Invalid Format', controlName);
          break;
        case 'matDatepickerFilter':
          errorStr += this.getErrorString(key, 'Date filter error', controlName);
          break;
        case 'pattern':
          errorStr += this.getErrorString(key, 'Invalid format', controlName);
          break;
        case 'duplicateDate':
          errorStr += this.getErrorString(key, 'Date already exists on this calendar', controlName);
          break;
        case 'nameExists':
          errorStr += this.getErrorString(key, 'An Entity With That Name Already Exists', controlName);
          break;
        case 'currencyMin':
          errorStr += this.getErrorString(
            key,
            `The value is below the minimum of ${errors[key].minValue}`,
            controlName
          );
          break;
        case 'lsIntegerPattern':
          errorStr += this.getErrorString(key, 'Invalid input, only whole integers allowed.', controlName);
          break;
        case 'lsCurrencyPattern':
          errorStr += this.getErrorString(
            key,
            'Current input does not match the decimal pattern for provided currency.',
            controlName
          );
          break;
        case 'lsRatePattern':
          errorStr += this.getErrorString(
            key,
            'Current input does not match the decimal pattern for a Limestone Rate.',
            controlName
          );
          break;
        case 'lsTimeRange':
          errorStr += this.getErrorString(key, 'Current input breaks time range check.', controlName);
          break;
        case 'lsNumberGreater':
          errorStr += this.getErrorString(key, 'Value larger than compare value.', controlName);
          break;
        case 'lsLengthAsync':
          if (errors[key].maxLengthAsync === null) {
            errorStr += this.getErrorString(key, `Must be ${errors[key].minLengthAsync} characters.`, controlName);
          } else {
            errorStr += this.getErrorString(
              key,
              `Must be between ${errors[key].minLengthAsync} and ${errors[key].maxLengthAsync} characters.`,
              controlName
            );
          }
          break;
      }
    });
    return errorStr;
  }

  protected addMessageOverride(handledErrorType: handledErrorMessages, message: string, controlName: string = null) {
    if (this.errorMsgMap.has(handledErrorType)) {
      this.errorMsgMap.get(handledErrorType).set(controlName, message);
    } else {
      this.errorMsgMap.set(handledErrorType, new Map<string, string>().set(controlName, message));
    }
  }

  private getErrorString(handledError: handledErrorMessages, defaultText: string, controlName: string = null): string {
    return (
      this.errorMsgMap.get(handledError)?.get(controlName) ||
      this.errorMsgMap.get(handledError)?.get(null) ||
      defaultText
    );
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  lookupsEqualAndNotNull(a: Lookup<number | string>, b: Lookup<number | string>): boolean {
    return a && b && a.id === b.id;
  }

  clearControlAndOptionalAutocomplete(control: AbstractControl<unknown>, subject?: BehaviorSubject<unknown>): void {
    control.reset();
    control.markAsDirty();
    subject?.next(null);
  }
}
