import { DaySelectionTypes } from './DaySelectionOption';

export class MaturityCalendar {
  public maturityType?: DaySelectionTypes;
  public daysSelected?: number[];

  constructor(maturityType?: DaySelectionTypes, daysSelected?: number[]) {
    this.maturityType = maturityType ?? null;
    this.daysSelected = daysSelected ?? null;
  }
}
