import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class UserRole extends GenericDropdownOption<string> {
  public description: string;
  public category: string;
  public isInternal: boolean;

  constructor(id?: string, name?: string, description?: string, category?: string, isInternal?: boolean) {
    super(id, name);
    this.description = description;
    this.category = category;
    this.isInternal = isInternal;
  }
}
