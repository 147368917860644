import { DropdownOption } from '@limestone/ls-shared-modules';

export class DataChangeEvent {
  data: any;
  autoCompleteSelection: DropdownOption;
  constructor(data: any, autoCompleteSelection?: DropdownOption) {
    this.data = data;
    this.autoCompleteSelection = autoCompleteSelection;
  }
}
