import { FundedParticipantProductCurrency } from './FundedParticipantProductCurrency';
import { RelationshipStatus } from './RelationshipStatus';

export class FundedParticipant {
  companyId?: number;
  relationshipStatus: RelationshipStatus;
  fundedParticipantProductCurrencies: FundedParticipantProductCurrency[];

  constructor(
    companyId?: number,
    relationshipStatus?: RelationshipStatus,
    fundedParticipantProductCurrencies?: FundedParticipantProductCurrency[]
  ) {
    this.companyId = companyId ?? null;
    this.relationshipStatus = relationshipStatus
      ? new RelationshipStatus(relationshipStatus.id, relationshipStatus.name, relationshipStatus.isActive)
      : new RelationshipStatus();
    this.fundedParticipantProductCurrencies = fundedParticipantProductCurrencies
      ? fundedParticipantProductCurrencies.map(
          (fppc) =>
            new FundedParticipantProductCurrency(
              fppc.isActive,
              fppc.fundedParticipantId,
              fppc.productCurrency,
              fppc.creditLimitAmount,
              fppc.limitBasisType
            )
        )
      : new Array<FundedParticipantProductCurrency>();
  }
}
