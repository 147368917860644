import { PobFinancingStatus } from './PobFinancingStatus';
import { InstrumentApprovalStatus } from './InstrumentApprovalStatus';
import { InstrumentApprovalSource } from './InstrumentApprovalSource';

export class Pob {
  id: number | string;
  seller: string;
  debtor: string;
  instrumentSourceChannel: string;
  currency: string;
  advanceAmount: number;
  expectedDueDate: string;
  financingStatus: PobFinancingStatus;
  instrumentApprovalStatus: InstrumentApprovalStatus;
  instrumentApprovalSource: InstrumentApprovalSource;
  facilityConfigId: number;
  graceDate: string;
  deemedDisputeDate: string;
  writeOffDate: string;

  constructor(
    id: number | string,
    seller: string,
    debtor: string,
    instrumentSourceChannel: string,
    currency: string,
    advanceAmount: number,
    expectedDueDate: string,
    financingStatus: PobFinancingStatus,
    instrumentApprovalStatus: InstrumentApprovalStatus,
    instrumentApprovalSource: InstrumentApprovalSource,
    facilityConfigId: number,
    graceDate: string,
    deemedDisputeDate: string,
    writeOffDate: string
  ) {
    this.id = id;
    this.seller = seller ?? null;
    this.debtor = debtor ?? null;
    this.instrumentSourceChannel = instrumentSourceChannel ?? null;
    this.currency = currency ?? null;
    this.advanceAmount = advanceAmount ?? null;
    this.expectedDueDate = expectedDueDate ?? null;
    this.financingStatus = financingStatus
      ? new PobFinancingStatus(financingStatus.id, financingStatus.name, financingStatus.isActive)
      : null;
    this.instrumentApprovalStatus
      ? new InstrumentApprovalStatus(
          instrumentApprovalStatus.id,
          instrumentApprovalStatus.name,
          instrumentApprovalStatus.isActive
        )
      : null;
    this.instrumentApprovalSource
      ? new InstrumentApprovalSource(
          instrumentApprovalSource.id,
          instrumentApprovalSource.instrumentApprovalParty,
          instrumentApprovalSource.instrumentApprovalMethod,
          instrumentApprovalSource.isActive
        )
      : null;
    this.facilityConfigId = facilityConfigId ?? null;
    this.graceDate = graceDate ?? null;
    this.deemedDisputeDate = deemedDisputeDate ?? null;
    this.writeOffDate = writeOffDate ?? null;
  }
}
