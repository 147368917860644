import { ProductCurrency } from './ProductCurrency';

export class OperationsCenterProductCurrency {
  id: number;
  operationsCenterId: number;
  productCurrency: ProductCurrency;
  defaultTimeZoneId: string;
  sellerCutoffTime: string;
  participantSettlementCutoffTime: string;
  participantCutoffTime: string;
  minReferenceRate: string;
  lastFacilityStartRun: string;
  isActive: boolean;
  processingEndTime: string;

  constructor(
    id?: number,
    operationsCenterId?: number,
    productCurrency?: ProductCurrency,
    defaultTimeZoneId?: string,
    sellerCutoffTime?: string,
    participantSettlementCutoff?: string,
    participantCutoff?: string,
    minReferenceRate?: string,
    lastFacilityStartRun?: string,
    isActive?: boolean,
    processingEndTime?: string
  ) {
    this.id = id;
    this.operationsCenterId = operationsCenterId ?? null;
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : new ProductCurrency();
    this.defaultTimeZoneId = defaultTimeZoneId ?? null;
    this.sellerCutoffTime = sellerCutoffTime ?? null;
    this.participantSettlementCutoffTime = participantSettlementCutoff;
    this.participantCutoffTime = participantCutoff;
    this.minReferenceRate = minReferenceRate;
    this.lastFacilityStartRun = lastFacilityStartRun;
    this.isActive = isActive;
    this.processingEndTime = processingEndTime;
  }
}
