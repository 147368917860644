import { ActiveFilter } from '../Components/App Components/data-grid/grid-filter-modal/grid-filter-modal.component';
import { GridSort } from '@limestone/ls-shared-modules';

export class PageRequest {
  // Begins at 1
  page: number;
  size: number;
  // Array of sorts, applied in order, with format: 'propertyName,sortDirection' example: 'name,desc'.
  // If not specified, sort direction defaults to asc
  sort?: string[];
  filter?: string;

  constructor(page: number, size: number, sorts?: GridSort[], filters?: Map<string, ActiveFilter[]>) {
    this.page = page;
    this.size = size;
    if (sorts) {
      this.setSort(sorts);
    }
    if (filters) {
      this.setFilters(filters);
    }
  }

  public setSort(sorts: GridSort[]): void {
    this.sort = sorts.map((s) => `${s.active},${s.type === undefined ? 'text' : s.type},${s.direction}`);
  }

  // &filter[i]=property1,filterType,filterDataType,filterValue &filter[i]=OR &filter[i]=property1,filterType,filterValue
  public setFilters(filters: Map<string, ActiveFilter[]>): void {
    this.filter = '';

    let i = 0;
    filters.forEach((filter, key) => {
      filter.forEach((f: ActiveFilter, j) => {
        let filterStr = '';
        if (j > 0 || i > 0) {
          filterStr += `&filter[${i}]=`;
        } else {
          filterStr = `filter[${i}]=`;
        }
        filterStr += `${key},${f.filterType === undefined ? 'text' : f.filterType},${f.filterAction},${f.filterValue}`;
        if (f.filterJunction) {
          filterStr += `&filter[${i}]=${f.filterJunction}`;
        }
        this.filter += filterStr;
      });
      i++;
    });
  }
}
