<div
  [formGroup]="formGroup"
  class="ls-rate-input-container">
  <input class="ls-rate-input-element wide-input"
         #rateValue
         matInput
         [lsRateDisplay]="formGroup.controls[RATE_VALUE].value"
         [lsRateNumOfDecimals]="_decimals"
         [required]="_rateRequired"
         type="text"
         (keydown)="handleInput($event)"
         [formControlName]="RATE_VALUE"
         (input)="inputChanged()"
         (focus)="onFocusIn($event)"
         (blur)="onFocusOut($event)"/>
  <span *ngIf="_suffix && _suffix.length>0" matSuffix>{{_suffix}}</span>
</div>
