import { Pol } from './Pol';

export class PobView {
  id: number;
  seller: string;
  debtor: string;
  instrumentSourceChannel: string;
  currency: string;
  netAmount: number;
  advanceAmount: number;
  remainderAmount: number;
  expectedDueDate: string;
  graceDate: string;
  deemedDisputeDate: string;
  writeOffDate: string;
  financingStatus: string;
  pols: Pol[];

  constructor(
    id?: number,
    seller?: string,
    debtor?: string,
    instrumentSourceChannel?: string,
    currency?: string,
    netAmount?: number,
    advanceAmount?: number,
    remainderAmount?: number,
    expectedDueDate?: string,
    graceDate?: string,
    deemedDisputeDate?: string,
    writeOffDate?: string,
    financingStatus?: string,
    pols?: Pol[]
  ) {
    this.id = id ?? null;
    this.seller = seller ?? null;
    this.debtor = debtor ?? null;
    this.instrumentSourceChannel = instrumentSourceChannel ?? null;
    this.currency = currency ?? null;
    this.netAmount = netAmount ?? null;
    this.advanceAmount = advanceAmount ?? null;
    this.remainderAmount = remainderAmount ?? null;
    this.expectedDueDate = expectedDueDate ?? null;
    this.graceDate = graceDate ?? null;
    this.deemedDisputeDate = deemedDisputeDate ?? null;
    this.writeOffDate = writeOffDate ?? null;
    this.financingStatus = financingStatus ?? null;
    this.pols = pols
      ? pols.map(
          (pol) =>
            new Pol(
              pol.id,
              pol.instrumentId,
              pol.currency,
              pol.netAmount,
              pol.advanceAmount,
              pol.expectedDueDate,
              pol.polEligibilityStatus,
              pol.polFinanceStatus,
              pol.ineligibleReason,
              pol.pobId as string
            )
        )
      : new Array<Pol>();
  }
}
