import { FinancingOutbound, FinancingOutboundTotals, Page } from '.';

export class FinancingOutboundData {
  page: Page<FinancingOutbound>;
  totals: FinancingOutboundTotals;

  constructor(page?: Page<FinancingOutbound>, totals?: FinancingOutboundTotals) {
    this.page = page ?? null;
    this.totals = totals ?? null;
  }
}
