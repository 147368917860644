<div class="header-container d-flex flex-row justify-content-start align-items-center">
  {{ column.label }}
  <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="hasActions()">
    <mat-icon aria-hidden="false" color="black" [matBadge]="activeActionCount()" matBadgeSize="small" matBadgeColor="accent">
      more_vert
    </mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="column.enableSort"
      class="filter"
      (click)="$event.stopPropagation(); sort()">
      <mat-icon color="primary" *ngIf=" _sort && _sort.active === column.value && _sort.direction === 'asc'">
        arrow_upward
      </mat-icon>
      <mat-icon color="primary" *ngIf="_sort && _sort.active === column.value && _sort.direction === 'desc'">
        arrow_downward
      </mat-icon>
      <span>Sort</span>
    </button>
    <button
      mat-menu-item
      *ngIf="column.enableFilter"
      class="filter"
      (click)="$event.stopPropagation(); openFilterModal();">
      <mat-icon aria-hidden="false"
        color="primary"
        [matBadge]="getFilterCount()"
        matBadgeSize="small"
        matBadgeColor="accent">filter_alt</mat-icon>
      <span>Filter</span>
    </button>
    <button
      mat-menu-item
      *ngIf="column.enableGrouping"
      class="filter"
      (click)="emitGroupToggle($event)">
      <mat-icon color="primary">group_work</mat-icon>
      <span>Group By</span>
    </button>
  </mat-menu>
</div>
