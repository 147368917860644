import { Company } from './Company';

export class ParticipantGroupCohortParticipant {
  id: string | number;
  fundedParticipantId: number | Company;
  assetSplitPercent: string;
  exposurePercent: string;
  discountCalcTypeId: string;
  discountMethodId: string;
  marginRate: string;
  referenceRateTypeId: string;
  referenceRateMethodId: string;
  referenceRateTenorId: string;
  discountBasisTypeId: string;
  dayCountId: string;
  offerCutoff: string;
  minTenor: number;
  maxTenor: number;
  maxPolLimit: string;
  minDailyThreshold: string;
  participantCreditLimitAmount: string;
  creditLimitBufferAmount: string;
  creditLimitAlertPercent: string;
  minMonthlyAmount: string;
  minMonthlyPercent: string;
  minChargedDays: number;

  constructor(
    id?: string | number,
    fundedParticipantId?: number | Company,
    assetSplitPercent?: string,
    exposurePercent?: string,
    discountCalcTypeId?: string,
    discountMethodId?: string,
    marginRate?: string,
    referenceRateTypeId?: string,
    referenceRateMethodId?: string,
    referenceRateTenorId?: string,
    discountBasisTypeId?: string,
    dayCountId?: string,
    offerCutoff?: string,
    minTenor?: number,
    maxTenor?: number,
    maxPolLimit?: string,
    minDailyThreshold?: string,
    participantCreditLimitAmount?: string,
    creditLimitBufferAmount?: string,
    creditLimitAlertPercent?: string,
    minMonthlyAmount?: string,
    minMonthlyPercent?: string,
    minChargedDays?: number
  ) {
    this.id = id;
    this.fundedParticipantId = fundedParticipantId;
    this.assetSplitPercent = assetSplitPercent;
    this.exposurePercent = exposurePercent;
    this.discountCalcTypeId = discountCalcTypeId;
    this.discountMethodId = discountMethodId;
    this.marginRate = marginRate;
    this.referenceRateTypeId = referenceRateTypeId;
    this.referenceRateMethodId = referenceRateMethodId;
    this.referenceRateTenorId = referenceRateTenorId;
    this.discountBasisTypeId = discountBasisTypeId;
    this.dayCountId = dayCountId;
    this.offerCutoff = offerCutoff;
    this.minTenor = minTenor;
    this.maxTenor = maxTenor;
    this.maxPolLimit = maxPolLimit;
    this.minDailyThreshold = minDailyThreshold;
    this.participantCreditLimitAmount = participantCreditLimitAmount;
    this.creditLimitBufferAmount = creditLimitBufferAmount;
    this.creditLimitAlertPercent = creditLimitAlertPercent;
    this.minMonthlyAmount = minMonthlyAmount;
    this.minMonthlyPercent = minMonthlyPercent;
    this.minChargedDays = minChargedDays;
  }
}
