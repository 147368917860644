import { ValidatorFn, Validators } from '@angular/forms';
import { DateValidation } from '../../app/Components/App Components/data-grid/Models/LsDataGridConfig';
import { CustomValidators } from '../CustomValidators';

export function createLsDateValidatorArray(dateValidation: DateValidation, customValidators: CustomValidators) {
  let dateValidations: ValidatorFn[] = [];
  if (dateValidation.minDate) {
    // control.setValidators()
  }
  if (dateValidation.required) {
    dateValidations.push(Validators.required);
  }
  if (dateValidation.uniqueDate) {
    dateValidations.push(customValidators.uniqueDateValidator());
  }
  return dateValidations;
}
