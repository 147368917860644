import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IngestStatusState } from './ingest-status-reducer';
import copy from 'fast-copy';

@Injectable()
export class IngestStatusSelectors {
  appState = (state: any) => state.ingest;

  selectIngestStatuses = createSelector(this.appState, (state: IngestStatusState) =>
    state.ingestStatuses == null ? null : copy(state.ingestStatuses)
  );
}
