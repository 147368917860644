<div class="nav-container d-flex flex-row">
  <div class="nav-inner-container d-flex flex-column">
    <div class="logo-container">
      <img aria-hidden="false" aria-label="Limestone Home" src="../../../assets/Raistone[white].svg" />
    </div>
    <div class="nav-section d-flex flex-column">
      <div *ngFor="let section of getKeys()" class="route-container d-flex flex-column ls-mb-3">
        <label class="section-label">{{ section }}</label>
        <div *ngFor="let route of this.routes.get(section)">
          <span [ngClass]="{ active: route.isActive }" class="nav-btn" mat-button (click)="navTo(route)">
            {{ route.label }}
          </span>
        </div>
      </div>
    </div>
    <img
      aria-hidden="false"
      aria-label="Limestone Home"
      src="../../../assets/raistone_darker_gray_bg.png"
      class="nav-img" />
  </div>

  <div class="content d-flex flex-row">
    <div class="content-container">
      <div class="header-container d-flex flex-row justify-content-end ls-mr-3">
        <ls-button id="logoff-btn" [title]="'Logout'" (click)="logoff()"></ls-button>
      </div>

      <router-outlet></router-outlet>

      <mat-toolbar class="footer">
        <span>© Raistone Capital LLC</span>
      </mat-toolbar>
    </div>
  </div>
</div>
