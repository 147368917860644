import { Currency } from './Currency';

export class BankCurrency {
  bankId?: number;
  currency: Currency;
  isActive: boolean;

  constructor(isActive?: boolean, bankId?: number, currency?: Currency) {
    this.bankId = bankId;
    this.currency = currency ? new Currency(currency.currencyCode, currency.name, currency.isActive) : new Currency();
    this.isActive = isActive ?? true;
  }
}
