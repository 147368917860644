import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgControl,
  NgForm,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { LsValidators } from '@limestone/ls-shared-modules';
import { CommonMethodComponentBase } from '../CommonMethodComponentBase';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ls-rate-input',
  templateUrl: './rate-input.component.html',
  styleUrls: ['./rate-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RateInputComponent }]
})
export class RateInputComponent
  extends CommonMethodComponentBase
  implements MatFormFieldControl<string>, ControlValueAccessor, Validator, DoCheck, OnDestroy
{
  static nextId: number = 0;
  @ViewChild('rateValue') rateInput: HTMLInputElement;

  formGroup: FormGroup<{
    rateValue: FormControl<string | null>;
  }>;

  stateChanges = new Subject<void>();
  focused: boolean = false;
  touched: boolean;
  controlType: string = 'ls-rate-input';
  @HostBinding() id: string = `ls-rate-input-${RateInputComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  protected readonly RATE_VALUE = 'rateValue';
  protected _decimals: number = 8;
  private rateValidator = LsValidators.rate(this._decimals);

  constructor(
    private fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.buildForm();

    if (this.ngControl !== null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  buildForm() {
    if (this.formGroup) {
      this.formGroup.removeControl(this.RATE_VALUE);
    }
    this.formGroup = this.fb.group({
      rateValue: [this.value, this._rateRequired ? [this.rateValidator, Validators.required] : [this.rateValidator]]
    });
  }

  get empty(): boolean {
    return !this.formGroup?.get(this.RATE_VALUE)?.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  private _required: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    return this.formGroup?.get(this.RATE_VALUE)?.value;
  }
  set value(val: string | null) {
    this.formGroup?.get(this.RATE_VALUE)?.setValue(val, { emitEvent: false });
    this.stateChanges.next();
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.formGroup.markAllAsTouched();
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.ls-rate-input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.rateInput, 'program');
  }

  registerOnChange(onChange: (rateValue: string) => void): void {
    this.formGroup
      .get(this.RATE_VALUE)
      .valueChanges.pipe(takeUntil(this.componentTeardown$), map(onChange))
      .subscribe();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(rate: string | null) {
    this.value = rate ?? null;
  }

  errorState: boolean = false;
  private updateErrorState() {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState =
      (this.ngControl?.invalid || this.formGroup.invalid) && (this.ngControl.touched || parent.submitted);

    if (this.formGroup?.get(this.RATE_VALUE)?.hasError('required')) {
      this.ngControl?.control?.setErrors({ required: true }, { emitEvent: false });
    } else if (this.formGroup?.get(this.RATE_VALUE)?.hasError('lsRatePattern')) {
      this.ngControl?.control?.setErrors({ lsRatePattern: true }, { emitEvent: false });
    } else {
      this.ngControl?.control?.setErrors(null, { emitEvent: false });
    }

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
      this.cdr.detectChanges();
    }
  }

  @Input() set decimals(decimals: number) {
    if (this.formGroup.get(this.RATE_VALUE).hasValidator(this.rateValidator)) {
      this.formGroup.get(this.RATE_VALUE).removeValidators(this.rateValidator);
    }
    this._decimals = decimals;
    this.rateValidator = LsValidators.rate(this._decimals);
    this.formGroup.get(this.RATE_VALUE).addValidators(this.rateValidator);
  }

  protected _rateLabel: string;
  @Input() set rateLabel(label: string) {
    this._rateLabel = label;
  }

  protected _suffix: string;
  @Input() set rateSuffix(label: string) {
    this._suffix = label;
  }

  protected _rateRequired: boolean = false;
  @Input() set rateRequired(required: boolean) {
    this._rateRequired = required;
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  public handleInput(input: KeyboardEvent): boolean {
    const regex = /^[0-9.]$/;

    const allowedKeys = ['ArrowRight', 'ArrowLeft', 'Backspace', 'Delete', 'Tab'];
    if (!(regex.test(input.key) || allowedKeys.find((k) => k === input.key))) {
      return false;
    }
    if (input.key === '.') {
      if (this._decimals === 0 || this.formGroup.get(this.RATE_VALUE).value?.indexOf('.') >= 0) {
        return false;
      }
    }
  }

  public inputChanged(): void {
    this.stateChanges.next();
  }

  onValidatorChange = () => {};
  registerOnValidatorChange(onValidatorChange: () => void) {
    this.onValidatorChange = onValidatorChange;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let errors: any = null;

    errors = this.addControlErrors(errors, this.formGroup.get(this.RATE_VALUE), this.RATE_VALUE);

    return errors;
  }

  addControlErrors(allErrors: any, control: AbstractControl<any, any>, controlName: string) {
    let errors: any = allErrors ? { ...allErrors } : null;

    const controlErrors = control.errors;

    if (controlErrors) {
      if (!errors) {
        errors = {};
      }
      errors[controlName] = controlErrors;
    }

    return errors;
  }
}
