import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { CashAppOutboundState } from './cash-app-outbound-reducer';

@Injectable()
export class CashAppOutboundSelectors {
  appState = (state: any) => state.cashAppOutbound;

  selectCashAppOutbound = createSelector(this.appState, (state: CashAppOutboundState) => ({
    cashAppOutboundPages: state?.cashAppOutboundPages == null ? null : copy(state.cashAppOutboundPages)
  }));

  selectCashAppOutboundComponentState = createSelector(this.appState, (state: CashAppOutboundState) => ({
    cashAppOutboundPages: copy(state?.cashAppOutboundPages),
    cashAppOutboundActivePage: copy(state?.cashAppOutboundActivePage),
    cashAppOutboundTotals: copy(state?.cashAppOutboundTotals)
  }));

  selectCashAppOutboundDetailsComponentState = createSelector(this.appState, (state: CashAppOutboundState) => ({
    cashAppOutboundActive: state?.cashAppOutboundActive == null ? null : copy(state.cashAppOutboundActive)
  }));
}
