// @ts-ignore

import { RemitLineItem } from './RemitLineItem';
import { RemitOutboundDetails } from './RemitOutboundDetails';

export class FinancingOutboundDetails extends RemitOutboundDetails {
  seller: string;
  debtor: string;

  constructor(
    facilityGroupName?: string,
    facilityGroupId?: number,
    recipient?: string,
    recipientId?: number,
    role?: string,
    proposedEffectiveDate?: Date,
    effectiveDate?: Date,
    compartment?: string,
    compartmentId?: number,
    remittancePurpose?: string,
    recipientBankAccount?: string,
    paymentMethod?: string,
    remittanceReferenceId?: number,
    remittanceNetAmount?: number,
    currencyCode?: string,
    remittanceAllocatedAmount?: number,
    remittanceUnallocatedAmount?: number,
    seller?: string,
    debtor?: string,
    remitStatusId?: string,
    remitTypeId?: string,
    lineItems?: RemitLineItem[]
  ) {
    super(
      facilityGroupName,
      facilityGroupId,
      recipient,
      recipientId,
      role,
      proposedEffectiveDate,
      effectiveDate,
      compartment,
      compartmentId,
      remittancePurpose,
      recipientBankAccount,
      paymentMethod,
      remittanceReferenceId,
      remittanceNetAmount,
      currencyCode,
      remittanceAllocatedAmount,
      remittanceUnallocatedAmount,
      remitStatusId,
      remitTypeId,
      lineItems
    );

    this.seller = seller;
    this.debtor = debtor;
  }
}
