import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ls-mat-table-column-sorter, div[ls-mat-table-column-sorter]',
  templateUrl: './column-sorter.component.html',
  styleUrls: ['./column-sorter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnSorterComponent implements OnInit {
  @Input() columns: string[];
  @Input() dragColumns: boolean;

  columnInfo: ColumnInfo[];

  @Output() columnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.columnInfo = this.columns.map((currElement, index) => ({
      name: currElement,
      hidden: false
    }));
    this.emitColumns(false);
  }
  columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(this.columnInfo, event.item.data.columnIndex, event.currentIndex);
    this.emitColumns(true);
  }

  toggleSelectedColumn(columnId: string) {
    const colFound = this.columnInfo.find((col) => col.name === columnId);
    colFound.hidden = !colFound.hidden;
    this.emitColumns(true);
  }

  private emitColumns(saveColumns: boolean) {
    // Only emit the columns on the next animation frame available
    window.requestAnimationFrame(() => {
      this.columnsChange.emit(this.columnInfo.filter((colInfo) => !colInfo.hidden).map((colInfo) => colInfo.name));
    });
  }
}

export interface ColumnInfo {
  name: string;
  hidden: boolean;
}
