import { Injectable, ErrorHandler } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { environment } from 'src/environments/environment';
import { ENV_NAME } from 'src/environments/environment.common';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorSnackBarComponent } from '../Components';
import { InternalServerError } from './Errors/internal-server-error';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private appInsightService: AppInsightsService, private snackBar: MatSnackBar) {
    super();
  }

  handleError(error: any): void {
    if (error instanceof InternalServerError) {
      this.snackBar.openFromComponent(ErrorSnackBarComponent, {
        panelClass: ['unsuccessful-sb'],
        data: [error.message]
      });
      error = error.serverError;
    }

    if (environment.envName === ENV_NAME.LOCAL) {
      console.error(error);
    } else {
      this.appInsightService.logException(error);
    }
  }
}
