import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GeneralUtils {
  static existsArray(value: any[]): boolean {
    if (typeof value === 'undefined' || value === null) {
      return false;
    }
    let result = true;
    value.forEach((v) => {
      if (typeof v === 'undefined' || v === null) {
        result = false;
      }
    });
    return result;
  }

  static exists(value: any): boolean {
    return !(typeof value === 'undefined' || value === null);
  }

  static getDatePlusDays(days: number): Date {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }
}
