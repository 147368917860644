import { PolOwner } from './PolOwner';

export class PolView {
  id: number;
  instrumentId: number;
  seller: string;
  debtor: string;
  instrumentNumber: string;
  currencyCode: string;
  netAmount: string;
  advanceRate: number;
  advanceAmount: string;
  remainderPercent: number;
  remainderAmount: string;
  baselineDueDate: string;
  unadjustedExpectedDueDate: string;
  expectedDueDate: string;
  pobId: number;
  eligibilityStatus: string;
  ineligibleReason: string;
  graceDate: string;
  deemedDisputeDate: string;
  writeOffDate: string;
  financingStatus: string;
  debtorCollectionStatus: string;
  participantCollectionStatus: string;
  participantRepaymentStatus: string;
  owners: PolOwner[];

  constructor(
    id?: number,
    instrumentId?: number,
    seller?: string,
    debtor?: string,
    instrumentNumber?: string,
    currency?: string,
    netAmount?: string,
    advanceRate?: number,
    advanceAmount?: string,
    remainderPercent?: number,
    remainderAmount?: string,
    baselineDueDate?: string,
    unadjustedExpectedDueDate?: string,
    expectedDueDate?: string,
    pobId?: number,
    polEligibilityStatus?: string,
    ineligibleReason?: string,
    graceDate?: string,
    deemedDisputeDate?: string,
    writeOffDate?: string,
    polFinanceStatus?: string,
    debtorCollectionStatus?: string,
    participantCollectionStatus?: string,
    participantRepaymentStatus?: string,
    owners?: PolOwner[]
  ) {
    this.id = id ?? null;
    this.instrumentId = instrumentId ?? null;
    this.instrumentNumber = instrumentNumber ?? null;
    this.seller = seller ?? null;
    this.debtor = debtor ?? null;
    this.currencyCode = currency ?? null;
    this.netAmount = netAmount ?? null;
    this.advanceRate = advanceRate ?? null;
    this.advanceAmount = advanceAmount ?? null;
    this.remainderPercent = remainderPercent ?? null;
    this.remainderAmount = remainderAmount ?? null;
    this.baselineDueDate = baselineDueDate ?? null;
    this.unadjustedExpectedDueDate = unadjustedExpectedDueDate ?? null;
    this.expectedDueDate = expectedDueDate ?? null;
    this.pobId = pobId ?? null;
    this.eligibilityStatus = polEligibilityStatus ?? null;
    this.ineligibleReason = ineligibleReason ?? null;
    this.graceDate = graceDate ?? null;
    this.deemedDisputeDate = deemedDisputeDate ?? null;
    this.writeOffDate = writeOffDate ?? null;
    this.financingStatus = polFinanceStatus ?? null;
    this.debtorCollectionStatus = debtorCollectionStatus ?? null;
    this.participantCollectionStatus = participantCollectionStatus ?? null;
    this.participantRepaymentStatus = participantRepaymentStatus ?? null;
    this.owners = owners
      ? owners.map(
          (o) =>
            new PolOwner(
              o.id,
              o.ownershipEventId,
              o.transferDate,
              o.companyName,
              o.ownershipTypeName,
              o.allocatedPercent,
              o.isActive
            )
        )
      : new Array<PolOwner>();
  }
}
